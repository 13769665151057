import { api } from '@/plugins'
import {
  GET_AGENCES,
  GET_AGENCES_SUCCESS,
  GET_AGENCES_FAIL,
  GET_COLLABORATEURS,
  GET_COLLABORATEURS_SUCCESS,
  GET_COLLABORATEURS_FAIL
} from './mutations'

export default {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_AGENCES)

      api
        .GET('agence/list')
        .then(async (datas) => {
          await commit(GET_AGENCES_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_AGENCES_FAIL)
          reject(e)
        })
    })
  },
  collaborateurs({ commit }, modelId) {
    return new Promise((resolve, reject) => {
      commit(GET_COLLABORATEURS)

      api
        .GET('agence/collaborateurs', modelId)
        .then(async (datas) => {
          await commit(GET_COLLABORATEURS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_COLLABORATEURS_FAIL)
          reject(e)
        })
    })
  }
}
