import Model from '../models/config.model'
import ConfigCollab from '../models/config-collab.model'

export default {
  addConfig: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(`production/primes/configs`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  addConfigCollab: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof ConfigCollab)) {
        return reject(
          new Error(
            `Erreur paramètre : ${ConfigCollab.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      if (!model.getProperty('collaborateurId')) {
        return reject(
          new Error(`Erreur paramètre : "collaborateurId" non saisi`)
        )
      }
      if (!model.getProperty('annee')) {
        return reject(new Error(`Erreur paramètre : "annee" non saisi`))
      }
      if (!model.getProperty('trimestre')) {
        return reject(new Error(`Erreur paramètre : "trimestre" non saisi`))
      }

      return POST(
        `production/primes/${model.getProperty(
          'collaborateurId'
        )}/${model.getProperty('annee')}/${model.getProperty('trimestre')}`,
        model
      )
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new ConfigCollab(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
