import AbstractModel from '../../abstract/abstract.model'

export const STATE_WAITING = 'STATE_WAITING'
export const STATE_SEND = 'STATE_SEND'

class FicheCompteRendu extends AbstractModel {
  constructor (data) {
    super(data, [
      'id',
      ['state', STATE_WAITING],
      {
        field: 'destinataires',
        default: []
      },
      'created'
    ])
  }
  getDestinataires () {
    return this.getProperty('destinataires', [])
  }

  getState () {
    return this.getProperty('state')
  }
  setState (state) {
    const newState = [STATE_WAITING, STATE_SEND].includes(state)
      ? state
      : this.getState()
    return this.setProperty('state', newState)
  }
}

export default FicheCompteRendu
