export default {
  delete: ({ DELETE }, idModel) =>
    new Promise((resolve, reject) => {
      if (!idModel) {
        reject(new Error(`Erreur paramètre : id non valide "${idModel}"`))
      }
      return DELETE(`fichiers/${idModel}`)
        .then(reponse => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
