export default {
  configCollaborateurs: (state) => state.configCollaborateurs,
  configCollaborateurById: (state) => (id) =>
    state.configCollaborateurs
      ? state.configCollaborateurs.find(
          (configCollaborateur) => configCollaborateur.id === id
        )
      : null,
  configCollaborateurs_pending: (state) => state.configCollaborateurs_pending
}
