import { parseNumber } from '@/plugins/utils'

class ProductionAvenant {
  constructor(data = {}) {
    const {
      id,
      collaborateurId,
      ficheId,
      produitId,
      motifChuteId,
      dateChute,
      dateEffet,
      dateSaisie,
      numero,
      nom,
      prenom,
      dateNaissance,
      sel,
      fraisDossierFixes,
      fraisVersementPU,
      fraisVersementPP,
      pourcentUC,
      primeAnnuelle,
      gedIds,
      gedBloquantIds,
      fdcId,
      typeId,
      controle,
      commentaires,
      calculs,
      bloquant,
      created,
      garantieDeces,
      collectifDetails
    } = data

    this.id = id

    this.collaborateurId = collaborateurId
    this.ficheId = ficheId || ''
    this.produitId = produitId
    this.motifChuteId = motifChuteId
    this.fdcId = fdcId
    this.typeId = typeId

    this.gedIds = gedIds || []
    this.gedBloquantIds = gedBloquantIds || []

    this.dateChute = dateChute || ''
    this.dateEffet = dateEffet || ''
    this.dateSaisie = dateSaisie || ''
    this.numero = numero || ''
    this.nom = nom || ''
    this.prenom = prenom || ''
    this.dateNaissance = dateNaissance || ''
    this.sel = sel ? '1' : '0'
    this.fraisDossierFixes = parseNumber(fraisDossierFixes)
    this.fraisVersementPU = parseNumber(fraisVersementPU)
    this.fraisVersementPP = parseNumber(fraisVersementPP)
    this.pourcentUC = parseNumber(pourcentUC)
    this.primeAnnuelle = parseNumber(primeAnnuelle)
    this.controle = !!controle
    this.commentaires = commentaires || ''
    this.calculs = calculs || {}
    this.bloquant = bloquant || {}
    this.created = created || ''
    this.garantieDeces = garantieDeces ? 1 : 0
    this.collectifDetails = collectifDetails || {}
  }

  isNew() {
    return !this.id
  }

  isBloque() {
    const total =
      typeof this.bloquant.total !== 'undefined' ? this.bloquant.total : 0
    return total !== 0
  }

  isChute() {
    return !!this.dateChute && !this.produitId
  }

  hasChute() {
    return !!this.dateChute && !!this.produitId
  }
}

export default ProductionAvenant
