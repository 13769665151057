export default {
  install (Vue) {
    Vue.mixin({
      mounted () {
        $(this.$el)
          .find('.modal')
          .addBack('.modal')
          .each((index, el) => {
            const $el = $(el)
            $el.modal({
              onOpenStart: function (modal) {
                $el.trigger('modal:openStart', [modal])
              },
              onOpenEnd: function (modal) {
                const content = $(modal).find('.modal-content')
                if (content.length) {
                  content.get(0).scrollTop = 0
                  content.css('opacity', 1)
                }

                $el.trigger('modal:openEnd', [modal])
              },
              onCloseStart: function (modal) {
                $el.trigger('modal:closeStart', [modal])
              },
              onCloseEnd: function (modal) {
                $el.trigger('modal:closeEnd', [modal])
              }
            })
          })
      }
    })
  }
}
