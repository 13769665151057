import { forEachValue } from '../../utils'
import Module from './module'

function update (path, targetModule, newModule) {
  targetModule.update(newModule)

  if (newModule.modules) {
    for (var key in newModule.modules) {
      if (!targetModule.getChild(key)) {
        // eslint-disable-next-line
          console.warn(
          "[ModuleCollection:update] trying to add a new module '" +
              key +
              "' on hot reloading, " +
              'manual reload is needed'
        )

        return null
      }
      update(
        path.concat(key),
        targetModule.getChild(key),
        newModule.modules[key]
      )
    }
  }
}

export default class ModuleCollection {
  constructor (rawRootModule) {
    this.register([], rawRootModule, false)
  }

  get (path) {
    return path.reduce(function (module, key) {
      return module.getChild(key)
    }, this.root)
  }

  getNamespace (path) {
    return path.reduce(function (namespace, key) {
      return namespace + key + '/'
    }, '')
  }

  update (rawRootModule) {
    update([], this.root, rawRootModule)
  }

  register (path, rawModule, runtime = true) {
    const this$1 = this

    let _Module = Module
    if (rawModule.model) {
      _Module = rawModule.model
    } else if (this.root && this.root._rawModule.model) {
      _Module = this.root._rawModule.model
    }
    const newModule = new _Module(rawModule, runtime)
    if (path.length === 0) {
      this.root = newModule
    } else {
      const parent = this.get(path.slice(0, -1))
      parent.addChild(path[path.length - 1], newModule)
    }

    if (rawModule.modules) {
      forEachValue(rawModule.modules, function (rawChildModule, key) {
        this$1.register(path.concat(key), rawChildModule, runtime)
      })
    }
  }

  unregister (path) {
    const parent = this.get(path.slice(0, -1))
    const key = path[path.length - 1]
    if (!parent.getChild(key).runtime) {
      return
    }

    parent.removeChild(key)
  }
}
