import ApplicationConfig from '../models/config.model'

export default {
  addConfig: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof ApplicationConfig)) {
        return reject(
          new Error(
            `Erreur paramètre : ${ApplicationConfig.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(`application/configs`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new ApplicationConfig(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
