export const GET_CONFIG_COLLABORATEURS = 'GET_CONFIG_COLLABORATEURS'
export const GET_CONFIG_COLLABORATEURS_SUCCESS =
  'GET_CONFIG_COLLABORATEURS_SUCCESS'
export const GET_CONFIG_COLLABORATEURS_FAIL = 'GET_CONFIG_COLLABORATEURS_FAIL'

export const REFRESH_CONFIG_COLLABORATEURS = 'REFRESH_CONFIG_COLLABORATEURS'
export const REFRESH_CONFIG_COLLABORATEURS_SUCCESS =
  'REFRESH_CONFIG_COLLABORATEURS_SUCCESS'
export const REFRESH_CONFIG_COLLABORATEURS_FAIL =
  'REFRESH_CONFIG_COLLABORATEURS_FAIL'

export default {
  [GET_CONFIG_COLLABORATEURS](state) {
    state.configCollaborateurs_pending = true
  },
  [GET_CONFIG_COLLABORATEURS_SUCCESS](state, configCollaborateurs) {
    state.configCollaborateurs = configCollaborateurs
    state.configCollaborateurs_pending = false
  },
  [GET_CONFIG_COLLABORATEURS_FAIL](state) {
    state.configCollaborateurs_pending = false
  },

  [REFRESH_CONFIG_COLLABORATEURS](state) {
    state.configCollaborateurs_refresh_pending = true
  },
  [REFRESH_CONFIG_COLLABORATEURS_SUCCESS](state, configCollaborateurs) {
    const ids = configCollaborateurs.map(
      (configCollaborateur) => configCollaborateur.id
    )
    state.configCollaborateurs = state.configCollaborateurs.filter(
      (configCollaborateur) => !ids.includes(configCollaborateur.id)
    )
    state.configCollaborateurs.push(...configCollaborateurs)

    state.lastModified = state.configCollaborateurs.reduce(
      (max, configCollaborateur) => {
        return configCollaborateur.modified > max
          ? configCollaborateur.modified
          : max
      },
      0
    )

    state.configCollaborateurs_refresh_pending = false
  },
  [REFRESH_CONFIG_COLLABORATEURS_FAIL](state) {
    state.configCollaborateurs_refresh_pending = false
  }
}
