import AbstractModel from '../abstract/abstract.model'

class Satisfaction extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'id_fiche',
      'id_collaborateur',
      {
        field: 'explication_claire',
        default: {
          note: 0
        }
      },
      {
        field: 'sujets_souhaite',
        default: {
          note: 0
        }
      },
      {
        field: 'remarque_entendu',
        default: {
          note: 0
        }
      },
      {
        field: 'reactivite_cabinet',
        default: {
          note: 0
        }
      },
      {
        field: 'solution_pertinante',
        default: {
          note: 0
        }
      },
      {
        field: 'recommendation_cabinet_entourage',
        default: {
          note: 0,
          justificatif: ''
        }
      },
      {
        field: 'recommendation_conseiller_entourage',
        default: {
          note: 0,
          justificatif: ''
        }
      },
      {
        field: 'connaissance_conseiller',
        default: {
          note: 0,
          justificatif: ''
        }
      },
      {
        field: 'traitement_post_rdv',
        default: {
          note: 0,
          justificatif: ''
        }
      },
      {
        field: 'autre_remarque',
        default: {
          justificatif: ''
        }
      },
      'modified'
    ])
  }
}

export default Satisfaction
