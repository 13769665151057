import DELETE from './api/delete'
import GET from './api/get'
import POST from './api/post'
import PUT from './api/put'

export default {
  DELETE,
  GET,
  POST,
  PUT
}
