import { store } from '@/plugins'

/**
 * Affichage du dashboard si besoin
 * @param {} to
 * @param {} from
 */
function setDisplayed(to) {
  const dashboard = to.meta.dashboard
  const show = dashboard !== false
  store.dispatch('ui/dashboardDisplayed', show)
}

/**
 * On ferme le menu (mobile) lors d'une navigation
 */
function closeSidenav() {
  $(document).trigger('sidenav:slide-out-right:close')
  $(document).trigger('sidenav:sidenav-main:close')
  const main = $('#main')
  if (main.length > 0) {
    main.get(0).scrollTo(0, 0)
  }
}

/**
 * On débloque la page apres une navigation
 */
function unlockScroll() {
  store.dispatch('ui/scrollUnlock')
}

export default function (router) {
  router.afterEach((to, from) => {
    setDisplayed(to, from)
    closeSidenav()
    unlockScroll()
  })
}
