import Model from '../suivihebdo.model'

export default {
  get: ({ GET }, { collaborateurId, semaine, annee }) =>
    new Promise((resolve, reject) => {
      if (!collaborateurId)
        return reject(
          new Error(`Erreur paramètre : collaborateurId non valide`)
        )
      if (!semaine)
        return reject(new Error(`Erreur paramètre : semaine non valide`))
      if (!annee)
        return reject(new Error(`Erreur paramètre : annee non valide`))
      return GET(`suivihebdos/${collaborateurId}/${semaine}/${annee}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  listByAnnee: ({ GET }, { collaborateurId, annee }) =>
    new Promise((resolve, reject) => {
      if (!collaborateurId)
        return reject(
          new Error(`Erreur paramètre : collaborateurId non valide`)
        )
      if (!annee)
        return reject(new Error(`Erreur paramètre : annee non valide`))
      return GET(`suivihebdos/${collaborateurId}/${annee}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            const quests = []
            data.forEach((d) => quests.push(new Model(d)))
            resolve(quests)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET('suivihebdos/list')
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            const quests = []
            data.forEach((d) => quests.push(new Model(d)))
            resolve(quests)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
