import AbstractModel from '../abstract/abstract.model'

class Client extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'nom',
      'prenom',
      'numero',
      'dateNaissance',
      'paysNaissance',
      'paysResidence',
      'correspondance',
      'complementIdentification',
      'mentions',
      'adresse',
      'contact'
    ])
  }
}

export default Client
