import { getDeepValue } from '@/plugins/utils'

/**
 * Affiche un avertissement avant de quitter une page non enregistrée
 * @param {} router
 */
export default function(router) {
  router.beforeEach((to, from, next) => {
    const checkDirtyOnChangeName = getDeepValue(
      from,
      'meta.checkDirtyOnChangeName',
      true
    )

    const fromName = getDeepValue(from, 'name')
    const toName = getDeepValue(to, 'name')

    if (fromName !== toName || !checkDirtyOnChangeName) {
      const dirty = getDeepValue(from, 'meta.dirty')

      const msgEl = document.createElement('span')
      msgEl.innerHTML = `Des données n'ont pas été enregistrées ! <br />Si vous annulez maintenant vous perdrez ces données.`

      if (dirty) {
        return swal({
          title: 'Êtes-vous sûr ?',
          content: { element: msgEl },
          icon: 'warning',
          buttons: {
            cancel: 'Fermer',
            confirm: 'Annuler les modifications',
            closeModal: false
          }
        }).then((value) => {
          if (value) {
            from.meta.dirty = false
            return next()
          }
        })
      }
    }

    return next()
  })
}
