import AbstractModel from '../abstract/abstract.model'

class SuiviHebdo extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'collaborateurId',
      'semaine',
      'annee',
      'nbRDV',
      'nbRDVCC',
      'chutes',
      'nbRDVS1',
      'nbRDVS2',
      'commentaire',
      'heuresDDA',
      'recommandations',
      'projets',
      'devisavenants',
      'avenants',
      'affairesNouvelles',
      'conges',
      'absences'
    ])
  }
}

export default SuiviHebdo
