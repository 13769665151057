export default {
  groups: (state) => state.groups,
  groups_pending: (state) => state.groups_pending,
  groupById: (state) => (id) => state.groups.find((g) => g.id === id),
  isGroupMember: (state) => (collaborateurId) => {
    if (
      state.groups.find((g) => g.membres.includes(parseInt(collaborateurId)))
    ) {
      return true
    }
    return false
  },
  groupByCollabId: (state) => (collaborateurId) =>
    state.groups.find((g) => g.membres.includes(parseInt(collaborateurId)))
}
