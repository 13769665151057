export default {
  install (Vue) {
    Vue.mixin({
      mounted () {
        $(this.$el).on('focus', '.clearOnFocus', function () {
          $(this)
            .val('')
            .keydown()
            .keyup()
            .removeClass('clearOnFocus')
        })

        $(this.$el)
          .find('.waves-effect')
          .each(function () {
            Waves.attach(this)
          })
      }
    })
  }
}
