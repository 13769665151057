import Recommandation from '../recommandation.model'

export default {
  recommandations: state => state.recommandations,
  recommandationById: state => id => {
    const recommandation = state.recommandations
      ? state.recommandations.find(recommandation => recommandation.id === id)
      : null
    return recommandation ? new Recommandation(recommandation) : null
  },
  recommandations_pending: state => state.recommandations_pending
}
