import Model from '../models/agence.model'
import AgenceRole from '../models/role.model'

export default {
  add: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(`agences`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  addCollaborateur: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof AgenceRole)) {
        return reject(
          new Error(
            `Erreur paramètre : ${AgenceRole.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(
        `agences/${model.getProperty('agenceId')}/collaborateurs`,
        model
      )
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new AgenceRole(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
