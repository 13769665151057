import AbstractModel from '../../abstract/abstract.model'
import { api } from '@/plugins'
import { NOTIFICATION_STATE } from '../notification.data'

class Notification extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'type',
      'state',
      'created',
      'modified',
      'title',
      'short_description',
      'description',
      'data',
      'color'
    ])
  }

  read() {
    return api
      .POST('notification/state', {
        model: this,
        state: NOTIFICATION_STATE.READ
      })
      .then((notif) => {
        this.resetModelRef()
        return notif
      })
  }

  unread() {
    return api
      .POST('notification/state', {
        model: this,
        state: NOTIFICATION_STATE.UNREAD
      })
      .then((notif) => {
        this.resetModelRef()
        return notif
      })
  }

  delete() {
    return api.DELETE('notification/delete', this)
  }
}

export default Notification
