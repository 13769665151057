export const NOTIFICATION_TYPE = {
  INSTANCE: 'INSTANCE',
  TELETRANSMISSION: 'TELETRANSMISSION',
  BIENETRE: 'BIENETRE'
}

export const NOTIFICATION_STATE = {
  UNREAD: 'UNREAD',
  READ: 'READ',
  DELETED: 'DELETED'
}
