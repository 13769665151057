import Client from '../client.model'

export default {
  clients: state => state.clients,
  clientById: state => id => {
    const client = state.clients
      ? state.clients.find(client => client.id === id)
      : null
    return client ? new Client(client) : null
  },
  clients_pending: state => state.clients_pending
}
