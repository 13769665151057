import Configuration from '../configuration.model'

export default {
  edit: ({ PUT }, configuration) =>
    new Promise((resolve, reject) => {
      if (!(configuration instanceof Configuration)) {
        return reject(new Error(
          `Erreur paramètre : Configuration attendu mais reçu "${configuration.constructor.name}"`
        ))
      } else if (configuration.isNew()) {
        return reject(new Error(`Erreur paramètre : Configuration is new`))
      }

      return PUT(`production/configurations/${configuration.id}`, configuration)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const configuration = new Configuration(data)
            resolve(configuration)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
