import { api } from '@/plugins'
import AbstractModel, {
  CALLBACK_NUMBER,
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'

import FicheCabinet from './cabinet.model'
import FicheContrat from './contrat.model'

class FicheEntreprise extends AbstractModel {
  constructor(data) {
    super(data, [
      'adresse_personnelle',
      'nom',
      'adresse',
      'cp',
      'ville',
      'villeId',
      'activite',
      {
        field: 'forme_juridique',
        callBack: CALLBACK_SUBDOC_ID
      },
      {
        field: 'date_creation',
        callBack: CALLBACK_NUMBER
      },
      'code_ape',
      'n_siret',
      'n_lei',
      {
        field: 'n_lei_validite',
        callBack: CALLBACK_NUMBER
      },
      'capital_social',
      'capital_social_modified',
      'capitalSocialDatemodif',
      {
        field: 'identifiant_cc',
        callBack: CALLBACK_SUBDOC_ID
      },
      'n_brochure',
      'frais_generaux',
      'frais_generaux_modified',
      'fraisGenerauxDatemodif',
      'ca',
      'ca_modified',
      'caDatemodif',
      'bic',
      'bic_modified',
      'bicDatemodif',
      'resultats',
      'resultats_modified',
      'resultatsDatemodif',
      'imposition',
      {
        field: 'taux_marginal',
        callBack: CALLBACK_SUBDOC_ID
      },
      'imposition_taux',
      'imposition_taux_is',
      {
        field: 'taux_is',
        callBack: CALLBACK_SUBDOC_ID
      },
      'nb_salarie',
      'nb_cadre',
      'moyenne_age_cadre',
      'nb_non_cadre',
      'moyenne_age_non_cadre',
      {
        field: 'actionnaires',
        default: []
      },
      {
        field: 'interlocuteurs',
        default: []
      },
      {
        field: 'cabinet',
        callBack: (value) => new FicheCabinet(value)
      },
      {
        field: 'contrats',
        default: [],
        callBack: (values) => values.map((value) => new FicheContrat(value))
      }
    ])
  }

  getCabinet = function () {
    return this.getProperty('cabinet')
  }

  getContrats = function () {
    return this.getProperty('contrats')
  }
  setContrats = function (contrats) {
    return this.setProperty('contrats', contrats)
  }
  async cabinetRequest(data) {
    const response = await api.POST('fiche/cabinetRequest', data)
    if (response) {
      M.toast({
        html: `La requète d'ajout a été envoyée.`,
        classes: 'green'
      })
    }
    return response
  }
}

export default FicheEntreprise
