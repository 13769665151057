import axios from 'axios'
import { install } from './install'
import { assert } from '@/plugins/utils'
import ModuleManager from '@/plugins/ModuleManager'
import { forEachValue, isObject } from '../utils'
import AbstractModel from '@/modules/abstract/abstract.model'

const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export let HTTP = null

function dataToQuery (data, prefix = '') {
  if (typeof data === 'undefined') {
    return
  }

  if (Array.isArray(data)) {
    return '=' + data.join(',')
  } else if (isObject(data)) {
    let _datas = []

    forEachValue(data, (v, k) => {
      if (typeof v !== 'undefined') {
        let _prefix = prefix + (!prefix ? `${k}` : `[${k}]`)
        let strValue = dataToQuery(v, _prefix)
        if (!isObject(v) || Array.isArray(v)) {
          strValue = _prefix + strValue
        }
        _datas.push(strValue)
      }
    })

    return _datas.join('&')
  } else {
    return '=' + data
  }
}

function dataExport (data) {
  if (data instanceof AbstractModel) {
    return data.getExport()
  }

  return data
}

function _GET (path, dataQuery = {}) {
  const query = dataToQuery(dataQuery)
  const url = path + (query ? `?${query}` : '')
  return HTTP.get(url)
}

function _POST (path, data, dataQuery) {
  const query = dataToQuery(dataQuery)
  const url = path + (query ? `?${query}` : '')
  return HTTP.post(url, dataExport(data))
}

function _PUT (path, data, dataQuery) {
  const query = dataToQuery(dataQuery)
  const url = path + (query ? `?${query}` : '')
  return HTTP.put(url, dataExport(data))
}

function _DELETE (path, data, dataQuery) {
  const query = dataToQuery(dataQuery)
  const url = path + (query ? `?${query}` : '')
  return HTTP.delete(url, dataExport(data))
}

export default class Api extends ModuleManager {
  static install = install;

  modules = {};
  apps = [];

  constructor (options = {}) {
    HTTP = axios.create({
      baseURL: options.baseUrl
    })

    const handlers = {
      GET: _GET,
      POST: _POST,
      PUT: _PUT,
      DELETE: _DELETE,
      HTTP: HTTP
    }

    super(options, methods, handlers)
  }

  init (app) {
    process.env.NODE_ENV !== 'production' &&
      assert(
        install.installed,
        "non installé. Appel manquant `Vue.use(Api)` avant la création de l'instance root."
      )

    this.apps.push(app)

    // set up app destroyed handler
    app.$once('hook:destroyed', () => {
      // clean out app from this.apps array once destroyed
      const index = this.apps.indexOf(app)
      if (index > -1) this.apps.splice(index, 1)
      // ensure we still have a main app or null if no apps
      // we do not release the router so it can be reused
      if (this.app === app) this.app = this.apps[0] || null
    })

    if (this.app) {
      return
    }

    this.app = app
    this.app._api = this
  }

  GET (_type, _data) {
    return this._callAction(methods.GET, _type, _data)
  }

  POST (_type, _data) {
    return this._callAction(methods.POST, _type, _data)
  }

  PUT (_type, _data) {
    return this._callAction(methods.PUT, _type, _data)
  }

  DELETE (_type, _data) {
    return super._callAction(methods.DELETE, _type, _data)
  }
}
