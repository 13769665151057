export const GET_FICHES = 'GET_FICHES'
export const GET_FICHES_SUCCESS = 'GET_FICHES_SUCCESS'
export const GET_FICHES_FAIL = 'GET_FICHES_FAIL'
export const REFRESH_FICHES = 'REFRESH_FICHES'
export const REFRESH_FICHES_SUCCESS = 'REFRESH_FICHES_SUCCESS'
export const REFRESH_FICHES_FAIL = 'REFRESH_FICHES_FAIL'
export const ON_DIRTY_SET = 'ON_DIRTY_SET'
export const REMOVE_LOCAL_FICHE = 'REMOVE_LOCAL_FICHE'

export default {
  [GET_FICHES](state) {
    state.fiches_pending = true
  },
  [GET_FICHES_SUCCESS](state, fiches) {
    state.fiches = fiches

    state.lastModified = state.fiches.reduce((max, fiche) => {
      return fiche.modified > max ? fiche.modified : max
    }, 0)

    state.fiches_pending = false
  },
  [GET_FICHES_FAIL](state) {
    state.fiches_pending = false
  },

  [REFRESH_FICHES](state) {
    state.fiches_refresh_pending = true
  },
  [REFRESH_FICHES_SUCCESS](state, fiches) {
    const ids = fiches.map((fiche) => fiche.id)
    state.fiches = state.fiches.filter((fiche) => !ids.includes(fiche.id))
    state.fiches.push(...fiches)

    state.lastModified = state.fiches.reduce((max, fiche) => {
      return fiche.modified > max ? fiche.modified : max
    }, 0)

    state.fiches_refresh_pending = false
  },
  [REFRESH_FICHES_FAIL](state) {
    state.fiches_refresh_pending = false
  },
  [ON_DIRTY_SET](state, isDirty) {
    state.isDirty = !!isDirty
  },
  [REMOVE_LOCAL_FICHE](state, id) {
    state.fiches = state.fiches.filter((fiche) => fiche.id !== id)
  }
}
