export const GET_STAT = 'GET_STAT'
export const GET_STAT_SUCCESS = 'GET_STAT_SUCCESS'
export const GET_STAT_FAIL = 'GET_STAT_FAIL'

export default {
  [GET_STAT](state) {
    state.models_pending = true
  },
  [GET_STAT_SUCCESS](state, model) {
    const key = model.getKey()
    if (key) {
      state.models = state.models.filter((m) => m.getKey() !== key)
      state.models.push(model)
    }
    state.models_pending = false
  },
  [GET_STAT_FAIL](state) {
    state.models_pending = false
  }
}
