import User from '../user.model'

export default {
  me: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`users/me`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const user = new User(data)
            resolve(user)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  get: ({ GET }, idUser) =>
    new Promise((resolve, reject) => {
      return GET(`users/${idUser}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const user = new User(data)
            resolve(user)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`users`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let users = []
            data.forEach((userData) => {
              users.push(new User(userData))
            })
            resolve(users)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  collaborateurs: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET('users/collaborateurs')
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach((modelData) => {
              models.push(new User(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  getStatTrimestre: ({ GET }, { idUser, annee, trimestre }) =>
    new Promise((resolve, reject) => {
      return GET(`stats/collaborateur-${idUser}-${annee}-${trimestre}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
