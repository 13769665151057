import AbstractModel from '../../abstract/abstract.model'

class CaisseLigne extends AbstractModel {
  constructor (data) {
    super(data, [
      'id',
      'caisseId',
      'nom',
      'prenom',
      'contrat',
      'recette',
      'depense',
      'commentaire',
      'date'
    ])
  }

  getCaisseId () {
    return this.getProperty('caisseId')
  }
}

export default CaisseLigne
