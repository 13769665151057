function collapsible() {
  $(this.$el)
    .find('.collapsible:not(.app-collapsible)')
    .collapsible({
      accordion:
        typeof $(this.$el).data('accordion') === 'undefined'
          ? true
          : !!$(this.$el).data('accordion'),

      onOpenStart: () => {
        $(this.$el).find('li.open').removeClass('open')

        setTimeout(() => {
          $(this.$el).find('li.active > a').parents('li').addClass('open')
        }, 10)
      }
    })
}

export default {
  install(Vue) {
    Vue.mixin({
      mounted() {
        collapsible.bind(this)()
      },
      updated() {
        collapsible.bind(this)()
      }
    })
  }
}
