import { api } from '@/plugins'
import {
  GET_CONTRATS,
  GET_CONTRATS_SUCCESS,
  GET_CONTRATS_FAIL,
  REFRESH_CONTRATS,
  REFRESH_CONTRATS_SUCCESS,
  REFRESH_CONTRATS_FAIL
} from './mutations'

export default {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_CONTRATS)

      api
        .GET('production/contrats/list', data)
        .then(async (datas) => {
          await commit(GET_CONTRATS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_CONTRATS_FAIL)
          reject(e)
        })
    })
  },
  refresh({ commit, state }) {
    const lastModified = state.lastModified
    return new Promise((resolve, reject) => {
      commit(REFRESH_CONTRATS)

      const query = {}
      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('production/contrats/list', query)
        .then(async (datas) => {
          await commit(REFRESH_CONTRATS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_CONTRATS_FAIL)
          reject(e)
        })
    })
  }
}
