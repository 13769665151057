export default {
  delete: ({ DELETE }, idGroup) =>
    new Promise((resolve, reject) => {
      if (!idGroup) {
        reject(new Error(`Erreur paramètre : idGroup non valide "${idGroup}"`))
      }
      return DELETE(`groups/${idGroup}`)
        .then((reponse) => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
