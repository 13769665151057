import { parseNumber } from '@/plugins/utils'

export const GET_PRODUITS = 'GET_PRODUITS'
export const GET_PRODUITS_SUCCESS = 'GET_PRODUITS_SUCCESS'
export const GET_PRODUITS_FAIL = 'GET_PRODUITS_FAIL'
export const REFRESH_PRODUITS = 'REFRESH_PRODUITS'
export const REFRESH_PRODUITS_SUCCESS = 'REFRESH_PRODUITS_SUCCESS'
export const REFRESH_PRODUITS_FAIL = 'REFRESH_PRODUITS_FAIL'
export const GET_GROUPES = 'GET_GROUPES'
export const GET_GROUPES_SUCCESS = 'GET_GROUPES_SUCCESS'
export const GET_GROUPES_FAIL = 'GET_GROUPES_FAIL'
export const GET_FAMILLES = 'GET_FAMILLES'
export const GET_FAMILLES_SUCCESS = 'GET_FAMILLES_SUCCESS'
export const GET_FAMILLES_FAIL = 'GET_FAMILLES_FAIL'
export const GET_STATISTIQUES = 'GET_STATISTIQUES'
export const GET_STATISTIQUES_SUCCESS = 'GET_STATISTIQUES_SUCCESS'
export const GET_STATISTIQUES_FAIL = 'GET_STATISTIQUES_FAIL'

export default {
  [GET_PRODUITS](state) {
    state.produits_pending = true
  },
  [GET_PRODUITS_SUCCESS](state, produits) {
    state.produits = produits
    state.produits_pending = false
  },
  [GET_PRODUITS_FAIL](state) {
    state.produits_pending = false
  },
  [REFRESH_PRODUITS](state) {
    state.produits_refresh_pending = true
  },
  [REFRESH_PRODUITS_SUCCESS](state, produits) {
    const ids = produits.map((produit) => produit.id)
    state.produits = state.produits.filter(
      (produit) => !ids.includes(produit.id)
    )
    state.produits.push(...produits)

    state.lastModified = state.produits.reduce((max, produit) => {
      const modified = parseNumber(produit.modified)
      return modified > max ? modified : max
    }, 0)

    state.produits_refresh_pending = false
  },
  [REFRESH_PRODUITS_FAIL](state) {
    state.produits_refresh_pending = false
  },
  [GET_GROUPES](state) {
    state.groupes_pending = true
  },
  [GET_GROUPES_SUCCESS](state, groupes) {
    state.groupes = groupes
    state.groupes_pending = false
  },
  [GET_GROUPES_FAIL](state) {
    state.groupes_pending = false
  },
  [GET_FAMILLES](state) {
    state.familles_pending = true
  },
  [GET_FAMILLES_SUCCESS](state, familles) {
    state.familles = familles
    state.familles_pending = false
  },
  [GET_FAMILLES_FAIL](state) {
    state.familles_pending = false
  },
  [GET_STATISTIQUES](state) {
    state.statistiques_pending = true
  },
  [GET_STATISTIQUES_SUCCESS](state, statistiques) {
    state.statistiques = statistiques
    state.statistiques_pending = false
  },
  [GET_STATISTIQUES_FAIL](state) {
    state.statistiques_pending = false
  }
}
