import moment from 'moment'
import AbstractModel, { CALLBACK_NUMBER } from '../../abstract/abstract.model'

class FicheEnfant extends AbstractModel {
  constructor(data) {
    super(data, [
      'nom',
      'prenom',
      {
        field: 'naissance',
        callBack: CALLBACK_NUMBER
      },
      'naissance_pays',
      'naissance_cp',
      'naissance_ville',
      'naissance_villeId',
      'n_secu',
      'n_secu_heritage',
      ['a_charge', 1, (value) => !!value],
      'telephone',
      'situation',
      'type_adoption'
    ])

    if (!this.getProperty('n_secu_heritage') && this.getProperty('n_secu')) {
      this.setProperty('n_secu_heritage', 'autre')
    }
  }

  getNumeroSecu = function (fiche) {
    const nSecuHeritage = this.getProperty('n_secu_heritage')
    const nSecu = this.getProperty('n_secu')

    const clientDatas = fiche.getClient().getDatas()
    const conjointDatas = fiche.getClient().hasConjoint()
      ? fiche.getConjoint().getDatas()
      : null

    const nc = 'N.C.'

    if (nSecuHeritage === 'autre') {
      return nSecu ?? nc
    } else if (nSecuHeritage === 'client' && clientDatas) {
      return clientDatas.n_secu ?? nc
    } else if (nSecuHeritage === 'conjoint' && conjointDatas) {
      return conjointDatas.n_secu ?? nc
    }

    return nc
  }

  getNaissance = function () {
    const dateNaissance = this.getProperty('naissance')
    let naissance = moment.unix(dateNaissance).format('DD/MM/YYYY')

    const naissanceCp = this.getProperty('naissance_cp')
    const naissanceVille = this.getProperty('naissance_ville')
    if (naissanceVille && naissanceCp) {
      naissance += ` à ${naissanceVille} (${naissanceCp})`
    }

    return naissance
  }
}

export default FicheEnfant
