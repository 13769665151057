export const GET_CONTRATS = 'GET_CONTRATS'
export const GET_CONTRATS_SUCCESS = 'GET_CONTRATS_SUCCESS'
export const GET_CONTRATS_FAIL = 'GET_CONTRATS_FAIL'
export const REFRESH_CONTRATS = 'REFRESH_CONTRATS'
export const REFRESH_CONTRATS_SUCCESS = 'REFRESH_CONTRATS_SUCCESS'
export const REFRESH_CONTRATS_FAIL = 'REFRESH_CONTRATS_FAIL'

export default {
  [GET_CONTRATS](state) {
    state.contrats_pending = true
  },
  [GET_CONTRATS_SUCCESS](state, contrats) {
    state.contrats = contrats
    state.contrats_pending = false
  },
  [GET_CONTRATS_FAIL](state) {
    state.contrats_pending = false
  },

  [REFRESH_CONTRATS](state) {
    state.contrats_refresh_pending = true
  },
  [REFRESH_CONTRATS_SUCCESS](state, contrats) {
    const ids = contrats.map((contrat) => contrat.id)
    state.contrats = state.contrats.filter(
      (contrat) => !ids.includes(contrat.id)
    )
    state.contrats.push(...contrats)

    state.lastModified = state.contrats.reduce((max, contrat) => {
      return contrat.modified > max ? contrat.modified : max
    }, 0)

    state.contrats_refresh_pending = false
  },
  [REFRESH_CONTRATS_FAIL](state) {
    state.contrats_refresh_pending = false
  }
}
