import AbstractModel, {
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'

import FichierManager from '@/modules/fichier/fichierManager.model'
import FicheCompteRendu from './compterendu.model'
import FicheSignature from './signature.model'
import FicheDevis from './devis.model'

export const STATE_OPEN = 'STATE_OPEN'
export const STATE_CLOSE = 'STATE_CLOSE'

class FicheVisite extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      {
        field: 'type',
        callBack: CALLBACK_SUBDOC_ID
      },
      'collaborateurId',
      'texte',
      ['state', STATE_OPEN],
      'date_cloture',
      {
        field: 'fichiers',
        default: [],
        callBack: (value) => new FichierManager(value)
      },
      {
        field: 'devis',
        default: [],
        callBack: (values) => values.map((value) => new FicheDevis(value))
      },
      {
        field: 'compteRendus',
        default: [],
        callBack: (values) => values.map((value) => new FicheCompteRendu(value))
      },
      {
        field: 'signature',
        callBack: (value) => new FicheSignature(value)
      },
      'created'
    ])
  }

  getState() {
    return this.getProperty('state')
  }
  setState(state) {
    const newState = [STATE_OPEN, STATE_CLOSE].includes(state)
      ? state
      : this.getState()
    return this.setProperty('state', newState)
  }

  addDevis(id) {
    const devis = this.getProperty('devis', [])
    devis.push(id)
    this.setProperty('devis', devis)
  }

  getDevis() {
    return this.getProperty('devis', [])
  }

  addCompteRendu(compteRendu) {
    const compteRendus = this.getProperty('compteRendus', [])
    compteRendus.push(compteRendu)
    this.setProperty('compteRendus', compteRendus)
  }

  getCompteRendus() {
    return this.getProperty('compteRendus', [])
  }

  setSignature(signature) {
    return this.setProperty('signature', signature || {})
  }

  getSignature() {
    return this.getProperty('signature')
  }

  hasSignature() {
    const signature = this.getProperty('signature')
    return signature && !signature.isNew()
  }
}

export default FicheVisite
