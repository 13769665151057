import AbstractModel, {
  CALLBACK_NUMBER,
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'

class FicheEpargne extends AbstractModel {
  constructor(data) {
    super(data, [
      {
        field: 'type',
        callBack: CALLBACK_SUBDOC_ID
      },
      'numero',
      'cotisation',
      'cotisation_modified',
      'cotisationDatemodif',
      {
        field: 'organisme',
        callBack: CALLBACK_SUBDOC_ID
      },
      {
        field: 'ouverture',
        callBack: CALLBACK_NUMBER
      },
      'stock',
      'stock_modified',
      'stockDatemodif',
      'versement_mensuel',
      'versement_mensuel_modified',
      'versementMensuelDatemodif',
      ['titulaire', 'client']
    ])
  }
}

export default FicheEpargne
