import Model from '../contrat.model'
import { compareStr } from '@/plugins/utils'

export default {
  contrats: (state) => state.contrats,
  contratById: (state) => (id) => {
    const model = state.contrats
      ? state.contrats.find((model) => compareStr(model.id)(id))
      : null
    return model ? new Model(model) : null
  },
  contratsByCollabId: (state) => (id) =>
    state.contrats.filter((c) => compareStr(c.collaborateurId)(id)),
  contratsByGroup: (state) => (group) => {
    let result = []
    group.membres.forEach((id) => {
      result = result.concat(
        state.contrats.filter((c) => compareStr(c.collaborateurId)(id))
      )
    })
    return result.map((model) => (model ? new Model(model) : null))
  },
  contrats_pending: (state) => state.contrats_pending,
  contrats_refresh_pending: (state) => state.contrats_refresh_pending
}
