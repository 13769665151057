import AbstractModel from '../abstract/abstract.model'
import apiConfigs from '@/configs/api'

class Fichier extends AbstractModel {
  constructor (data) {
    super(data, ['id', 'name', 'deleted', 'data'])
  }

  getName = function () {
    return this.getProperty('data.name', this.getProperty('name'))
  };

  getSize = function () {
    return this.getProperty('data.size', this.getSrcProperty('size', 0))
  };

  getSizeReadable = function () {
    return this.getProperty('data.size2', this.getSrcProperty('size2', 0))
  };

  getType = function () {
    return this.getProperty('data.type', this.getSrcProperty('type'))
  };

  getFormat = function () {
    return this.getProperty('data.format', this.getSrcProperty('format'))
  };

  getUrl = function () {
    const urlApi = apiConfigs.baseUrl
    return `${urlApi}fichiers/${this.getId()}`
  };

  getUrlDownload = function () {
    const url = this.getUrl()
    return `${url}/download`
  };

  isDeleted = function () {
    return !!this.getProperty('deleted')
  };

  isPreload = function () {
    return !!this.getProperty('data.preload', this.getProperty('preload'))
  };

  getExport = function () {
    return this.getId()
  };
}

export default Fichier
