export const APPLICATION_CONFIG = 'APPLICATION_CONFIG'
export const APPLICATION_CONFIG_SUCCESS = 'APPLICATION_CONFIG_SUCCESS'
export const APPLICATION_CONFIG_FAIL = 'APPLICATION_CONFIG_FAIL'

export default {
  [APPLICATION_CONFIG](state) {
    state.configs_pending = true
  },
  [APPLICATION_CONFIG_SUCCESS](state, config) {
    state.configs[config.getAnnee()] = config
    state.configs_pending = false
  },
  [APPLICATION_CONFIG_FAIL](state) {
    state.configs_pending = false
  }
}
