export default {
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`clients`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
