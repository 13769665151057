module.exports = {
  publicPath: process.env.NODE_ENV === 'production' ? '/' : '/',
  lintOnSave: process.env.NODE_ENV !== 'production',
  chainWebpack: (config) => {
    // On retire le plugin prefetch
    config.plugins.delete('prefetch')

    // On utilise le plugin preload
    config.plugin('preload').tap((options) => {
      options[0].as = (entry) => {
        if (/\.css$/.test(entry)) return 'style'
        if (/\.woff$/.test(entry)) return 'font'
        if (/\.png$/.test(entry)) return 'image'
        return 'script'
      }
      return options
    })
  }
}
