class Group {
  constructor(data = {}) {
    const { id, nom, membres, created, couleur } = data

    this.id = id
    this.nom = nom || ''
    this.membres = membres || []
    this.created = created
    this.couleur = couleur || this.randomColor()
  }

  isNew() {
    return !this.id
  }

  randomColor() {
    return `#${((Math.random() * 0xffffff) << 0).toString(16)}`
  }
}

export default Group
