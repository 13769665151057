export const GET_INSTANCES = 'GET_INSTANCES'
export const GET_INSTANCES_SUCCESS = 'GET_INSTANCES_SUCCESS'
export const GET_INSTANCES_FAIL = 'GET_INSTANCES_FAIL'
export const UPDATE_INSTANCE = 'UPDATE_INSTANCE'

export default {
  [GET_INSTANCES](state) {
    state.instances_pending = true
  },
  [GET_INSTANCES_SUCCESS](state, instances) {
    state.instances = instances
    state.instances_pending = false
  },
  [GET_INSTANCES_FAIL](state) {
    state.instances_pending = false
  },
  [UPDATE_INSTANCE](state, instanceModif) {
    const index = state.instances.findIndex((i) => i.id === instanceModif.id)
    if (index == -1) {
      return false
    }

    const instance = state.instances[index]
    state.instances[index] = Object.assign(instance, instanceModif)
  }
}
