import AbstractModel, {
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'

class FicheCredit extends AbstractModel {
  constructor(data) {
    super(data, [
      {
        field: 'type',
        callBack: CALLBACK_SUBDOC_ID
      },
      'montant_initial',
      'montant_initial_modified',
      'montantInitialDatemodif',
      'montant_restant',
      'montant_restant_modified',
      'montantRestantDatemodif',
      'mensualite',
      'mensualite_modified',
      'mensualiteDatemodif',
      'taux',
      'taux_modified',
      'tauxDatemodif',
      {
        field: 'organisme',
        callBack: CALLBACK_SUBDOC_ID
      },
      'date_initiale',
      'duree',
      'duree_modified',
      'dureeDatemodif',
      {
        field: 'paliers',
        default: []
      }
    ])
  }
}

export default FicheCredit
