import AbstractModel, {
  CALLBACK_NUMBER,
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'

class FicheContrat extends AbstractModel {
  constructor(data) {
    super(data, [
      {
        field: 'type',
        callBack: CALLBACK_SUBDOC_ID
      },
      'numero',
      'cotisation',
      'organismeOld',
      {
        field: 'organisme',
        callBack: CALLBACK_SUBDOC_ID
      },
      {
        field: 'echeance',
        callBack: CALLBACK_NUMBER
      },

      'fumeur',
      'direction',
      'sport_licence',
      'sports',

      {
        field: 'ouverture',
        callBack: CALLBACK_NUMBER
      },
      'stock',

      'commentaire'
    ])
  }
}

export default FicheContrat
