export default {
  install (Vue) {
    Vue.mixin({
      mounted () {
        // Ascenseur
        $(this.$el)
          .find('.app-perfectscrollbar')
          .addBack('.app-perfectscrollbar')
          .each(function () {
            if (!this._perfectscrollbar) {
              const options = $(this).hasClass('app-perfectscrollbar-no-x')
                ? {
                  suppressScrollX: true,
                  wheelSpeed: 0.5
                }
                : {
                  wheelSpeed: 0.5,
                  wheelPropagation: true,
                  minScrollbarLength: 20
                }
              this._perfectscrollbar = new PerfectScrollbar(this, options)

              $(document).on('resize', () => {
                this._perfectscrollbar.update()
              })

              $(this).on('scroll click', () => {
                this._perfectscrollbar.update()
              })
            }
          })
      }
    })
  }
}
