import routesDefault from './routes-default'
import routesApp from './routes'
import { publicPath } from '@/../vue.config.js'

export const PATH_LOGIN = '/login'

export const routes = [...routesDefault, ...routesApp]

export const configs = {
  routes,
  base: publicPath,
  mode: 'history'
}
