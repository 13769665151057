import { PATH_LOGIN } from '@/configs/router'

import { store } from '@/plugins'

/**
 * Retourne true si au moins un groupe est commun
 * @param {} groupsUser
 * @param {} route
 */
function isUserAllowed(user, route) {
  const groupsAllowed = route.meta.credentials

  return user.isAllowed(groupsAllowed)
}

function getUser() {
  let user = store.getters['user/user']
  if (!user) {
    return store.dispatch('user/refresh')
  }
  return Promise.resolve(user)
}

const hasCredential = (route) => {
  return !!route.meta.credentials && route.meta.credentials.length > 0
}

const isRouteAllowed = (route) =>
  new Promise((resolve, reject) => {
    // Si la route ne demande pas d'autorisation
    if (!hasCredential(route)) {
      return resolve()
    }

    getUser()
      .then((user) => {
        const granted = isUserAllowed(user, route)
        if (!granted) {
          M.toast({
            html: `Vous n'avez pas la permission "${route.meta.credentials}" pour accéder à cette page`,
            classes: 'orange'
          })
          return reject()
        }
        return resolve()
      })
      .catch((e) => {
        return reject(e)
      })
  })

export default function (router) {
  router.beforeEach((to, from, next) => {
    isRouteAllowed(to)
      .then(() => next())
      .catch(() => {
        M.toast({
          html: `Vous avez été déconnecté`
        })
        next({
          path: PATH_LOGIN,
          query: {
            redirect: to.fullPath
          }
        })
      })
  })
}
