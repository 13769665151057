import Vue from 'vue'
import VueChartJs from 'vue-chartjs'

const chartNames = [
  {
    name: 'chart-ligne',
    type: VueChartJs.Line
  },
  {
    name: 'chart-barre',
    type: VueChartJs.Bar
  },
  {
    name: 'chart-barre-horizontale',
    type: VueChartJs.HorizontalBar
  },
  {
    name: 'chart-donut',
    type: VueChartJs.Doughnut
  },
  {
    name: 'chart-camembert',
    type: VueChartJs.Pie
  },
  {
    name: 'chart-radar',
    type: VueChartJs.Radar
  },
  {
    name: 'chart-zone',
    type: VueChartJs.PolarArea
  },
  {
    name: 'chart-bulle',
    type: VueChartJs.Bubble
  },
  {
    name: 'chart-point',
    type: VueChartJs.Scatter
  }
]

const registerCharts = (chartNames) => {
  chartNames.forEach((chartName) => {
    Vue.component(chartName.name, {
      extends: chartName.type,
      mixins: [VueChartJs.mixins.reactiveProp],
      props: {
        options: {
          type: Object,
          default: () => ({})
        }
      },
      mounted() {
        this.renderChart(this.chartData, this.options)
      },
      methods: {
        update() {
          this.$data._chart.update()
        }
      }
    })
  })
}

registerCharts(chartNames)
