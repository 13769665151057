import Model from '../suivihebdo.model'

export default {
  edit: ({ PUT }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      } else if (model.isNew()) {
        return reject(
          new Error(`Erreur paramètre : ${Model.constructor.name} is new`)
        )
      }

      return PUT(`suivihebdos/${model.getId()}`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
