import { api } from '@/plugins'
import {
  GET_AVENANTS,
  GET_AVENANTS_SUCCESS,
  GET_AVENANTS_FAIL,
  REFRESH_AVENANTS,
  REFRESH_AVENANTS_SUCCESS,
  REFRESH_AVENANTS_FAIL
} from './mutations'

export default {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_AVENANTS)

      api
        .GET('production/avenants/list', data)
        .then(async (datas) => {
          await commit(GET_AVENANTS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_AVENANTS_FAIL)
          reject(e)
        })
    })
  },
  refresh({ commit, state }) {
    const lastModified = state.lastModified
    return new Promise((resolve, reject) => {
      commit(REFRESH_AVENANTS)

      const query = {}
      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('production/avenants/list', query)
        .then(async (datas) => {
          await commit(REFRESH_AVENANTS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_AVENANTS_FAIL)
          reject(e)
        })
    })
  }
}
