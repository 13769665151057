export const TYPE_GESTION = 'TYPE_GESTION'
export const TYPE_COMMERCIALE = 'TYPE_COMMERCIALE'
export const TYPE_DIRECTION = 'TYPE_DIRECTION'

export const types = [
  {
    api: 'gestion',
    app: TYPE_GESTION
  },
  {
    api: 'commerciale',
    app: TYPE_COMMERCIALE
  },
  {
    api: 'direction',
    app: TYPE_DIRECTION
  }
]

export function getApiType(type) {
  const apiType = types.find((t) => t.app === type)
  return apiType ? apiType.api : null
}
