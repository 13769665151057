import { compareStr, parseNumber } from '@/plugins/utils'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { getTrimestreIntervalleByDate } from '../date'

/* eslint-disable camelcase */
class Production_Produit {
  constructor(data = {}) {
    const {
      id,
      nom,
      groupe,
      famille,
      formulaire,
      produitCollectifType,

      configured,
      configurations,
      _configurations,
      modified
    } = cloneDeep(data)

    this.id = id
    this.nom = nom || ''
    this.groupe = groupe || ''
    this.famille = famille || ''
    this.formulaire = formulaire || ''
    this.produitCollectifType = produitCollectifType || ''

    this.configured = !!configured
    this.configurations = {}
    this._configurations = Array.isArray(configurations)
      ? configurations
      : _configurations ?? []

    this.modified = parseNumber(modified)
  }

  isNew() {
    return !this.id
  }

  isCollectif() {
    return this.produitCollectifType !== ''
  }

  getConfig(annee) {
    return getConfig(this, annee)
  }
}

export function getConfig(produit, annee) {
  if (!produit) {
    return {}
  }

  if (!produit._configurations) {
    if (Array.isArray(produit.configurations)) {
      produit._configurations = produit.configurations
    } else {
      console.log('no config')
      return {}
    }
  }

  if (!annee) {
    const date = moment()
    const trimestreIntervalle = getTrimestreIntervalleByDate(date.toDate())
    if (!trimestreIntervalle) {
      console.log('out date')
      return {}
    }
    annee = trimestreIntervalle.annee
  }

  let config = cloneDeep(
    produit._configurations.find((c) => compareStr(c.annee)(annee)) ??
      produit._configurations.reduce(
        (r, config) => (r.annee > config.annee ? r : config),
        {}
      )
  )

  if (config) {
    config.annee = annee

    config.surcommissions = {
      surcommission: String(config.surcommissions?.surcommission || ''),
      pourcentUcMin: parseNumber(config.surcommissions?.pourcentUcMin) || '',
      pourcentUc: parseNumber(config.surcommissions?.pourcentUc) || '',
      pourcentEuro: parseNumber(config.surcommissions?.pourcentEuro) || '',
      pourcentPpFixe: parseNumber(config.surcommissions?.pourcentPpFixe) || '',
      pourcentPuFixe: parseNumber(config.surcommissions?.pourcentPuFixe) || '',
      pourcentPpMin: parseNumber(config.surcommissions?.pourcentPpMin) || '',
      pourcentPuMin: parseNumber(config.surcommissions?.pourcentPuMin) || '',
      plafondAffaire: parseNumber(config.surcommissions?.plafondAffaire) || '',
      plafondAdherent:
        parseNumber(config.surcommissions?.plafondAdherent) || '',
      montantPrimeMin:
        parseNumber(config.surcommissions?.montantPrimeMin) || '',
      pourcentAnnuel: parseNumber(config.surcommissions?.pourcentAnnuel) || '',
      ageSenior: parseNumber(config.surcommissions?.ageSenior) || '',
      pourcentSenior: parseNumber(config.surcommissions?.pourcentSenior) || '',
      pourcentDeces: parseNumber(config.surcommissions?.pourcentDeces),
      plafondAnnuel: parseNumber(config.surcommissions?.plafondAnnuel) || ''
    }

    config.coef_ht = parseNumber(config.coef_ht, 1) || 1
    config.coef_stats = parseNumber(config.coef_stats, 0) || 0
    config.garantieDeces = !!(config?.surcommissions?.pourcentDeces > 0)
    config.montant_prime = config.montant_prime || 'ht'
    config.teletransmission = config.teletransmission ? 1 : 0
    config.sel = config.sel ? 1 : 0
    config.exclureUc = config.exclureUc ? 1 : 0
    config.statistique = config.statistique || ''
    config.plafondCommission = parseNumber(config?.plafondCommission) || ''
    config.coefPlafondCommission =
      parseNumber(config?.coefPlafondCommission) || ''
    config.coefPonderationFondEuro =
      config?.coefPonderationFondEuro ||
      compareStr(0)(config?.coefPonderationFondEuro)
        ? parseNumber(config?.coefPonderationFondEuro)
        : ''
    config.due = config.due ? 1 : 0
    config.dsn = config.dsn ? 1 : 0
    config.duespec = config.duespec ? 1 : 0

    return config
  }

  console.log('out end')

  return {}
}

export default Production_Produit
