export const GET_CABINETS = 'GET_CABINETS'
export const GET_CABINETS_SUCCESS = 'GET_CABINETS_SUCCESS'
export const GET_CABINETS_FAIL = 'GET_CABINETS_FAIL'

export default {
  [GET_CABINETS](state) {
    state.models_pending = true
  },
  [GET_CABINETS_SUCCESS](state, models) {
    state.models = models
    state.models_pending = false
  },
  [GET_CABINETS_FAIL](state) {
    state.models_pending = false
  }
}
