import AbstractModel from '../../abstract/abstract.model'

class Ville extends AbstractModel {
  constructor(data) {
    super(data, ['id', 'cp', 'ville'])
  }

  getCp() {
    return this.getProperty('cp')
  }

  getVille() {
    return this.getProperty('ville')
  }
}

export default Ville
