import groups, { GROUP_ADMIN, roles } from '@/configs/groups'

import EmptyRouterComponent from '@/plugins/Router/modules/empty-router'

import {
  TYPE_COMMERCIALE,
  TYPE_GESTION,
  TYPE_DIRECTION
} from '@/modules/instance/instance.data'
import { router } from '@/plugins'

export default [
  {
    path: '/caisses',
    component: EmptyRouterComponent,
    redirect: { name: 'caisse.list' },
    children: [
      {
        path: ':id([0-9]+)',
        name: 'caisse.list',
        component: () => import('@/modules/caisse/template/list'),
        props: true,
        meta: {
          credentials: [groups.CAISSE_LIST],
          title: 'Liste des recettes/dépenses',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des recettes/dépenses',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Caisse',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/cheques',
    component: EmptyRouterComponent,
    redirect: { name: 'cheques.list' },
    children: [
      {
        path: '',
        name: 'cheque.list',
        component: () => import('@/modules/cheque/template/list'),
        props: true,
        meta: {
          credentials: [groups.CHEQUE_LIST],
          title: 'Liste des chèques',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des chèques',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Chèques',
                path: 'CURRENT'
              }
            ]
          }
        },
        children: [
          {
            path: 'archives',
            name: 'cheque.archives',
            component: () => import('@/modules/cheque/template/list'),
            meta: {
              archive: true,
              credentials: [groups.CHEQUE_LIST],
              title: 'Liste des chèques archivés',
              metaTags: [],
              breadcrumbs: {
                title: 'Liste des chèques archivés',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Chèques',
                    name: 'cheque.list'
                  },
                  {
                    label: 'Archives',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: '/fiches-decouverte',
    name: 'fiche.list',
    component: () => import('@/modules/fiche/template/list'),
    props: true,
    meta: {
      credentials: [groups.FICHE_LIST],
      title: 'Liste des fiches découverte',
      metaTags: [],
      breadcrumbs: {
        title: 'Liste des fiches découverte',
        items: [
          {
            label: 'Accueil',
            path: '/'
          },
          {
            label: 'Fiche découverte',
            path: 'CURRENT'
          }
        ]
      }
    },
    children: [
      {
        path: ':id/:mainIndex?/:subIndex?',
        name: 'fiche.edit',
        component: () => import('@/modules/fiche/template/edit'),
        props: true,
        meta: {
          credentials: [groups.FICHE_EDIT],
          title: () => 'Editer une fiche découverte',
          metaTags: [],
          onRouterBefore(route) {
            if (route?.to?.params?.mainIndex === 'delete') {
              router.push({
                name: 'fiche.list',
                params: {
                  id: route?.to?.params?.id,
                  action: 'delete'
                }
              })
            } else if (route?.to?.params?.mainIndex === 'duplicate') {
              router.push({
                name: 'fiche.list',
                params: {
                  id: route?.to?.params?.id,
                  action: 'duplicate'
                }
              })
            }
          },
          breadcrumbs: {
            title: ({ $this }) => {
              let infos = ''

              const fiche = $this.$route.meta.fiche
              if (fiche) {
                const client = fiche.getClient()
                if (client) {
                  infos += ` : ${client.getProperty(
                    'nom',
                    ''
                  )} ${client.getProperty('prenom', '')}`

                  const societe = client.getProperty('societe')
                  if (societe) {
                    infos += ` / ${societe || ''}`
                  }
                }
              }
              return `Editer une fiche découverte${infos}`
            },
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Fiche découverte',
                path: '/fiches-decouverte'
              },
              {
                label: 'Edition',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'new/:mainIndex([a-z]+)?/:subIndex([a-z]+)?',
        name: 'fiche.new',
        component: () => import('@/modules/fiche/template/edit'),
        props: true,
        meta: {
          credentials: [groups.FICHE_ADD],
          title: 'Ajouter une fiche découverte',
          metaTags: [],
          breadcrumbs: {
            title: 'Ajouter une fiche découverte',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Fiche découverte',
                path: '/fiches-decouverte'
              },
              {
                label: 'Création',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/recommandations',
    component: EmptyRouterComponent,
    redirect: { name: 'recommandation.list' },
    children: [
      {
        path: '',
        name: 'recommandation.list',
        component: () => import('@/modules/recommandation/template/list'),
        props: true,
        meta: {
          archive: false,
          credentials: [groups.RECOMMANDATION_LIST],
          title: 'Liste des recommandations',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des recommandations',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Recommandations',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'archives',
        name: 'recommandation.archive',
        component: () => import('@/modules/recommandation/template/list'),
        props: true,
        meta: {
          archive: true,
          credentials: [groups.RECOMMANDATION_LIST],
          title: 'Liste des recommandations',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des recommandations',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Recommandations',
                path: '/recommandations'
              },
              {
                label: 'Archives',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/resiliations',
    component: EmptyRouterComponent,
    redirect: { name: 'resiliation.list' },
    children: [
      {
        path: '',
        name: 'resiliation.list',
        component: () => import('@/modules/resiliation/template/list'),
        meta: {
          credentials: [groups.RESILIATION_LIST],
          title: 'Liste des résiliations',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des résiliations',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Résiliations',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/instances',
    component: EmptyRouterComponent,
    redirect: { name: 'home' },
    children: [
      {
        path: 'commerciale/:id?',
        name: 'instance.commerciale',
        component: () => import('@/modules/instance/template/list'),
        props: true,
        meta: {
          type: TYPE_COMMERCIALE,
          credentials: [
            groups.INSTANCE_COMMERCIALE_LIST,
            groups.INSTANCE_COMMERCIALE_SUPERVISER
          ],
          title: 'Liste des instances commerciale',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des instances commerciale',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Instances commerciale',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'gestion/:id?',
        name: 'instance.gestion',
        component: () => import('@/modules/instance/template/list'),
        props: true,
        meta: {
          type: TYPE_GESTION,
          credentials: [
            groups.INSTANCE_GESTION_LIST,
            roles.SUPERVISEUR_INSTANCE
          ],
          title: 'Liste des instances gestion',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des instances gestion',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Instances gestion',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'direction/:id?',
        name: 'instance.direction',
        component: () => import('@/modules/instance/template/list'),
        props: true,
        meta: {
          type: TYPE_DIRECTION,
          credentials: [groups.INSTANCE_DIRECTION_LIST],
          title: 'Liste des instances direction',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des instances direction',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Instances direction',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'commerciale/archives',
        name: 'instance.commerciale.archives',
        component: () => import('@/modules/instance/template/list'),
        meta: {
          type: TYPE_COMMERCIALE,
          archives: true,
          props: true,
          credentials: [
            groups.INSTANCE_COMMERCIALE_LIST,
            roles.SUPERVISEUR_INSTANCE
          ],
          title: 'Liste des archives instances commerciale',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des archives instances commerciale',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Instances commerciale',
                name: 'instance.commerciale'
              },
              {
                label: 'Archives instances commerciale',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'gestion/archives',
        name: 'instance.gestion.archives',
        component: () => import('@/modules/instance/template/list'),
        meta: {
          type: TYPE_GESTION,
          archives: true,
          props: true,
          credentials: [
            groups.INSTANCE_GESTION_LIST,
            roles.SUPERVISEUR_INSTANCE
          ],
          title: 'Liste des archives instances gestion',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des archives instances gestion',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Instances gestion',
                name: 'instance.gestion'
              },
              {
                label: 'Archives instances gestion',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'direction/archives',
        name: 'instance.direction.archives',
        component: () => import('@/modules/instance/template/list'),
        meta: {
          type: TYPE_DIRECTION,
          archives: true,
          props: true,
          credentials: [
            groups.INSTANCE_DIRECTION_LIST,
            groups.INSTANCE_DIRECTION_SUPERVISER
          ],
          title: 'Liste des archives instances direction',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des archives instances direction',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Instances direction',
                name: 'instance.direction'
              },
              {
                label: 'Archives instances direction',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/clients',
    redirect: '/page-construction',
    meta: {
      credentials: ['client']
    }
  },
  {
    path: '/parrainages',
    redirect: '/page-construction',
    meta: {
      credentials: ['parrainage']
    }
  },
  {
    path: '/resiliations',
    redirect: '/page-construction',
    meta: {
      credentials: ['resiliation']
    }
  },

  {
    path: '/productions',
    component: EmptyRouterComponent,
    redirect: { name: 'production.contrat.list' },
    children: [
      {
        path: 'contrats',
        name: 'production.contrat.list',
        component: () => import('@/modules/production/contrat/template/list'),
        meta: {
          credentials: [groups.PRODUCTION_LIST],
          title: 'Liste des contrats',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des contrats',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Production'
              },
              {
                label: 'Contrats',
                path: 'CURRENT'
              }
            ]
          }
        },
        children: [
          {
            path: 'new',
            name: 'production.contrat.new',
            component: () =>
              import('@/modules/production/contrat/template/edit'),
            meta: {
              credentials: [groups.PRODUCTION_ADD],
              title: 'Ajouter un contrat',
              metaTags: [],
              breadcrumbs: {
                title: 'Ajouter un contrat',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Contrats',
                    path: '/productions'
                  },
                  {
                    label: 'Création',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: 'duedsn/:id?/:action(edit|envoi|traiter|envoi-dsn|traiter-dsn)?',
            name: 'production.contrat.duedsn.list',
            component: () =>
              import('@/modules/production/contrat/duedsn/template/list'),
            props: true,
            meta: {
              credentials: [groups.DUEDSN_LIST],
              title: 'DUE/DSN',
              metaTags: [],
              breadcrumbs: {
                title: 'DUE/DSN',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Contrats',
                    path: '/productions'
                  },
                  {
                    label: 'DUE/DSN',
                    path: 'CURRENT'
                  }
                ]
              }
            },
            children: [
              {
                path: 'archives',
                name: 'production.contrat.duedsn.archives',
                component: () =>
                  import('@/modules/production/contrat/duedsn/template/list'),
                meta: {
                  archives: true,
                  credentials: [groups.DUEDSN_LIST],
                  title: 'DUE/DSN archives',
                  metaTags: [],
                  breadcrumbs: {
                    title: 'DUE/DSN archives',
                    items: [
                      {
                        label: 'Accueil',
                        path: '/'
                      },
                      {
                        label: 'Production'
                      },
                      {
                        label: 'Contrats',
                        path: '/productions'
                      },
                      {
                        label: 'DUE/DSN',
                        path: {
                          name: 'production.contrat.duedsn.list'
                        }
                      },
                      {
                        label: 'Archives',
                        path: 'CURRENT'
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            path: 'teletransmissions/:id?/:action(relance|archive|commenter)?',
            name: 'production.contrat.teletransmission.list',
            component: () =>
              import(
                '@/modules/production/contrat/teletransmission/template/list'
              ),
            props: true,
            meta: {
              credentials: [groups.TELETRANSMISSION_LIST],
              title: 'Télétransmissions',
              metaTags: [],
              breadcrumbs: {
                title: 'Télétransmissions',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Contrats',
                    path: '/productions'
                  },
                  {
                    label: 'Télétransmissions',
                    path: 'CURRENT'
                  }
                ]
              }
            },
            children: [
              {
                path: 'archives',
                name: 'production.contrat.teletransmission.archives',
                component: () =>
                  import(
                    '@/modules/production/contrat/teletransmission/template/list'
                  ),
                meta: {
                  archives: true,
                  credentials: [groups.TELETRANSMISSION_LIST],
                  title: 'Télétransmissions archives',
                  metaTags: [],
                  breadcrumbs: {
                    title: 'Télétransmissions archives',
                    items: [
                      {
                        label: 'Accueil',
                        path: '/'
                      },
                      {
                        label: 'Production'
                      },
                      {
                        label: 'Contrats',
                        path: '/productions'
                      },
                      {
                        label: 'Télétransmissions',
                        path: {
                          name: 'production.contrat.teletransmission.list'
                        }
                      },
                      {
                        label: 'Archives',
                        path: 'CURRENT'
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            path: ':id',
            name: 'production.contrat.edit',
            component: () =>
              import('@/modules/production/contrat/template/edit'),
            meta: {
              credentials: [
                groups.PRODUCTION_EDIT,
                groups.TELETRANSMISSION_LIST
              ],
              title: 'Editer un contrat',
              metaTags: [],
              breadcrumbs: {
                title: 'Editer un contrat',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Contrats',
                    path: '/productions'
                  },
                  {
                    label: 'Edition',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'avenants',
        name: 'production.avenant.list',
        component: () => import('@/modules/production/avenant/template/list'),
        meta: {
          credentials: [groups.AVENANT_LIST],
          title: 'Liste des avenants',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des avenants',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Production'
              },
              {
                label: 'Avenants',
                path: 'CURRENT'
              }
            ]
          }
        },
        children: [
          {
            path: 'new',
            name: 'production.avenant.new',
            component: () =>
              import('@/modules/production/avenant/template/edit'),
            meta: {
              credentials: [groups.AVENANT_ADD],
              title: 'Ajouter un avenant',
              metaTags: [],
              breadcrumbs: {
                title: 'Ajouter un avenant',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Avenants',
                    path: '/productions'
                  },
                  {
                    label: 'Création',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: ':id',
            name: 'production.avenant.edit',
            component: () =>
              import('@/modules/production/avenant/template/edit'),
            meta: {
              credentials: [groups.AVENANT_EDIT],
              title: 'Editer un avenant',
              metaTags: [],
              breadcrumbs: {
                title: 'Editer un avenant',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Avenants',
                    path: '/productions'
                  },
                  {
                    label: 'Edition',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'stats',
        component: EmptyRouterComponent,
        children: [
          {
            path: ':viewId(\\d+)?',
            name: 'production.commission.list',
            component: () =>
              import('@/modules/production/commission/template/list'),
            props: true,
            meta: {
              credentials: [groups.COMMISSION_LIST],
              title: ({ user }) =>
                user && user.isAdmin()
                  ? 'Liste des commissions'
                  : 'Liste des statistiques',
              metaTags: [],
              breadcrumbs: {
                title: ({ user }) =>
                  user && user.isAdmin()
                    ? 'Liste des commissions'
                    : 'Liste des statistiques',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: ({ user }) =>
                      user && user.isAdmin() ? 'Commissions' : 'Statistiques',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: 'agence',
            component: EmptyRouterComponent,
            redirect: { name: 'production.agence.commission.list' },
            children: [
              {
                path: 'commission/:viewId(\\d+)?',
                name: 'production.agence.commission.list',
                component: () =>
                  import('@/modules/production/commission/template/list'),
                props: true,
                meta: {
                  agence: true,
                  credentials: [GROUP_ADMIN],
                  title: 'Liste des commissions agence',
                  metaTags: [],
                  breadcrumbs: {
                    title: 'Liste des commissions agence',
                    items: [
                      {
                        label: 'Accueil',
                        path: '/'
                      },
                      {
                        label: 'Production'
                      },
                      {
                        label: 'Commissions',
                        path: 'CURRENT'
                      }
                    ]
                  }
                }
              },
              {
                path: 'surcommission',
                name: 'production.agence.surcommission.list',
                component: () =>
                  import('@/components/pages/production/surcommission/list'),
                meta: {
                  agence: true,
                  credentials: [GROUP_ADMIN],
                  title: 'Liste des surcommissions',
                  metaTags: [],
                  breadcrumbs: {
                    title: 'Liste des surcommissions',
                    items: [
                      {
                        label: 'Accueil',
                        path: '/'
                      },
                      {
                        label: 'Production'
                      },
                      {
                        label: 'Surcommissions',
                        path: 'CURRENT'
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/admin',
    component: EmptyRouterComponent,
    redirect: { name: 'admin.users.list' },
    children: [
      {
        path: 'users',
        component: EmptyRouterComponent,
        children: [
          {
            path: '',
            name: 'admin.users.list',
            component: () => import('@/components/pages/admin/users/list'),
            meta: {
              credentials: [GROUP_ADMIN],
              title: 'Liste des utilisateurs',
              metaTags: [],
              breadcrumbs: {
                title: 'Liste des utilisateurs',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Utilisateurs',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: ':id',
            name: 'admin.users.edit',
            component: () => import('@/components/pages/admin/users/edit'),
            meta: {
              credentials: [GROUP_ADMIN],
              title: "Edition d'un utilisateur",
              metaTags: [],
              breadcrumbs: {
                title: "Edition d'un utilisateur",
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Utilisateurs',
                    path: '/admin/users'
                  },
                  {
                    label: 'Edition',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'groups',
        component: EmptyRouterComponent,
        children: [
          {
            path: '',
            name: 'admin.groups.list',
            component: () => import('@/components/pages/admin/groups/list'),
            meta: {
              credentials: [GROUP_ADMIN],
              title: 'Liste des groupes',
              metaTags: [],
              breadcrumbs: {
                title: 'Liste des groupes',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Groupes',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: ':id',
            name: 'admin.groups.edit',
            component: () => import('@/components/pages/admin/groups/edit'),
            meta: {
              credentials: [GROUP_ADMIN],
              title: "Edition d'un groupe",
              metaTags: [],
              breadcrumbs: {
                title: "Edition d'un groupe",
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Groupes',
                    path: '/admin/groups'
                  },
                  {
                    label: 'Edition',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'donnees/:type?',
        name: 'admin.donnees.list',
        component: () => import('@/components/pages/admin/donnees'),
        meta: {
          credentials: [GROUP_ADMIN],
          title: 'Liste des données',
          metaTags: [],
          breadcrumbs: {
            title: 'Liste des données',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Données',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'production',
        component: EmptyRouterComponent,
        redirect: { name: 'admin.production.produits.list' },
        children: [
          {
            path: 'produits',
            component: EmptyRouterComponent,
            children: [
              {
                path: '',
                name: 'admin.production.produits.list',
                component: () =>
                  import('@/modules/production/produit/template/admin/list'),
                meta: {
                  credentials: [GROUP_ADMIN],
                  title: 'Liste des produits',
                  metaTags: [],
                  breadcrumbs: {
                    title: 'Liste des produits',
                    items: [
                      {
                        label: 'Accueil',
                        path: '/'
                      },
                      {
                        label: 'Production'
                      },
                      {
                        label: 'Produits',
                        path: 'CURRENT'
                      }
                    ]
                  }
                }
              },
              {
                path: ':id',
                name: 'admin.production.produits.edit',
                component: () =>
                  import('@/modules/production/produit/template/admin/list'),
                meta: {
                  credentials: [GROUP_ADMIN],
                  title: "Edition d'un produit",
                  metaTags: [],
                  breadcrumbs: {
                    title: "Edition d'un produit",
                    items: [
                      {
                        label: 'Accueil',
                        path: '/'
                      },
                      {
                        label: 'Production'
                      },
                      {
                        label: 'Produits',
                        path: '/admin/production/produits'
                      },
                      {
                        label: 'Edition',
                        path: 'CURRENT'
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            path: 'configurations/:id?',
            name: 'admin.production.configurations.list',
            component: () =>
              import('@/modules/production/configuration/template/admin/index'),
            meta: {
              credentials: [GROUP_ADMIN],
              title: 'Configurations Produit/Collaborateur',
              metaTags: [],
              breadcrumbs: {
                title: 'Configurations Produit/Collaborateur',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Configurations Produit/Collaborateur',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: 'configCollaborateur',
            name: 'admin.production.configCollaborateur',
            component: () =>
              import(
                '@/modules/production/configCollaborateur/template/admin/index'
              ),
            meta: {
              credentials: [GROUP_ADMIN],
              title: 'Configurations collaborateur',
              metaTags: [],
              breadcrumbs: {
                title: 'Configurations collaborateur',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Configurations collaborateur',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: 'surcommissions/:annee?',
            name: 'admin.production.surcommissions',
            component: () =>
              import('@/modules/production/surcommission/template/admin'),
            meta: {
              credentials: [GROUP_ADMIN],
              props: true,
              title: 'Configurations Surcommissions',
              metaTags: [],
              breadcrumbs: {
                title: 'Configurations Surcommissions',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Configurations Surcommissions',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: 'teletransmissions',
            name: 'admin.production.teletransmissions.list',
            redirect: '/page-construction',
            meta: {
              credentials: [GROUP_ADMIN]
            }
          },
          {
            path: 'prime/:annee?',
            name: 'admin.production.prime',
            component: () =>
              import('@/modules/production/prime/template/admin'),
            meta: {
              credentials: [GROUP_ADMIN],
              props: true,
              title: 'Configurations Prime',
              metaTags: [],
              breadcrumbs: {
                title: 'Configurations Prime',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Configurations Prime',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          },
          {
            path: 'contrat/:annee?',
            name: 'admin.production.contrat',
            component: () =>
              import('@/modules/production/contrat/config/template/admin'),
            meta: {
              credentials: [GROUP_ADMIN],
              props: true,
              title: 'Configurations Contrat',
              metaTags: [],
              breadcrumbs: {
                title: 'Configurations Contrat',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Production'
                  },
                  {
                    label: 'Configurations Contrat',
                    path: 'CURRENT'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'cabinets',
        component: () => import('@/modules/cabinet/template/admin'),
        redirect: { name: 'admin.cabinet.list' },
        children: [
          {
            path: ':id?',
            name: 'admin.cabinet.list',
            components: {
              default: () => import('@/modules/cabinet/template/admin/list'),
              sidebar: () =>
                import('@/modules/cabinet/template/admin/edit/index')
            },
            meta: {
              credentials: [groups.CABINET_LIST, groups.CABINET_SUPERVISER],
              title: 'Liste des cabinets comptables',
              metaTags: [],
              checkDirtyOnChangeName: false,
              breadcrumbs: {
                title: 'Liste des cabinets comptables',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Cabinets comptables',
                    name: 'admin.cabinet.list'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'agence',
        component: EmptyRouterComponent,
        redirect: { name: 'admin.agence.list' },
        children: [
          {
            path: ':id?',
            name: 'admin.agence.list',
            component: () => import('@/modules/agence/template/admin/list'),
            props: true,
            meta: {
              credentials: [GROUP_ADMIN],
              title: 'Liste des agences',
              metaTags: [],
              breadcrumbs: {
                title: 'Liste des agences',
                items: [
                  {
                    label: 'Accueil',
                    path: '/'
                  },
                  {
                    label: 'Agences',
                    name: 'admin.agence.list'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'application/configs/:annee?',
        name: 'admin.application.configs',
        component: () => import('@/modules/application/template/admin'),
        meta: {
          credentials: [GROUP_ADMIN],
          props: true,
          title: 'Application Configurations',
          metaTags: [],
          breadcrumbs: {
            title: 'Application Configurations',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Application'
              },
              {
                label: 'Configurations',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/statistiques',
    component: EmptyRouterComponent,
    redirect: { name: 'home' },
    credentials: [groups.STATISTIQUES],
    children: [
      {
        path: 'collaborateurs',
        name: 'stats.collaborateurs',
        component: () => import('@/modules/user/template/stats/collaborateurs'),
        meta: {
          credentials: [
            groups.STATISTIQUES_COLLABORATEURS,
            groups.STATISTIQUES_COLLABORATEURS_SUPERVISER
          ],
          title: 'Statistiques des collaborateurs',
          metaTags: [],
          breadcrumbs: {
            title: 'Statistiques des collaborateurs',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Statistique des collaborateurs',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'suivihebdo',
        name: 'stats.suivihebdo',
        component: () => import('@/modules/suivihebdo/template/stats/stats'),
        meta: {
          credentials: [
            groups.STATISTIQUES_SUIVIHEBDO,
            groups.STATISTIQUES_SUIVIHEBDO_SUPERVISER
          ],
          title: 'Suivi hebdomadaire',
          metaTags: [],
          breadcrumbs: {
            title: 'Suivi hebdomadaire',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Suivi hebdomadaire',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'bien_etre',
        name: 'stats.bien_etre',
        component: () => import('@/modules/bienetre/template/stats/stats'),
        meta: {
          credentials: [groups.STATISTIQUES_BIEN_ETRE],
          title: 'Bien-être des collaborateurs',
          metaTags: [],
          breadcrumbs: {
            title: 'Bien-être des collaborateurs',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Bien-être des collaborateurs',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'satisfaction',
        name: 'stats.satisfaction',
        component: () => import('@/modules/satisfaction/template/stats/stats'),
        meta: {
          credentials: [groups.STATISTIQUES_SATISFACTION],
          title: 'Satisfaction des clients',
          metaTags: [],
          breadcrumbs: {
            title: 'Satisfaction des clients',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Satisfaction des clients',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'resiliations',
        name: 'stats.resiliation',
        component: () => import('@/modules/resiliation/template/stats/stats'),
        meta: {
          credentials: [groups.STATISTIQUES_RESILIATION],
          title: 'Statistiques des résiliations',
          metaTags: [],
          breadcrumbs: {
            title: 'Statistiques des résiliations',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Statistique des résiliations',
                path: 'CURRENT'
              }
            ]
          }
        }
      },
      {
        path: 'diagrammes',
        name: 'stats.diagrammes',
        component: () =>
          import('@/components/pages/index/cards/production/stats'),
        meta: {
          credentials: [
            groups.STATISTIQUES_DIAGRAMMES,
            groups.STATISTIQUES_DIAGRAMMES_SUPERVISER
          ],
          title: 'Diagrammes',
          metaTags: [],
          breadcrumbs: {
            title: 'Diagrammes',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Diagrammes',
                path: 'CURRENT'
              }
            ]
          }
        }
      }
    ]
  }
]
