import Cheque from '../cheque.model'

export default {
  cheques: state => state.cheques,
  chequeById: state => id => {
    const cheque = state.cheques
      ? state.cheques.find(cheque => cheque.id === id)
      : null
    return cheque ? new Cheque(cheque) : null
  },
  cheques_pending: state => state.cheques_pending
}
