export default {
  delete: ({ DELETE }, idConfigCollaborateur) =>
    new Promise((resolve, reject) => {
      if (!idConfigCollaborateur) {
        reject(
          new Error(
            `Erreur paramètre : idConfigCollaborateur non valide "${idConfigCollaborateur}"`
          )
        )
      }
      return DELETE(`production/configCollaborateurs/${idConfigCollaborateur}`)
        .then((reponse) => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
