<template>
  <header id="header" class="page-topbar">
    <div class="navbar navbar-fixed">
      <nav
        class="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light"
        :class="{ 'nav-collapsed': !navbarLocked }"
      >
        <div class="nav-wrapper">
          <div class="flex-auto">
            <h6 class="pl-2 pr-2">
              {{ date.format('dddd Do MMMM YYYY HH:mm') }}
            </h6>
          </div>
          <ul class="navbar-list">
            <li
              :is="c.component"
              v-for="(c, index) in components"
              :key="index"
              :item="c"
            />
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import moment from 'moment'
import headerConfigs from '@/configs/elements/header'
import { mapGetters } from 'vuex'
import { setIntervalIfVisible } from '@/plugins/utils'

export default {
  name: 'Header',
  data: () => ({
    components: headerConfigs.components,
    date: moment()
  }),
  computed: {
    ...mapGetters('ui', { navbarLocked: 'sidebarLock' })
  },
  mounted() {
    // Search class for focus
    $(this.$el)
      .find('.header-search-input')
      .focus(function () {
        $(this).parent('div').addClass('header-search-wrapper-focus')
      })
      .blur(function () {
        $(this).parent('div').removeClass('header-search-wrapper-focus')
      })

    setIntervalIfVisible(() => {
      this.date = moment()
    }, 1000)

    // Search box form small screen
    $(this.$el)
      .find('.search-button')
      .click(function () {
        if ($('.search-sm').is(':hidden')) {
          $('.search-sm').show()
          $('.search-box-sm').focus()
        } else {
          $('.search-sm').hide()
          $('.search-box-sm').val('')
        }
      })
    $('.search-sm-close').click(function () {
      $('.search-sm').hide()
      $('.search-box-sm').val('')
    })
  }
}
</script>
