import ConfigCollaborateur from '../configCollaborateur.model'

export default {
  edit: ({ PUT }, configCollaborateur) =>
    new Promise((resolve, reject) => {
      if (!(configCollaborateur instanceof ConfigCollaborateur)) {
        return reject(
          new Error(
            `Erreur paramètre : ConfigCollaborateur attendu mais reçu "${configCollaborateur.constructor.name}"`
          )
        )
      } else if (configCollaborateur.isNew()) {
        return reject(
          new Error(`Erreur paramètre : ConfigCollaborateur is new`)
        )
      }

      return PUT(
        `production/configCollaborateurs/${configCollaborateur.id}`,
        configCollaborateur
      )
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const configCollaborateur = new ConfigCollaborateur(data)
            resolve(configCollaborateur)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
