export default [
  {
    key: 'nbRDV',
    nom: `Nombre de RDV Positifs sur le trimestre`,
    stat: 'nbRDV'
  },
  {
    key: 'tauxInstanceGestion',
    nom: `Taux d'instance de gestion`,
    stat: 'tauxInstanceGestion'
  },
  {
    key: 'tauxCompletudeFDC',
    nom: `Taux de complétude FDC`,
    stat: 'tauxCompletudeFDC'
  },
  {
    key: 'sel',
    nom: `SEL`,
    stat: 'sel'
  },
  {
    key: 'campagneTauxRDV',
    nom: `Campagne : Taux de rdv`,
    stat: 'campagneTauxRDV',
    manuel: true
  },
  {
    key: 'nbProspect',
    nom: `Nombre de prospect signé`,
    stat: 'nbProspect'
  },
  {
    key: 'tauxRemiseCP',
    nom: `Taux de remise CP`,
    stat: 'tauxRemiseCP'
  }
]
