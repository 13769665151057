import { GROUP_USER, GROUP_ADMIN } from '@/configs/groups'

import EmptyRouterComponent from '@/plugins/Router/modules/empty-router'

export default [
  {
    path: '/',
    component: () => import('@/components/pages/index/index.vue'),
    name: 'home',
    meta: {
      credentials: [GROUP_USER],
      title: 'Tableau de bord',
      metaTags: [
        {
          name: 'description',
          content: 'Tableau de bord'
        }
      ]
    }
  },
  {
    path: '/login',
    component: () => import('@/components/pages/login/login.vue'),
    name: 'login',
    meta: {
      onRouterBefore: ({ store }) => {
        store.dispatch('auth/logout')
      },
      dashboard: false,
      title: 'Connexion',
      metaTags: [
        {
          name: 'description',
          content: 'Page de connexion'
        }
      ]
    }
  },
  {
    path: '/consentement/:id([\\w-]+)',
    name: 'consentement',
    component: () => import('@/components/pages/public/consentement-rgpd.vue'),
    meta: {
      dashboard: false,
      title: 'consentement au RGPD'
    }
  },
  {
    path: '/questionnaire/:idQuestionnaire([\\w-]+)',
    name: 'questionnaire',
    component: () => import('@/components/pages/satisfaction/Satisfaction.vue'),
    meta: {
      dashboard: false,
      title: 'Questionnaire de satisfaction'
    }
  },
  {
    path: '/user',
    component: EmptyRouterComponent,
    redirect: { name: 'user.profile' },
    children: [
      {
        path: 'profile',
        component: () => import('@/components/pages/user/profile.vue'),
        name: 'user.profile',
        meta: {
          credentials: [GROUP_USER],
          title: 'Mon profil',
          metaTags: [
            {
              name: 'description',
              content: 'Mon profil'
            }
          ],
          breadcrumbs: {
            title: 'Mon profil',
            items: [
              {
                label: 'Accueil',
                path: '/'
              },
              {
                label: 'Mon profil',
                path: '/user/profile'
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/components/pages/divers/404.vue'),
    meta: {
      dashboard: false,
      title: '404 - Ressource introuvable',
      metaTags: []
    }
  },
  {
    path: '/503',
    component: () => import('@/components/pages/divers/503.vue'),
    meta: {
      dashboard: false,
      title: '503 - Ressource indisponible'
    }
  },
  {
    path: '/maintenance',
    component: () => import('@/components/pages/divers/maintenance.vue'),
    meta: {
      dashboard: false,
      title: 'Page en maintenance',
      metaTags: []
    }
  },
  {
    path: '/construction',
    component: () => import('@/components/pages/divers/construction.vue'),
    meta: {
      dashboard: false,
      title: 'Page en construction',
      metaTags: []
    }
  },
  {
    path: '/page-construction',
    component: () => import('@/components/pages/divers/construction.vue'),
    meta: {
      credentials: [GROUP_USER],
      title: 'Page en construction',
      metaTags: []
    }
  },
  {
    path: '/test',
    component: () => import('@/components/pages/index/index.vue'),
    meta: {
      credentials: [GROUP_ADMIN],
      title: 'Test',
      metaTags: [
        {
          name: 'description',
          content: 'Ma page de test'
        }
      ],
      breadcrumbs: {
        title: 'Ma page de test'
      }
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]
