import AbstractModel from '@/modules/abstract/abstract.model'
import { api } from '@/plugins'

class PrimeConfigCollab extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'annee',
      'trimestre',
      'collaborateurId',
      'nbRDV',
      'campagneTauxRDV',
      'heuresDDA',
      'recommandations',
      'projets'
    ])
  }

  save() {
    if (this.isNew()) {
      return api
        .POST('production/primes/addConfigCollab', this)
        .then((config) => {
          M.toast({
            html: `La configuration a été enregistrée.`,
            classes: 'green'
          })

          this.resetModelRef()
          this.setProperty('id', config.getId())
          return config
        })
    } else {
      return api
        .PUT('production/primes/editConfigCollab', this)
        .then((config) => {
          M.toast({
            html: `Le configuration a été modifiée.`,
            classes: 'green'
          })

          this.resetModelRef()
          return config
        })
    }
  }
}

export default PrimeConfigCollab
