import AbstractModel from '../../../abstract/abstract.model'
import { api } from '@/plugins'

class SurcommissionConfig extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'annee',
      {
        field: 'configs',
        default: {}
      }
    ])

    this.initSurco()
  }

  initSurco() {
    this.initSurcoVie()
    this.initSurcoSante()
  }

  initSurcoVie() {
    const configs = this.data.configs
    if (!configs.SURCO_VIE) {
      configs.SURCO_VIE = {}
    }

    if (!configs.SURCO_VIE.trimestres) {
      configs.SURCO_VIE.trimestres = Array(4)
        .fill()
        .map((_v, index) => ({
          trimestre: index + 1,
          tranches: [],
          tauxGlobal: ''
        }))
    }

    configs.SURCO_VIE.trimestres.forEach((trimestre) => {
      const maxTranches = 8
      if (trimestre.tranches.length < maxTranches) {
        for (let i = trimestre.tranches.length; i <= maxTranches; i++) {
          trimestre.tranches.push({ max: '', taux: '' })
        }
      }
    })
  }

  initSurcoSante() {
    const configs = this.data.configs
    if (!configs.SURCO_SANTE) {
      configs.SURCO_SANTE = {}
    }

    if (!configs.SURCO_SANTE.annuel) {
      configs.SURCO_SANTE.annuel = {
        tranches: [],
        tauxGlobal: ''
      }
    }

    const maxTranches = 6
    const annuel = configs.SURCO_SANTE.annuel
    if (annuel.tranches.length < maxTranches) {
      for (let i = annuel.tranches.length; i <= maxTranches; i++) {
        annuel.tranches.push({ max: '', taux: '' })
      }
    }
  }
  getAnnee() {
    return this.getProperty('annee')
  }

  getConfigVieData() {
    return this.data.configs.SURCO_VIE
  }

  getConfigSanteData() {
    return this.data.configs.SURCO_SANTE
  }

  save() {
    if (this.isNew()) {
      return api
        .POST('production/surcommissions/addConfig', this)
        .then((config) => {
          M.toast({
            html: `La configuration a été enregistrée.`,
            classes: 'green'
          })

          this.resetModelRef()
          return config
        })
    } else {
      return api
        .PUT('production/surcommissions/editConfig', this)
        .then((config) => {
          M.toast({
            html: `Le configuration a été modifiée.`,
            classes: 'green'
          })

          this.resetModelRef()
          return config
        })
    }
  }
}

export default SurcommissionConfig
