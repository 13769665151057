import AbstractModel from '../abstract/abstract.model'
import Fichier from './fichier.model'
import { isObject } from '@/plugins/utils'

class FichierManager extends AbstractModel {
  constructor(data) {
    super(data)

    let fichiers = []

    const dataFiles = data || []
    if (Array.isArray(dataFiles)) {
      dataFiles.forEach((file) => {
        if (!isObject(file)) {
          file = { id: file }
        }
        const fichier = new Fichier(file)
        fichier.setProperty('preload', true)
        fichiers.push(fichier)
      })
    }
    this.setProperty('fichiers', fichiers)
  }

  setFichiers = function(fichiers) {
    return this.setProperty('fichiers', fichiers)
  }

  getFichiers = function() {
    return this.getProperty('fichiers')
  }

  getNbFichiers = function() {
    const fichiers = this.getProperty('fichiers', [])
    return fichiers ? fichiers.length : 0
  }

  getExport = function() {
    return this.getFichiers().map((f) => f.getExport())
  }

  getFileuploaderFileInit = function() {
    let fichiers = this.getFichiers().map((fichier) => {
      let fileDatas = {
        id: fichier.getId(),
        name: fichier.getName(),
        size: fichier.getSize(),
        type: fichier.getType(),
        file: fichier.getUrlDownload(),
        data: fichier.getProperty('data') || {}
      }

      if (fichier.isDeleted()) {
        fileDatas.name = fileDatas.name + ' (supprimé)'
        fileDatas.data.popup = false
        fileDatas.data.url = undefined
      } else {
        fileDatas.data.url = fichier.getUrl()
      }

      if (fichier.isPreload()) {
        fileDatas.file = fichier.getUrl()
      }

      fileDatas.data.fichier = fichier

      return fileDatas
    })

    return fichiers
  }
}

export default FichierManager
