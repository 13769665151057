import Group from '../group.model'

export default {
  get: ({ GET }, idGroup) =>
    new Promise((resolve, reject) => {
      return GET(`groups/${idGroup}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const group = new Group(data)
            resolve(group)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`groups/list`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let groups = []
            data.forEach((groupData) => {
              groups.push(new Group(groupData))
            })
            resolve(groups)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
