import Model from '../instance.model'
import { getApiType } from '../instance.data'

export default {
  add: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(`instances/${getApiType(model.getType())}`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),

  assign: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      if (datas?.instanceIds?.length < 1 || !datas?.collaborateurId) {
        return reject(
          new Error(
            `Erreur paramètre : instanceIds et collaborateurId sont obligatoires.`
          )
        )
      }
      return POST(`instances/${getApiType(datas.type)}/assign`, {
        instanceIds: datas.instanceIds,
        collaborateurId: datas.collaborateurId
      })
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve()
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
