import { compareStr } from '@/plugins/utils'
import Production_Produit from '../produit.model'

export default {
  produits: (state) => state.produits,
  produitById: (state) => (id) => {
    const datas = state.produits.find((produit) => compareStr(produit.id)(id))
    return new Production_Produit(datas)
  },
  produitsConfigured: (state) =>
    state.produits.filter((produit) => !!produit.configured),
  produits_pending: (state) => state.produits_pending,
  produits_refresh_pending: (state) => state.produits_refresh_pending,
  groupes: (state) => state.groupes,
  groupes_pending: (state) => state.groupes_pending,
  familles: (state) => state.familles,
  familles_pending: (state) => state.familles_pending,
  statistiques: (state) => state.statistiques,
  statistiques_pending: (state) => state.statistiques_pending
}
