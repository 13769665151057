import { GROUP_USER, GROUP_ADMIN } from '@/configs/groups'
import { forEachValue, isObject, setDeepValue } from '@/plugins/utils'

class User {
  constructor(data = {}) {
    const {
      id,
      nom,
      prenom,
      login,
      email,
      _agence,
      agence,
      created,
      lastActive,
      rappel,
      groups,
      commissionAgence,
      surcommissionnement,
      eligiblePrime,
      dashboard,
      roles,
      couleur,
      menu,
      otp
    } = data

    this.id = id
    this.nom = nom || ''
    this.prenom = prenom || ''
    this.login = login || ''
    this.email = email || ''
    this.created = created || ''
    this.lastActive = lastActive || ''
    this.rappel = rappel || ''
    this.groups = groups || []
    this._agence = (isObject(agence) ? agence : _agence) || { id: agence }
    this.agence = this._agence.id
    this.commissionAgence = commissionAgence ? '1' : '0'
    this.surcommissionnement = surcommissionnement ? '1' : '0'
    this.eligiblePrime = eligiblePrime ? '1' : '0'
    this.roles = roles || []
    this.couleur = couleur || this.randomColor()
    this.menu = menu || []
    this.otp = otp

    setDeepValue(this, 'dashboard.widgets', dashboard?.widgets ?? {})
    setDeepValue(
      this,
      'dashboard.notifications',
      dashboard?.notifications ?? {}
    )
  }

  isAdmin() {
    return isAdmin(this)
  }

  isActived() {
    return isActived(this)
  }

  isNew() {
    return !this.id
  }

  isAllowed(grp, ignoreAdmin) {
    return isAllowed(this, grp, ignoreAdmin)
  }

  randomColor() {
    return `#${((Math.random() * 0xffffff) << 0).toString(16)}`
  }
}

export default User

export function isAdmin(user) {
  return user.groups.includes(GROUP_ADMIN)
}

export function isActived(user) {
  return user.isAdmin() || user.groups.includes(GROUP_USER)
}

export function isAllowed(user, grp, ignoreAdmin) {
  if (!user) {
    return false
  } else if (!user.isActived()) {
    return false
  }

  if (!grp) {
    return true
  } else if (user.isAdmin() && !ignoreAdmin) {
    return true
  }

  if (!Array.isArray(grp)) {
    grp = [String(grp)]
  }

  if (grp.length === 0) {
    return true
  }

  // Test des groupes de l'utilisateur
  if (user.groups.filter((g) => grp.includes(g)).length > 0) {
    return true
  }

  // Test des roles de l'utilisateur
  const userRoles = user.roles.reduce((rs, agenceRoles) => {
    const roles = agenceRoles.roles ?? {}
    forEachValue(roles, (valid, role) => {
      if (valid && !rs.includes(role)) {
        rs.push(role)
      }
    })
    return rs
  }, [])

  return userRoles.filter((r) => grp.includes(r)).length > 0
}
