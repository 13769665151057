export const GET_LIGNES = 'GET_LIGNES'
export const GET_LIGNES_SUCCESS = 'GET_LIGNES_SUCCESS'
export const GET_LIGNES_FAIL = 'GET_LIGNES_FAIL'

export default {
  [GET_LIGNES] (state) {
    state.lignes_pending = true
  },
  [GET_LIGNES_SUCCESS] (state, lignes) {
    state.lignes = lignes
    state.lignes_pending = false
  },
  [GET_LIGNES_FAIL] (state) {
    state.lignes_pending = false
  }
}
