import { api } from '@/plugins'
import { GET_GROUPS, GET_GROUPS_SUCCESS, GET_GROUPS_FAIL } from './mutations'

export default {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_GROUPS)
      api
        .GET('group/list')
        .then(async (groups) => {
          await commit(GET_GROUPS_SUCCESS, groups)
          resolve(groups)
        })
        .catch((e) => {
          commit(GET_GROUPS_FAIL)
          reject(e)
        })
    })
  }
}
