import { forEachValue } from '../../utils'

export default class Module {
  constructor (rawModule, runtime) {
    this.runtime = runtime
    this._children = {}
    this._rawModule = rawModule
    const rawState = rawModule.state
    this.state = (typeof rawState === 'function' ? rawState() : rawState) || {}
    this.onCreate = rawModule.onCreate
  }

  init (...options) {
    if (typeof this.onCreate === 'function') {
      this.onCreate(...options)
    }
  }

  getOptions () {
    return this._rawModule
  }

  addChild (key, module) {
    this._children[key] = module
  }

  removeChild (key) {
    delete this._children[key]
  }

  getChild (key) {
    return this._children[key]
  }

  forEachChild (fn) {
    forEachValue(this._children, fn)
  }
}
