import collapsible from './_collapsible'
import common from './_common'
import dropdown from './_dropdown'
import fullscreen from './_full-screen'
import modal from './_modal'
import perfectscrollbar from './_perfect-scroll-bar'
import sidenav from './_sidenav'
import tabs from './_tabs'
import tooltip from './_tooltip'
import uiAlert from './_ui-alert'

export default {
  install (Vue) {
    Vue.use(collapsible)
    Vue.use(dropdown)
    Vue.use(fullscreen)
    Vue.use(modal)
    Vue.use(perfectscrollbar)
    Vue.use(sidenav)
    Vue.use(tabs)
    Vue.use(tooltip)
    Vue.use(uiAlert)
    Vue.use(common)
  }
}
