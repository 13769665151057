export const REFRESH_NOTIFICATIONS = 'REFRESH_NOTIFICATIONS'
export const REFRESH_NOTIFICATIONS_SUCCESS = 'REFRESH_NOTIFICATIONS_SUCCESS'
export const REFRESH_NOTIFICATIONS_FAIL = 'REFRESH_NOTIFICATIONS_FAIL'

export default {
  [REFRESH_NOTIFICATIONS](state) {
    state.models_pending = true
  },
  [REFRESH_NOTIFICATIONS_SUCCESS](state, models) {
    state.models = models

    // const ids = models.map((model) => model.id)
    // state.models = state.models.filter((model) => !ids.includes(model.id))

    // state.models.push(
    //   ...models.filter((model) => model.state !== NOTIFICATION_STATE.DELETED)
    // )

    // state.lastModified = models.reduce((max, model) => {
    //   return model.modified > max ? model.modified : max
    // }, 0)

    state.models_pending = false
  },
  [REFRESH_NOTIFICATIONS_FAIL](state) {
    state.models_pending = false
  }
}
