import Produit from '../produit.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`production/produits/${id}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Produit(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }, data) =>
    new Promise((resolve, reject) => {
      return GET(`production/produits`, { ...data })
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach((modelData) => {
              models.push(new Produit(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  available: ({ GET }, { userId, annee }) =>
    new Promise((resolve, reject) => {
      const uri = `production/produits/available/${userId ?? ''}/${annee ?? ''}`
      return GET(uri)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach((modelData) => {
              models.push(new Produit(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  groupes: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`production/produits/groupes`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  familles: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`production/produits/familles`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  statistiques: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`production/produits/statistiques`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
