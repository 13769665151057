import { sortDeep } from '@/plugins/utils'

export default {
  donnees: (state) => state.donnees,
  donneeById: (state) => (id) =>
    state.donnees
      ? state.donnees.find((donnee) => donnee.getId() === parseInt(id))
      : null,
  donneesByType: (state) => (type, order = true) => {
    let donnees = state.donnees
      ? state.donnees.filter((donnee) => donnee.getProperty('type') === type)
      : null

    return order ? sortDeep(donnees, 'data.valeur') : donnees
  },
  donnees_pending: (state) => state.donnees_pending
}
