export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'
export const GET_COLLABORATEURS = 'GET_COLLABORATEURS'
export const GET_COLLABORATEURS_SUCCESS = 'GET_COLLABORATEURS_SUCCESS'
export const GET_COLLABORATEURS_FAIL = 'GET_COLLABORATEURS_FAIL'

export default {
  [GET_USER] (state) {
    state.pending = true
  },
  [GET_USER_SUCCESS] (state, user) {
    state.user = user
    state.pending = false
  },
  [GET_USER_FAIL] (state) {
    state.pending = false
  },
  [GET_COLLABORATEURS] (state) {
    state.collabs_pending = true
  },
  [GET_COLLABORATEURS_SUCCESS] (state, users) {
    state.collaborateurs = users
    state.collabs_pending = false
  },
  [GET_COLLABORATEURS_FAIL] (state) {
    state.collabs_pending = false
  }
}
