import AbstractModel from '../../abstract/abstract.model'

export const STATE_WAITING = 'STATE_WAITING'
export const STATE_PDF_CREATED = 'STATE_PDF_CREATED'
export const STATE_PDF_SEND = 'STATE_PDF_SEND'
export const STATE_VISITE_ADDED = 'STATE_VISITE_ADDED'

class FicheDevis extends AbstractModel {
  constructor (data) {
    super(data, [
      'id',
      'idDevis',
      ['state', STATE_WAITING],
      'created',
      'datePdfCreated',
      'datePdfSend',
      'dateVisiteAdded'
    ])
  }

  getState () {
    return this.getProperty('state')
  }
}

export default FicheDevis
