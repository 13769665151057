import { api } from '@/plugins'
import {
  GET_PRODUITS,
  GET_PRODUITS_SUCCESS,
  GET_PRODUITS_FAIL,
  REFRESH_PRODUITS,
  REFRESH_PRODUITS_SUCCESS,
  REFRESH_PRODUITS_FAIL,
  GET_GROUPES,
  GET_GROUPES_SUCCESS,
  GET_GROUPES_FAIL,
  GET_FAMILLES,
  GET_FAMILLES_SUCCESS,
  GET_FAMILLES_FAIL,
  GET_STATISTIQUES,
  GET_STATISTIQUES_SUCCESS,
  GET_STATISTIQUES_FAIL
} from './mutations'

export default {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_PRODUITS)

      api
        .GET('production/produits/list')
        .then(async (produits) => {
          await commit(GET_PRODUITS_SUCCESS, produits)
          resolve(produits)
        })
        .catch((e) => {
          commit(GET_PRODUITS_FAIL)
          reject(e)
        })
    })
  },
  refresh({ commit, state }) {
    const lastModified = state.lastModified
    return new Promise((resolve, reject) => {
      commit(REFRESH_PRODUITS)

      const query = {}
      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('production/produits/list', query)
        .then(async (datas) => {
          await commit(REFRESH_PRODUITS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_PRODUITS_FAIL)
          reject(e)
        })
    })
  },
  available({ commit }, { userId, annee } = {}) {
    return new Promise((resolve, reject) => {
      commit(GET_PRODUITS)

      api
        .GET('production/produits/available', { userId, annee })
        .then(async (produits) => {
          await commit(GET_PRODUITS_SUCCESS, produits)
          resolve(produits)
        })
        .catch((e) => {
          commit(GET_PRODUITS_FAIL)
          reject(e)
        })
    })
  },
  groupes({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_GROUPES)

      api
        .GET('production/produits/groupes')
        .then(async (groupes) => {
          await commit(GET_GROUPES_SUCCESS, groupes)
          resolve(groupes)
        })
        .catch((e) => {
          commit(GET_GROUPES_FAIL)
          reject(e)
        })
    })
  },
  familles({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_FAMILLES)

      api
        .GET('production/produits/familles')
        .then(async (familles) => {
          await commit(GET_FAMILLES_SUCCESS, familles)
          resolve(familles)
        })
        .catch((e) => {
          commit(GET_FAMILLES_FAIL)
          reject(e)
        })
    })
  },
  statistiques({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_STATISTIQUES)

      api
        .GET('production/produits/statistiques')
        .then(async (statistiques) => {
          await commit(GET_STATISTIQUES_SUCCESS, statistiques)
          resolve(statistiques)
        })
        .catch((e) => {
          commit(GET_STATISTIQUES_FAIL)
          reject(e)
        })
    })
  }
}
