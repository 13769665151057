import AbstractModel from '../../abstract/abstract.model'
import { api } from '@/plugins'

class Agence extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'nom',
      'adresse',
      'cp',
      'ville',
      'villeId',
      'email',
      'telephone'
    ])
  }

  save() {
    if (this.isNew()) {
      return api.POST('agence/add', this).then((agence) => {
        M.toast({
          html: `Le agence a été ajoutée.`,
          classes: 'green'
        })

        this.resetModelRef()
        return agence
      })
    } else {
      return api.PUT('agence/edit', this).then((agence) => {
        M.toast({
          html: `Le agence a été modifiée.`,
          classes: 'green'
        })

        this.resetModelRef()
        return agence
      })
    }
  }

  delete() {
    return api.DELETE('agence/delete', this).then(() => {
      M.toast({
        html: `Le agence a été supprimée.`,
        classes: 'green'
      })
    })
  }
}

export default Agence
