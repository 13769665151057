import { parseNumber } from '@/plugins/utils'

class Production_Commission {
  constructor(data = {}) {
    const {
      id,
      agence,

      contratId,
      collaborateurId,
      produitId,
      dateCommission,
      dateSaisie,
      dateCalcul,
      regularisation,
      lastPaid,
      montant,
      modified,
      created,

      //
      calculs,
      configurationId
    } = data

    this.id = id

    this.agence = !!agence

    this.contratId = contratId
    this.collaborateurId = collaborateurId
    this.produitId = produitId

    this.dateCommission = dateCommission || ''
    this.dateSaisie = dateSaisie || ''
    this.dateCalcul = dateCalcul || ''

    this.regularisation = !!regularisation
    this.lastPaid = parseNumber(lastPaid)

    this.montant = parseNumber(montant)

    this.calculs = calculs || {}
    this.configurationId = configurationId

    this.modified = parseNumber(modified)
    this.created = created || ''
  }

  isNew() {
    return !this.id
  }
}

export default Production_Commission
