<template>
  <transition name="fade" mode="out-in">
    <template v-if="pending">
      <div class="loading preloader valign-wrapper">
        <div class="preloader-wrapper big active">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <div class="msg">
          {{ loadingMessage ? loadingMessage : 'CHARGEMENT ...' }}
        </div>
        <div class="progress-bar-layer">
          <div
            class="progress-bar"
            :style="{
              width: progressBar + '%'
            }"
          ></div>
        </div>
      </div>
    </template>
    <div v-else-if="dashboardShow && user" key="dashboard">
      <Header />
      <Sidenav />
      <Main />
      <Footer />
      <SidebarRight />
    </div>
    <div v-else key="dashboardless">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view :key="reloadRouterKey" />
      </transition>
    </div>
  </transition>
</template>

<script>
import Header from './header/header'
import Sidenav from './sidenav/sidenav'
import Main from './main'
import SidebarRight from './sidebar/right'

import { mapGetters } from 'vuex'

import './dashboard.scss'
import groups from '@/configs/groups'
import { sleep } from '@/plugins/utils'

export default {
  name: 'Dashboard',
  components: {
    Header,
    Sidenav,
    Main,
    SidebarRight
  },
  data() {
    return {
      prevHeight: 0,
      progressBar: 0,
      pending: false,
      loadingMessage: null
    }
  },
  computed: {
    ...mapGetters('ui', {
      dashboardShow: 'dashboard'
    }),
    ...mapGetters('user', ['user', 'isAllowed']),
    reloadRouterKey() {
      return this.$root.reloadRouterKey
    }
  },
  watch: {
    user: {
      handler(user, oldUser) {
        if (user && !oldUser) {
          this.loadApp()
        }
      },
      immediate: true,
      deep: true
    }
  },
  unmounted() {
    console.log('unmounted')
  },
  methods: {
    async loadApp() {
      this.pending = true
      this.progressBar = 0

      if (!this.isAllowed(groups.USER)) {
        return
      }

      // Chargement des collaborateurs
      this.loadingMessage = `Chargement des collaborateurs`
      await this.$store.dispatch('user/collaborateurs')
      this.progressBar = 10

      await this.$store.dispatch('group/list')
      this.progressBar = 20

      // Chargement des clients
      if (this.isAllowed(groups.FICHE_LIST)) {
        this.loadingMessage = `Chargement des clients`
        await this.$store.dispatch('client/list')
        await this.$store.dispatch('fiche/refresh')
      }
      this.progressBar = 40

      // Chargement des contrats
      if (
        this.isAllowed([groups.PRODUCTION_LIST, groups.PRODUCTION_SUPERVISER])
      ) {
        this.loadingMessage = `Chargement des produits`
        await this.$store.dispatch('production/produits/refresh')
        this.progressBar = 60

        this.loadingMessage = `Chargement des contrats`
        await this.$store.dispatch('production/contrats/refresh')
        this.progressBar = 80

        this.loadingMessage = `Chargement des avenants`
        await this.$store.dispatch('production/avenants/refresh')
        this.progressBar = 90

        this.loadingMessage = `Chargement des statistiques`
        await this.$store.dispatch('production/commissions/refresh')
      }

      this.loadingMessage = `Chargement terminé`
      this.progressBar = 100

      await sleep(500)
      this.pending = false
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter(element) {
      const { height } = getComputedStyle(element)
      element.style.height = this.prevHeight
      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    }
  }
}
</script>
