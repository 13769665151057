import Produit from '../produit.model'

export default {
  add: ({ POST }, produit) =>
    new Promise((resolve, reject) => {
      if (!(produit instanceof Produit)) {
        return reject(new Error(
          `Erreur paramètre : Produit attendu mais reçu "${produit.constructor.name}"`
        ))
      }
      return POST(`production/produits`, produit)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const produit = new Produit(data)
            resolve(produit)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
