export const GET_RESILIATIONS = 'GET_RESILIATIONS'
export const GET_RESILIATIONS_SUCCESS = 'GET_RESILIATIONS_SUCCESS'
export const GET_RESILIATIONS_FAIL = 'GET_RESILIATIONS_FAIL'

export default {
  [GET_RESILIATIONS] (state) {
    state.resiliations_pending = true
  },
  [GET_RESILIATIONS_SUCCESS] (state, resiliations) {
    state.resiliations = resiliations
    state.resiliations_pending = false
  },
  [GET_RESILIATIONS_FAIL] (state) {
    state.resiliations_pending = false
  }
}
