export const GET_CHEQUES = 'GET_CHEQUES'
export const GET_CHEQUES_SUCCESS = 'GET_CHEQUES_SUCCESS'
export const GET_CHEQUES_FAIL = 'GET_CHEQUES_FAIL'

export default {
  [GET_CHEQUES] (state) {
    state.cheques_pending = true
  },
  [GET_CHEQUES_SUCCESS] (state, cheques) {
    state.cheques = cheques
    state.cheques_pending = false
  },
  [GET_CHEQUES_FAIL] (state) {
    state.cheques_pending = false
  }
}
