import DELETE from './api/delete'
import GET from './api/get'
import POST from './api/post'
import PUT from './api/put'
import ville from './ville/ville.api'

export default {
  DELETE,
  GET,
  POST,
  PUT,
  namespaced: true,
  modules: {
    ville
  }
}
