import { api } from '@/plugins'
import {
  GET_SURCOMMISSION_CONFIG,
  GET_SURCOMMISSION_CONFIG_SUCCESS,
  GET_SURCOMMISSION_CONFIG_FAIL
} from './mutations'

export default {
  listConfig({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_SURCOMMISSION_CONFIG)

      api
        .GET('production/surcommissions/listConfig')
        .then(async (datas) => {
          await commit(GET_SURCOMMISSION_CONFIG_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_SURCOMMISSION_CONFIG_FAIL)
          reject(e)
        })
    })
  }
}
