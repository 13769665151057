import { sortDeep } from '@/plugins/utils'

export const GET_DONNEES = 'GET_DONNEES'
export const GET_DONNEES_SUCCESS = 'GET_DONNEES_SUCCESS'
export const GET_DONNEES_FAIL = 'GET_DONNEES_FAIL'

export default {
  [GET_DONNEES](state) {
    state.donnees_pending = true
  },
  [GET_DONNEES_SUCCESS](state, data) {
    let { donnees, type: types } = data

    if (types) {
      if (!Array.isArray(types)) {
        types = types.split(',')
      }

      let _donnees = state.donnees.filter(
        (d) => !types.includes(d.getProperty('type'))
      )
      _donnees.push(...donnees)
      state.donnees = sortDeep(_donnees, 'id')
    }

    state.donnees_pending = false
  },
  [GET_DONNEES_FAIL](state) {
    state.donnees_pending = false
  }
}
