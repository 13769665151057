import Model from '../satisfaction.model'

export default {
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET('satisfactions')
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let quests = []
            data.forEach((d) => {
              quests.push(new Model(d))
            })
            resolve(quests)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`satisfactions/${id}`)
        .then((reponse) => {
          resolve(reponse.data)
        })
        .catch((error) => {
          reject(error)
        })
    }),
  possibleToPost: ({ GET }, idFiche) =>
    new Promise((resolve, reject) => {
      return GET(`satisfactions/possible-to-post`, { idFiche })
        .then((reponse) => {
          resolve(!!reponse?.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
}
