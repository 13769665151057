export default {
  delete: ({ DELETE }, agence) =>
    new Promise((resolve, reject) => {
      if (!agence.getId()) {
        return reject(new Error(`Erreur paramètre : agence ID non valide`))
      }
      return DELETE(`agences/${agence.getId()}`)
        .then((reponse) => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
