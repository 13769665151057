<template>
  <aside id="right-sidebar-nav">
    <div
      id="slide-out-right"
      class="
        slide-out-right-sidenav
        sidenav
        rightside-navigation
        app-sidenav app-sidenav-right
      "
      data-sidenav-group="right"
    >
      <div v-if="onglets && Object.keys(onglets).length > 0" class="row">
        <div class="slide-out-right-title">
          <div class="col s12 border-bottom-1 pb-0 pt-1">
            <div class="row">
              <div class="col s2 pr-0 center">
                <i class="material-icons vertical-text-middle"
                  ><a href="#" class="sidenav-close">clear</a></i
                >
              </div>
              <div class="col s10 pl-0">
                <ul class="tabs">
                  <li
                    v-for="(onglet, index) in onglets"
                    :key="index"
                    class="tab"
                  >
                    <a :href="`#${index}`" class="pl-5 pr-5">
                      <span>{{ onglet.label }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            app-perfectscrollbar app-perfectscrollbar-no-x
            slide-out-right-body
          "
        >
          <div v-for="(onglet, index) in onglets" :id="index" :key="index">
            <component :is="onglet.component" />
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarRight',
  computed: {
    ...mapGetters('user', ['user']),
    isAdmin() {
      return this.user && this.user.isAdmin()
    },
    onglets() {
      const onglets = {}
      if (this.isAdmin) {
        onglets.administration = {
          label: 'Admin',
          component: () => import('./onglet/administration.vue')
        }
      }
      return onglets
    }
  }
}
</script>
