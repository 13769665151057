import { scripts } from "@/configs/assets";

export function loadScript(src) {
  return new Promise(function(resolve, reject) {
    if (document.querySelector('script[src="' + src + '"]')) {
      resolve();
      return;
    }

    const el = document.createElement("script");

    el.type = "text/javascript";
    el.async = true;
    el.src = src;

    el.addEventListener("load", resolve);
    el.addEventListener("error", reject);
    el.addEventListener("abort", reject);

    document.head.appendChild(el);
  });
}

export function unloadScript(src) {
  return new Promise(function(resolve, reject) {
    const el = document.querySelector('script[src="' + src + '"]');

    if (!el) {
      reject();
      return;
    }

    document.head.removeChild(el);
    resolve();
  });
}

export function chainLoad(index) {
  return new Promise(function(resolve, reject) {
    if (index >= scripts.length) {
      resolve();
      return;
    }
    if (!scripts[index]) {
      reject();
      return;
    }

    loadScript(scripts[index])
      .then(() => {
        loadingBar(index);
        chainLoad(index + 1)
          .then(() => {
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      })
      .catch(e => {
        // eslint-disable-next-line
        console.error("ERREUR DE CHARGEMENT", index, scripts[index], e);
      });
  });
}

function loadingBar(index) {
  const el = document.querySelector(
    ".loading.preloader .progress-bar-layer .progress-bar"
  );
  if (!el) return;
  const width = Math.round(((index + 1) / scripts.length) * 100);
  el.style.width = width + "%";
}

export function loadScripts() {
  return chainLoad(0);
}
