export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL'

export default {
  [GET_CLIENTS] (state) {
    state.clients_pending = true
  },
  [GET_CLIENTS_SUCCESS] (state, clients) {
    state.clients = clients
    state.clients_pending = false
  },
  [GET_CLIENTS_FAIL] (state) {
    state.clients_pending = false
  }
}
