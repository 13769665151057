import { compareStr } from '@/plugins/utils'
import Instance from '../instance.model'

export default {
  instances: (state) => state.instances,
  instanceById: (state) => (id) => {
    const instance = state.instances
      ? state.instances.find((instance) => compareStr(instance.id)(id))
      : null
    return instance ? new Instance(instance) : null
  },
  instances_pending: (state) => state.instances_pending
}
