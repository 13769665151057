import Vue from 'vue'

import './Charts'

const components = [
  {
    tag: 'collapsible',
    prepare: () => import('./Collapsible')
  },
  {
    tag: 'loading',
    prepare: () => import('./Loading')
  },
  {
    tag: 'modal',
    prepare: () => import('./Modal')
  },
  {
    tag: 'tabs',
    prepare: () => import('./Tabs')
  },

  // FORM
  {
    tag: 'form-input',
    prepare: () => import('./Form/input')
  },
  {
    tag: 'form-input-group',
    prepare: () => import('./Form/inputgroup')
  },
  {
    tag: 'form-textarea',
    prepare: () => import('./Form/textarea')
  },
  {
    tag: 'form-checkbox',
    prepare: () => import('./Form/checkbox')
  },
  {
    tag: 'form-select',
    prepare: () => import('./Form/select')
  },
  {
    tag: 'form-switch',
    prepare: () => import('./Form/switch')
  },
  {
    tag: 'form-date',
    prepare: () => import('./Form/datepicker')
  },
  {
    tag: 'form-cpville',
    prepare: () => import('./Form/cpville')
  },
  {
    tag: 'form-fichier',
    prepare: () => import('@/modules/fichier/template/fichier')
  },

  // DataTable
  {
    tag: 'data-tables',
    prepare: () => import('./DataTables/DataTables')
  },
  {
    tag: 'data-table',
    prepare: () => import('./Datatable/Datatable')
  },

  // Templates
  {
    tag: 'template-sub-sidebar',
    prepare: () => import('./Templates/sub-sidebar')
  },
  {
    tag: 'template-menu-categories',
    prepare: () => import('./Templates/menu-categories')
  },
  {
    tag: 'pdf',
    prepare: () => import('./Templates/pdf')
  },

  // Ui
  {
    tag: 'ui-dropdown',
    prepare: () => import('./Ui/dropdown')
  }
]

components.forEach(({ tag, prepare }) => {
  Vue.component(tag, (resolve) => prepare().then((c) => resolve(c.default)))
})
