import { isObject, forEachValue, clone, getDeepValue } from '@/plugins/utils'
import moment from 'moment-timezone'
import { objectEquals } from '@/plugins/utils'

class AbstractModel {
  _dataConstructor = {}
  data = {}
  modelRef = null
  fields = []
  idFields = []

  isModelRef = false

  constructor(data, fields, idFields = []) {
    this.__reload(data, fields, idFields)
  }

  __reload(data, fields, idFields = []) {
    if (getDeepValue(data, '__is_model_reference', false)) {
      this.isModelRef = true
      delete data.__is_model_reference
    }

    this._dataConstructor = clone(data || {})
    this.idFields = idFields || []
    this.fields = fields || []

    // On défini des champs par défaut
    ;['id', 'modified', 'created'].forEach((f) => {
      if (!this.fields.includes(f)) {
        this.fields.push(f)
      }
    })

    this.fields.forEach((field) => {
      // Pour un traitement simple on peut se contenter du nom du champ ou d'un tableau
      if (Array.isArray(field)) {
        field = {
          field: field[0],
          default: field[1],
          callBack: field[2]
        }
      }
      if (!isObject(field)) {
        field = { field: field }
      }

      let prop = field.field
      let value = getDeepValue(this._dataConstructor, field.field)
      if (value === undefined) {
        value = field.default
      }

      // Si besoin d'un traitement avant ajout
      let callBack = field.callBack
      if (callBack) {
        value = callBack(value, this._dataConstructor, field.default, prop)
      }

      // Ajout de la valeur
      this.setProperty(prop, value)
    })

    //
    idFields.forEach((idField) => {
      const field = this.getProperty(idField)
      const newField = field && typeof field.id !== 'undefined' ? field.id : ''
      this.setProperty(idField, newField)
    })

    this.resetModelRef()
  }

  getId = function () {
    return this.getProperty('id')
  }

  getProperty = function (prop, _default = null) {
    return getDeepValue(this.data, prop, _default)
  }

  getPropertyDate = function (prop, format = null, _default = null) {
    const value = this.getProperty(prop)
    let _moment
    if (value) {
      _moment = moment.unix(value)
    } else if (_default) {
      if (_default === 'now') {
        _moment = moment()
      } else {
        _moment = moment.unix(_default)
      }
    }

    if (_moment && format) {
      return _moment.format(format)
    }
    return _moment
  }

  getSrcProperty = function (prop, _default = null) {
    return getDeepValue(this._dataConstructor, prop, _default)
  }

  setProperty = function (prop, value) {
    this.data[prop] = value
    return this
  }

  getDatas = function () {
    return this.data
  }

  isNew() {
    return !this.getId()
  }

  getExport() {
    return dataExport(this.data)
  }

  resetModelRef(ref) {
    let newModelRef = ref || this
    if (newModelRef && newModelRef.getExportRef && !this.isModelRef) {
      const datas = newModelRef.getExport()
      datas.__is_model_reference = true

      this.modelRef = new this.constructor(datas)
      this.modelRef.isModelRef = true
    }

    return this
  }

  isDirty(debug = false) {
    if (!this.modelRef) {
      this.resetModelRef()
    }

    if (!this.modelRef || !this.modelRef.getExportRef) {
      return true
    }

    return !objectEquals(
      this.getExportRef(),
      this.modelRef.getExportRef(),
      true,
      debug
    )
  }

  getExportRef() {
    let data = this.getExport()

    this.idFields.forEach((idField) => {
      data[`${idField}Id`] = data[idField].id ? data[idField].id : undefined
      data[idField] = ''
    })

    return data
  }
}

function dataExport(data) {
  if (isObject(data) && data instanceof AbstractModel) {
    return data.getExport()
  } else if (isObject(data) || Array.isArray(data)) {
    let _data = Array.isArray(data) ? [] : {}
    forEachValue(data, (d, k) => {
      const exp = dataExport(d)
      if (Array.isArray(_data)) {
        _data.push(exp)
      } else {
        _data[k] = exp
      }
    })
    return _data
  }
  return data
}

export const CALLBACK_SUBDOC_ID = function (subDoc, datas, valueDefault = '') {
  if (subDoc && subDoc.id) {
    return subDoc.id
  } else if (subDoc && !isNaN(parseInt(subDoc))) {
    return subDoc
  }
  return valueDefault
}

export const CALLBACK_NUMBER = function (value, datas, valueDefault = '') {
  if (typeof value === 'undefined') {
    return valueDefault
  }

  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  if (dateRegex.test(value)) {
    return moment(value).unix()
  }

  const numberValue = parseFloat(value)
  return isNaN(numberValue) ? valueDefault : numberValue
}

export default AbstractModel
