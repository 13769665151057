export default {
  login: ({ POST }, data) =>
    new Promise((resolve, reject) => {
      return POST(`auth/login`, data)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.token) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  otp: ({ POST }, data) =>
    new Promise((resolve, reject) => {
      return POST(`auth/otp`, data)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.token) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
