export default {
  install (Vue) {
    Vue.mixin({
      mounted () {
        // Init tabs
        $(this.$el)
          .find('.tabs')
          .each(function () {
            const $this = $(this)
            const options = {
              swipeable: !!$this.data('swipeable')
            }
            $this.tabs(options)
          })
      }
    })
  }
}
