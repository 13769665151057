import AbstractModel from '@/modules/abstract/abstract.model'
import { api } from '@/plugins'

class ApplicationConfig extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'annee',
      {
        field: 'configs',
        default: {}
      }
    ])

    this.init()
  }

  init() {
    if (!this.data.configs) {
      this.data.configs = {}
    }
  }

  getAnnee() {
    return this.getProperty('annee')
  }

  save() {
    if (this.isNew()) {
      return api.POST('application/addConfig', this).then((config) => {
        M.toast({
          html: `La configuration a été enregistrée.`,
          classes: 'green'
        })

        this.resetModelRef()
        return config
      })
    } else {
      return api.PUT('application/editConfig', this).then((config) => {
        M.toast({
          html: `Le configuration a été modifiée.`,
          classes: 'green'
        })

        this.resetModelRef()
        return config
      })
    }
  }
}

export default ApplicationConfig
