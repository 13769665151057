import SuiviHebdo from '../suivihebdo.model'

export default {
  add: ({ POST }, suivihebdo) =>
    new Promise((resolve, reject) => {
      if (!(suivihebdo instanceof SuiviHebdo)) {
        return reject(
          new Error(
            `Erreur paramètre : SuiviHebdo attendu mais reçu "${suivihebdo.constructor.name}"`
          )
        )
      }
      return POST('suivihebdos', suivihebdo)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const suivihebdo = new SuiviHebdo(data)
            resolve(suivihebdo)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
