function tootip() {
  $(this.$el).find('.tooltipped').tooltip({
    delay: 50
  })
}

export default {
  install(Vue) {
    Vue.mixin({
      mounted() {
        tootip.bind(this)()
      },
      updated() {
        tootip.bind(this)()
      },
      beforeDestroy() {
        try {
          // $(this.$el).find('.tooltipped').tooltip('dispose') // used in v4
          $(this.$el).find('.tooltipped').tooltip('destroy') // v3 and older
        } catch (e) {
          // ignore
        }
      }
    })
  }
}
