export const GET_PRIME_CONFIG = 'GET_PRIME_CONFIG'
export const GET_PRIME_CONFIG_SUCCESS = 'GET_PRIME_CONFIG_SUCCESS'
export const GET_PRIME_CONFIG_FAIL = 'GET_PRIME_CONFIG_FAIL'

export default {
  [GET_PRIME_CONFIG](state) {
    state.models_pending = true
  },
  [GET_PRIME_CONFIG_SUCCESS](state, configs) {
    state.configs = configs
    state.models_pending = false
  },
  [GET_PRIME_CONFIG_FAIL](state) {
    state.models_pending = false
  }
}
