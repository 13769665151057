import Model from '../models/stat.model'

export default {
  get: ({ GET }, statKey) =>
    new Promise((resolve, reject) => {
      if (!statKey) {
        return reject(new Error(`Erreur paramètre : stat.key non valide`))
      }
      return GET(`stats/${statKey}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
