import { getStorage } from '@/plugins/Store/locationStorage'
import { STORE_SB_COLLAPSE } from './storage'

export default {
  sidebarLock: !getStorage().getItem(STORE_SB_COLLAPSE),
  colDatatables: {},
  dashboard: false,
  scrollLocked: false,
  modeDebug: false
}
