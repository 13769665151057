import BienEtre from '../bienetre.model'

export default {
  add: ({ POST }, bienetre) =>
    new Promise((resolve, reject) => {
      if (!(bienetre instanceof BienEtre)) {
        return reject(
          new Error(
            `Erreur paramètre : BienEtre attendu mais reçu "${bienetre.constructor.name}"`
          )
        )
      }
      return POST('bienetres', bienetre)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const bienetre = new BienEtre(data)
            resolve(bienetre)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
