import { api } from '@/plugins'
import {
  APPLICATION_CONFIG,
  APPLICATION_CONFIG_SUCCESS,
  APPLICATION_CONFIG_FAIL
} from './mutations'

export default {
  getConfig({ commit }, annee) {
    return new Promise((resolve, reject) => {
      commit(APPLICATION_CONFIG)

      api
        .GET('application/getConfig', annee)
        .then(async (datas) => {
          await commit(APPLICATION_CONFIG_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(APPLICATION_CONFIG_FAIL)
          reject(e)
        })
    })
  }
}
