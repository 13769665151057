import Model from '../recommandation.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      if (!id) {
        return reject(new Error(`Erreur paramètre : ID non valide`))
      }
      return GET(`recommandations/${id}`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`recommandations`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
