import AbstractModel from '../abstract/abstract.model'

class BienEtre extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'userId',
      {
        field: 'locaux_confort',
        default: 0
      },
      {
        field: 'ambiance_collegue',
        default: 0
      },
      {
        field: 'sup_confiance',
        default: 0
      },
      {
        field: 'sup_soutien',
        default: 0
      },
      {
        field: 'sup_soucis',
        default: 0
      },
      {
        field: 'adequation_attentes',
        default: 0
      },
      {
        field: 'capacite_evolution',
        default: 0
      },
      {
        field: 'stress',
        default: 0
      },
      {
        field: 'surcharge_travail',
        default: 0
      },
      {
        field: 'partage_objectifs',
        default: 0
      },
      {
        field: 'acteur_decisions',
        default: 0
      }
    ])
  }
}

export default BienEtre
