import agence from '@/modules/agence/agence.api'
import application from '@/modules/application/application.api'
import auth from '@/modules/auth/auth.api'
import bienetre from '@/modules/bienetre/bienetre.api'
import cabinet from '@/modules/cabinet/cabinet.api'
import caisse from '@/modules/caisse/caisse.api'
import cheque from '@/modules/cheque/cheque.api'
import client from '@/modules/client/client.api'
import donnee from '@/modules/donnee/donnee.api'
import fiche from '@/modules/fiche/fiche.api'
import fichier from '@/modules/fichier/fichier.api'
import instance from '@/modules/instance/instance.api'
import notification from '@/modules/notification/notification.api'
import production from '@/modules/production/production.api'
import recommandation from '@/modules/recommandation/recommandation.api'
import resiliation from '@/modules/resiliation/resiliation.api'
import satisfaction from '@/modules/satisfaction/satisfaction.api'
import stat from '@/modules/stat/stat.api'
import suivihebdo from '@/modules/suivihebdo/suivihebdo.api'
import user from '@/modules/user/user.api'
import group from '@/modules/group/group.api'

export default {
  baseUrl: '/api/',
  modules: {
    agence,
    application,
    auth,
    bienetre,
    cabinet,
    caisse,
    cheque,
    client,
    donnee,
    fiche,
    fichier,
    instance,
    notification,
    production,
    recommandation,
    resiliation,
    satisfaction,
    stat,
    suivihebdo,
    user,
    group
  }
}
