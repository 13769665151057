import ApplicationConfig from '../models/config.model'

export default {
  getConfig: ({ GET }, annee) =>
    new Promise((resolve, reject) => {
      if (!annee) {
        return reject(new Error(`Erreur paramètre : "annee" non saisie`))
      }

      return GET(`application/configs/${annee}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            return resolve(new ApplicationConfig(data))
          } else {
            return reject(reponse.status)
          }
        })
        .catch((error) => {
          const { response } = error
          if (response.status === 404) {
            return resolve(new ApplicationConfig({ annee }))
          }
          reject(error)
        })
    })
}
