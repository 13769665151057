// Plugins
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

// Plugin perso
import Api from './Api'
import router from './Router'

// initialisation des plugins JS
import JS from './js'

// Configs
import apiConfigs from '@/configs/api'
import storeConfigs from '@/configs/store'

// Enregistrement plugin
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Api)
Vue.use(JS)

// Initialisation plugin
const store = new Vuex.Store(storeConfigs)
const api = new Api(apiConfigs)

export { router, store, api }
