<template>
  <div>
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification"
      :class="{
        unread: NOTIFICATION_STATE.UNREAD === notification.state
      }"
    >
      <div class="notification-text">
        <span class="notification-title">
          {{ notification.title }}
        </span>
        <span class="notification-description">
          {{ notification.description }}
        </span>
      </div>
      <div class="notification-actions">
        <template v-if="user.isAdmin()">
          <a
            v-if="NOTIFICATION_STATE.UNREAD === notification.state"
            title="Marquer comme lu"
            class="notification-action notification-read blue-text dropdown-close"
            @click="readNotif(notification.id)"
          >
            <i class="material-icons">remove_red_eye</i>
          </a>
        </template>
        <router-link
          v-else
          title="Voir le formulaire"
          class="notification-action notification-read blue-text dropdown-close"
          :to="readNotifAndRedirect(notification.id)"
        >
          <i class="material-icons">link</i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_STATE } from '@/modules/notification/notification.data'
import { mapGetters } from 'vuex'

export default {
  name: 'NotificationBienetreList',
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      NOTIFICATION_STATE
    }
  },
  computed: {
    ...mapGetters('notification', ['notificationById']),
    ...mapGetters('user', ['user', 'isAdmin'])
  },
  methods: {
    readNotif(id) {
      const notif = this.notificationById(id)
      notif.read()
    },
    readNotifAndRedirect(id) {
      const notif = this.notificationById(id)
      notif.read()
      return { name: 'user.profile' }
    }
  }
}
</script>
