import Ville from '../ville.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`donnees/villes/${id}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Ville(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }, cp) =>
    new Promise((resolve, reject) => {
      cp = String(cp).trim()
      if (!cp.match(/^\d+$/)) {
        // Si le code postal n'est pas un nombre, on ne retourne aucune ville
        return resolve([])
      }

      return GET(`donnees/villes?cp=${cp}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
