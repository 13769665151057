export default {
  get: ({ GET }, cabinetId) =>
    new Promise((resolve, reject) => {
      return GET(`cabinets/${cabinetId}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`cabinets`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  contactsList: ({ GET }, cabinetId) =>
    new Promise((resolve, reject) => {
      return GET(`cabinets/${cabinetId}/contacts`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
