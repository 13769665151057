<template>
  <div
    id="main-cube-scene"
    class="scene"
    :class="{
      'main-full': !navbarLocked
    }"
  >
    <div
      id="main"
      :class="{
        'overflow-hidden': scrollLocked
      }"
    >
      <div id="main-content">
        <div class="row">
          <Breadcrumbs />
          <div class="col s12">
            <transition
              name="fade"
              mode="out-in"
              @beforeLeave="beforeLeave"
              @enter="enter"
              @afterEnter="afterEnter"
            >
              <router-view :key="reloadRouterKey" />
            </transition>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from './breadcrumbs/breadcrumbs'
import Footer from './footer/footer'

import { mapGetters } from 'vuex'

export default {
  name: 'Main',
  components: {
    Breadcrumbs,
    Footer
  },
  data() {
    return {
      prevHeight: 0
    }
  },
  computed: {
    ...mapGetters('ui', {
      scrollLocked: 'scrollLocked',
      navbarLocked: 'sidebarLock',
      modeDebugON: 'modeDebug'
    }),
    ...mapGetters('user', ['user']),
    reloadRouterKey() {
      return this.$root.reloadRouterKey
    },
    isDebugger() {
      return this.user && this.user.login === 'torop'
    }
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter(element) {
      const { height } = getComputedStyle(element)
      element.style.height = this.prevHeight
      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    }
  }
}
</script>
