export default {
  produits_pending: false,
  produits: [],
  produits_refresh_pending: false,
  lastModified: null,
  groupes: [],
  groupes_pending: false,
  familles: [],
  familles_pending: false,
  statistiques: [],
  statistiques_pending: false
}
