import Configuration from '../configuration.model'

export default {
  add: ({ POST }, configuration) =>
    new Promise((resolve, reject) => {
      if (!(configuration instanceof Configuration)) {
        return reject(new Error(
          `Erreur paramètre : Configuration attendu mais reçu "${configuration.constructor.name}"`
        ))
      }
      return POST(`production/configurations`, configuration)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const configuration = new Configuration(data)
            resolve(configuration)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
