import { compareStr } from '@/plugins/utils'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { getTrimestreIntervalleByDate } from '../date'

/* eslint-disable camelcase */
class Production_Configuration {
  constructor(data = {}) {
    const {
      id,
      collaborateurId,
      produitId,
      configurations,
      _configurations,
      modified,
      mode
    } = data

    this.id = id
    this.collaborateurId = collaborateurId
    this.produitId = produitId
    this.modified = modified
    this.mode = mode

    this.configurations = {}
    this._configurations = Array.isArray(configurations)
      ? configurations
      : _configurations ?? []
  }

  isNew() {
    return !this.id
  }

  getConfig(annee) {
    return getConfig(this, annee)
  }
}

export function getConfig(prodConfig, annee) {
  if (!prodConfig._configurations) {
    if (Array.isArray(prodConfig.configurations)) {
      prodConfig._configurations = prodConfig.configurations
    } else {
      console.log('no config')
      return {}
    }
  }

  if (!annee) {
    const date = moment()
    const trimestreIntervalle = getTrimestreIntervalleByDate(date.toDate())
    if (!trimestreIntervalle) {
      console.log('out date')
      return {}
    }
    annee = trimestreIntervalle.annee
  }

  let config = cloneDeep(
    prodConfig._configurations.find((c) => compareStr(c.annee)(annee)) ??
      prodConfig._configurations.reduce(
        (r, config) => (r.annee > config.annee ? r : config),
        {}
      )
  )

  if (config) {
    config.annee = annee

    config.prime_conquete = parseFloat(config.prime_conquete) || ''
    config.prime_prospection = parseFloat(config.prime_prospection) || ''
    config.prime_forfaitaire = parseFloat(config.prime_forfaitaire) || ''
    config.taux_commission_frais_fixe =
      parseFloat(config.taux_commission_frais_fixe) || ''
    config.frais_pourcent_moins = parseFloat(config.frais_pourcent_moins) || ''
    config.taux_commission_distribue =
      parseFloat(config.taux_commission_distribue) || ''
    config.coef_ca_si_sel = parseFloat(config.coef_ca_si_sel) || 1
    config.prime_nb_contrat = config.prime_nb_contrat ? 1 : 0
    config.mode = config.mode || { type: 'disabled' }
    if (config.mode?.type === 'non-disponible') {
      config.mode.type = 'disabled'
    }
    config.surco = config.surco || {}
    config.plafond_commission = config.plafond_commission || ''

    return config
  }

  console.log('out end')

  return {}
}

export default Production_Configuration
