import Model from '../fiche.model'
import FicheCompteRendu from '../models/compterendu.model'
import FicheVisite from '../models/visite.model'
import FicheSignature from '../models/signature.model'
import FicheDevis from '../models/devis.model'

export default {
  add: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(`fiches`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  visiteAdd: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const model = datas.visite
      const ficheId = datas.fiche ? datas.fiche.getId() : null
      if (!(model instanceof FicheVisite)) {
        return reject(
          new Error(
            `Erreur paramètre : ${FicheVisite.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : Fiche ID non défini`))
      }
      return POST(`fiches/${ficheId}/visites`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new FicheVisite(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  devisAdd: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { data, fiche, visite } = datas
      const ficheId = fiche ? fiche.getId() : null
      const visiteId = visite ? visite.getId() : null
      if (!data) {
        return reject(new Error(`Erreur paramètre : Data non défini`))
      }
      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : Fiche ID non défini`))
      }
      if (!visiteId) {
        return reject(new Error(`Erreur paramètre : Visite ID non défini`))
      }
      return POST(`fiches/${ficheId}/visites/${visiteId}/devis`, data)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new FicheDevis(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  compterenduAdd: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { model, fiche, visite } = datas
      const ficheId = fiche ? fiche.getId() : null
      const visiteId = visite ? visite.getId() : null
      if (!(model instanceof FicheCompteRendu)) {
        return reject(
          new Error(
            `Erreur paramètre : ${FicheCompteRendu.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : Fiche ID non défini`))
      }
      if (!visiteId) {
        return reject(new Error(`Erreur paramètre : Visite ID non défini`))
      }
      return POST(`fiches/${ficheId}/visites/${visiteId}/compterendu`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new FicheCompteRendu(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  signRequest: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { fiche, visite } = datas
      const ficheId = fiche ? fiche.getId() : null
      const visiteId = visite ? visite.getId() : null

      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : Fiche ID non défini`))
      }
      if (!visiteId) {
        return reject(new Error(`Erreur paramètre : Visite ID non défini`))
      }

      return POST(`fiches/${ficheId}/visites/${visiteId}/signature`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new FicheSignature(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  sendAvisGoogle: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { idFiche, agence } = datas
      if (!idFiche) {
        return reject(new Error(`Erreur paramètre : idFiche non défini`))
      }
      return POST(`mail/notifyClient/google/${idFiche}/${agence}`)
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve()
          } else {
            reject(new Error(`Erreur lors de l'envoi du mail`))
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  sendDemarcheConseil: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { idFiche } = datas
      if (!idFiche) {
        return reject(new Error(`Erreur paramètre : idFiche non défini`))
      }
      return POST(`mail/notifyClient/conseil/${idFiche}`)
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve()
          } else {
            reject(new Error(`Erreur lors de l'envoi du mail`))
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  cabinetRequest: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      return POST(`cabinets/request`, datas)
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve(reponse.data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  demandeConsentement: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      return POST(`fiches/${datas.ficheId}/consentement-rgpd`, {
        email: datas.email
      })
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve(reponse.data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),

  accepteConsentement: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { idConsentement } = datas
      if (!idConsentement) {
        return reject(new Error(`Erreur paramètre : idConsentement non défini`))
      }
      return POST(`fiches/consentement/${idConsentement}/accepte`)
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve(reponse.data)
          } else {
            reject(reponse.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  skipConsentement: ({ POST }, datas) =>
    new Promise((resolve, reject) => {
      const { idConsentement } = datas
      if (!idConsentement) {
        return reject(new Error(`Erreur paramètre : idConsentement non défini`))
      }
      return POST(`fiches/consentement/${idConsentement}/skip`)
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve(reponse.data)
          } else {
            reject(reponse.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  duplicate: ({ POST }, id) =>
    new Promise((resolve, reject) => {
      return POST(`fiches/${id}/duplicate`)
        .then((reponse) => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
