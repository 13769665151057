import VueRouter from 'vue-router'
import { configs } from '@/configs/router'

import Auth from './modules/auth'
import Dirty from './modules/dirty'
import HeadMeta from './modules/head-meta'
import Dashboard from './modules/dashboard'
import Modal from './modules/modal'

import { store } from '@/plugins'

import { getDeepValue } from '@/plugins/utils'

const router = new VueRouter(configs)

router.beforeEach(async (to, from, next) => {
  to.meta._prevRoute = from ? from.fullPath : undefined
  to.meta.debugVars = {}

  next()
  store.dispatch('router/initiated')
})

Auth(router)
Dirty(router)
HeadMeta(router)
Dashboard(router)
Modal(router)

router.beforeEach(async (to, from, next) => {
  const onRouterBefore = getDeepValue(to, 'meta.onRouterBefore', () => {})
  if (onRouterBefore) {
    await onRouterBefore({ to, from, router, store })
  }

  next()
})

router.afterEach(async (to, from) => {
  const onRouterAfter = getDeepValue(to, 'meta.onRouterAfter', () => {})
  if (onRouterAfter) {
    await onRouterAfter({ to, from, router, store })
  }
  $(window).resize()
})

export default router
