import Model from '../bienetre.model'

export default {
  get: ({ GET }, userId) =>
    new Promise((resolve, reject) => {
      if (!userId) {
        return reject(new Error(`Erreur paramètre : user ID non valide`))
      }
      return GET(`bienetres/${userId}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET('bienetres/list')
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let quests = []
            data.forEach((d) => {
              quests.push(new Model(d))
            })
            resolve(quests)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
