import { api } from '@/plugins'
import {
  CONTRAT_CONFIG,
  CONTRAT_CONFIG_SUCCESS,
  CONTRAT_CONFIG_FAIL
} from './mutations'

export default {
  getConfig({ commit }, annee) {
    return new Promise((resolve, reject) => {
      commit(CONTRAT_CONFIG)

      api
        .GET('production/contrats/configs/getConfig', annee)
        .then(async (datas) => {
          await commit(CONTRAT_CONFIG_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(CONTRAT_CONFIG_FAIL)
          reject(e)
        })
    })
  }
}
