import { api } from '@/plugins'
import {
  GET_DONNEES,
  GET_DONNEES_SUCCESS,
  GET_DONNEES_FAIL
} from './mutations'

export default {
  list ({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_DONNEES)

      api
        .GET('donnee/list')
        .then(async donnees => {
          await commit(GET_DONNEES_SUCCESS, { donnees })
          resolve(donnees)
        })
        .catch(e => {
          commit(GET_DONNEES_FAIL)
          reject(e)
        })
    })
  },

  type ({ commit }, type) {
    return new Promise((resolve, reject) => {
      commit(GET_DONNEES)

      if (!type) {
        commit(GET_DONNEES_FAIL)
        reject(new Error('Aucun type défini.'))
      }

      api
        .GET('donnee/list', type)
        .then(async donnees => {
          await commit(GET_DONNEES_SUCCESS, { donnees, type })
          resolve(donnees)
        })
        .catch(e => {
          commit(GET_DONNEES_FAIL)
          reject(e)
        })
    })
  }
}
