import AbstractModel, {
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'
import FicheUser from './user.model'

class FicheDonation extends AbstractModel {
  constructor(data) {
    super(data, [
      {
        field: 'type',
        callBack: CALLBACK_SUBDOC_ID
      },
      'date',
      {
        field: 'beneficiaire',
        callBack: (value) => new FicheUser(value)
      },
      {
        field: 'beneficiaires',
        default: []
      },
      'montant',
      'montant_modified',
      'montantDatemodif'
    ])
  }

  getBeneficiaire() {
    return this.getProperty('beneficiaire')
  }
  getBeneficiaires() {
    return this.getProperty('beneficiaires')
  }
}

export default FicheDonation
