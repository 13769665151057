export default {
  install(Vue) {
    Vue.mixin({
      mounted() {
        // dropdown Pop
        $(this.$el).find('.dropdown-trigger').dropdown()

        // dropdown hover
        $(this.$el).find('.dropdown-button').dropdown({
          inDuration: 300,
          outDuration: 225,
          constrainWidth: false,
          hover: true,
          gutter: 0,
          coverTrigger: true,
          alignment: 'left'
          // stopPropagation: false
        })

        // dropdown menu
        $(this.$el)
          .find('.dropdown-menu')
          .each((index, el) => {
            if (!el.M_Dropdown) {
              $(el).dropdown({
                inDuration: 300,
                outDuration: 225,
                constrainWidth: false,
                hover: false,
                gutter: 0,
                coverTrigger: false,
                alignment: 'right',
                closeOnClick: $(el).data('closeonclick') !== false
                // stopPropagation: false
              })
            }
          })

        $(this.$el).off('click.dropdown-close', '.dropdown-close')
        $(this.$el).on('click.dropdown-close', '.dropdown-close', (e) => {
          const id = e.target?.closest('.dropdown-content')?.id
          if (id) {
            $(this.$el)
              .find(`.dropdown-menu[data-target="${id}"]`)
              .dropdown('close')
          }
        })
      },
      updated() {
        $(this.$el)
          .find('.dropdown-menu')
          .each((index, el) => {
            if (!el.M_Dropdown) {
              $(el).on('click', '.close', () => {
                console.log('test2')
              })
              $(el).dropdown({
                inDuration: 300,
                outDuration: 225,
                constrainWidth: false,
                hover: false,
                gutter: 0,
                coverTrigger: false,
                alignment: 'right',
                closeOnClick: $(el).data('closeonclick') !== false
                // stopPropagation: false
              })
            }
          })
      }
    })
  }
}
