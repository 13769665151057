import { api } from '@/plugins'
import {
  GET_QUESTIONNAIRES,
  GET_QUESTIONNAIRES_SUCCESS,
  GET_QUESTIONNAIRES_FAIL
} from './mutations'

export default {
  possibleToPost() {
    return new Promise((resolve, reject) => {
      api
        .GET('satisfaction/possibleToPost')
        .then(async (result) => {
          resolve(result.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  list({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_QUESTIONNAIRES)
      api
        .GET('satisfaction/list')
        .then(async (quests) => {
          await commit(GET_QUESTIONNAIRES_SUCCESS, quests)
          resolve(quests)
        })
        .catch((e) => {
          commit(GET_QUESTIONNAIRES_FAIL)
          reject(e)
        })
    })
  }
}
