export default {
  send: ({ POST }, query) =>
    new Promise((resolve, reject) => {
      if (!query.idFiche || !query.idCollaborateur) {
        return reject(
          new Error(
            `Erreur paramètre : l'id de la fiche et du collaborateur sont demandés !`
          )
        )
      }
      return POST('satisfactions/create', query)
        .then((reponse) => {
          resolve(reponse.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
}
