import groups, { GROUP_USER, GROUP_ADMIN, roles } from '@/configs/groups'

import { compareStr, sortDeep } from '@/plugins/utils'

export default [
  {
    title: 'Accueil',
    icon: 'settings_input_svideo',
    name: 'home',
    to: { name: 'home' },
    credentials: [GROUP_USER]
  },
  {
    title: 'Instances',
    icon: 'notifications',
    credentials: [
      groups.INSTANCE_COMMERCIALE_LIST,
      groups.INSTANCE_GESTION_LIST,
      groups.INSTANCE_DIRECTION_LIST,
      roles.SUPERVISEUR_INSTANCE
    ],
    items: [
      {
        title: 'Commerciales',
        credentials: [
          groups.INSTANCE_COMMERCIALE_LIST,
          roles.SUPERVISEUR_INSTANCE
        ],
        to: { name: 'instance.commerciale' },
        actions: [
          {
            title: 'Archives',
            credentials: [roles.SUPERVISEUR_INSTANCE],
            icon: 'archive',
            to: { name: 'instance.commerciale.archives' }
          }
        ],
        childrens: ['instance.commerciale.archives']
      },
      {
        title: 'Gestion',
        credentials: [groups.INSTANCE_GESTION_LIST, roles.SUPERVISEUR_INSTANCE],
        to: { name: 'instance.gestion' },
        actions: [
          {
            title: 'Archives',
            credentials: [roles.SUPERVISEUR_INSTANCE],
            icon: 'archive',
            to: { name: 'instance.gestion.archives' }
          }
        ],
        childrens: ['instance.gestion.archives']
      },
      {
        title: 'Direction',
        credentials: [groups.INSTANCE_DIRECTION_LIST],
        to: { name: 'instance.direction' },
        actions: [
          {
            title: 'Archives',
            credentials: [roles.INSTANCE_DIRECTION_LIST],
            icon: 'archive',
            to: { name: 'instance.direction.archives' }
          }
        ],
        childrens: ['instance.direction.archives']
      }
    ]
  },
  {
    title: 'Clients',
    icon: 'supervisor_account',
    credentials: [
      groups.FICHE_LIST,
      groups.RECOMMANDATION_LIST,
      groups.RESILIATION_LIST
    ],
    items: [
      {
        title: 'Fiches découverte',
        credentials: [groups.FICHE_LIST],
        to: { name: 'fiche.list' },
        childrens: ['fiche.new', 'fiche.edit']
      },
      {
        title: 'Recommandations',
        credentials: [groups.RECOMMANDATION_LIST],
        to: { name: 'recommandation.list' },
        childrens: ['recommandation.archive'],
        actions: [
          {
            title: 'Archives',
            credentials: [roles.RECOMMANDATION_LIST],
            icon: 'archive',
            to: { name: 'recommandation.archive' }
          }
        ]
      },
      {
        title: 'Résiliations',
        credentials: [groups.RESILIATION_LIST],
        to: { name: 'resiliation.list' }
      }
    ]
  },
  {
    title: 'Ma production',
    icon: 'chrome_reader_mode',
    credentials: [groups.PRODUCTION_LIST, groups.COMMISSION_LIST],
    items: [
      {
        title: 'Contrats',
        credentials: [groups.PRODUCTION_LIST],
        to: { name: 'production.contrat.list' },
        actions: [
          {
            icon: 'add',
            title: 'Ajouter un contrat',
            to: { name: 'production.contrat.new' },
            credentials: [groups.PRODUCTION_ADD]
          }
        ],
        childrens: ['production.contrat.new', 'production.contrat.edit']
      },
      {
        title: 'Avenants',
        credentials: [groups.AVENANT_LIST],
        to: { name: 'production.avenant.list' },
        actions: [
          {
            icon: 'add',
            title: 'Ajouter un avenant',
            to: { name: 'production.avenant.new' },
            credentials: [groups.AVENANT_ADD]
          }
        ],
        childrens: ['production.avenant.new', 'production.avenant.edit']
      },
      {
        title: 'Télétransmissions',
        credentials: [groups.TELETRANSMISSION_LIST],
        to: { name: 'production.contrat.teletransmission.list' },
        actions: [
          {
            title: 'Archives',
            credentials: [groups.TELETRANSMISSION_LIST],
            icon: 'archive',
            to: { name: 'production.contrat.teletransmission.archives' }
          }
        ],
        childrens: ['production.contrat.teletransmission.archives']
      },
      {
        title: 'DUE/DSN',
        credentials: [groups.DUEDSN_LIST],
        to: { name: 'production.contrat.duedsn.list' },
        actions: [
          {
            title: 'Archives',
            credentials: [groups.DUEDSN_LIST],
            icon: 'archive',
            to: { name: 'production.contrat.duedsn.archives' }
          }
        ],
        childrens: ['production.contrat.duedsn.archives']
      },
      {
        title: ({ user }) =>
          user && user.isAdmin() ? 'Commissions' : 'Statistiques',
        credentials: [groups.COMMISSION_LIST],
        to: { name: 'production.commission.list' }
      },
      {
        title: 'Agence',
        credentials: [GROUP_ADMIN],
        childrens: [
          'production.agence.commission.list',
          'production.agence.surcommission.list'
        ],
        items: [
          {
            title: 'Commissions',
            to: { name: 'production.agence.commission.list' }
          },

          {
            title: 'Surcommissions',
            to: { name: 'production.agence.surcommission.list' }
          }
        ]
      }
    ]
  },
  {
    title: 'Gestion',
    icon: 'business',
    credentials: [
      groups.CAISSE_LIST,
      groups.CHEQUE_LIST,
      groups.CABINET_LIST,
      groups.CABINET_SUPERVISER
    ],
    items: [
      {
        title: 'Caisses',
        credentials: [groups.CAISSE_LIST],
        items: (view) => {
          const agences = view.$store.getters['agence/agences']
          const user = view.$store.getters['user/user']

          if (!user) {
            return []
          }

          return sortDeep(
            agences
              .map((agence) => ({
                to: {
                  name: 'caisse.list',
                  params: {
                    id: agence.id
                  }
                },
                credentials: [groups.CAISSE_LIST],
                title: agence.nom
              }))
              .filter(
                (agence) =>
                  user.isAdmin() ||
                  user.isAllowed(groups.CAISSE_SUPERVISER) ||
                  compareStr(user.agence)(agence.to.params.id)
              ),
            'title'
          )
        }
      },
      {
        title: 'Chèques',
        credentials: [groups.CHEQUE_LIST],
        to: { name: 'cheque.list' },
        actions: [
          {
            title: 'Archives',
            credentials: [groups.CHEQUE_LIST],
            icon: 'archive',
            to: { name: 'cheque.archives' }
          }
        ],
        childrens: ['cheque.archives']
      },
      {
        title: 'Cabinets comptables',
        credentials: [groups.CABINET_LIST, groups.CABINET_SUPERVISER],
        to: { name: 'admin.cabinet.list' }
      }
    ]
  },
  {
    title: 'Mes outils',
    icon: 'link',
    credentials: [
      groups.OUTIL_MAXIREM,
      groups.OUTIL_DEMO_BIA,
      groups.OUTIL_INFOS_CCN,
      groups.OUTIL_BILAN_RETRAITE,
      groups.OUTIL_DROIT_SUCCESSION,
      groups.OUTIL_IMPOT_REVENU,
      groups.OUTIL_DEMARCHE,
      groups.OUTIL_SIMULATEUR_REVENUS
    ],
    items: [
      {
        title: 'Maxirem',
        credentials: [groups.OUTIL_MAXIREM],
        href: 'https://web.maxirem.fr/MAXIREM_WEB14_CIPRES?p=102863-62bf40477342accde304ff457a15f191',
        target: '_blank'
      },
      {
        title: 'Démo portail BIA',
        credentials: [groups.OUTIL_DEMO_BIA],
        href: 'https://www.swisslife.fr/Video-BIA',
        target: '_blank'
      },
      {
        title: 'Infos CCN',
        credentials: [groups.OUTIL_INFOS_CCN],
        href: 'https://ccn.swisslife-direct.fr/#/',
        target: '_blank'
      },
      {
        title: 'Bilan retraite',
        credentials: [groups.OUTIL_BILAN_RETRAITE],
        href: 'https://www.swisslifeone.fr/index-swisslifeOne.html#/tarification-et-simulation/bilan_retraite?refreshTime=1678210849410',
        target: '_blank'
      },
      {
        title: 'Droits de succession',
        credentials: [groups.OUTIL_DROIT_SUCCESSION],
        href: 'https://www.swisslifeone.fr/index-swisslifeOne.html#/tarification-et-simulation/droits_succession?refreshTime=1678210881919',
        target: '_blank'
      },
      {
        title: 'Impôts sur le revenu',
        credentials: [groups.OUTIL_IMPOT_REVENU],
        href: 'https://www.swisslifeone.fr/index-swisslifeOne.html#/tarification-et-simulation/impot_revenu?refreshTime=1678210906638',
        target: '_blank'
      },
      {
        title: 'Démarche conseil / Connaissance client',
        credentials: [groups.OUTIL_DEMARCHE],
        href: 'https://forms.office.com/r/drRQTYsqRn',
        target: '_blank'
      },
      {
        title: 'Simulateur de revenus',
        credentials: [groups.OUTIL_SIMULATEUR_REVENUS],
        items: [
          {
            title: 'Auxiliaire médical',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/auxiliaire-medical',
            target: '_blank'
          },
          {
            title: 'Chirurgien-dentiste',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/chirurgien-dentiste',
            target: '_blank'
          },
          {
            title: 'Médecin',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/medecin',
            target: '_blank'
          },
          {
            title: 'Sage-femme',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/sage-femme',
            target: '_blank'
          },
          {
            title: 'Pharmacien',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/pharmacien',
            target: '_blank'
          },
          {
            title: 'Avocat',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/avocat',
            target: '_blank'
          },
          {
            title: 'Expert-comptable',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/expert-comptable',
            target: '_blank'
          },
          {
            title: 'Cipav',
            href: 'https://mon-entreprise.urssaf.fr/simulateurs/profession-liberale/cipav',
            target: '_blank'
          }
        ]
      }
    ]
  },
  {
    title: 'Statistiques',
    icon: 'show_chart',
    credentials: [groups.USER],
    items: [
      {
        title: 'Collaborateurs',
        to: { name: 'stats.collaborateurs' },
        credentials: [
          groups.STATISTIQUES_COLLABORATEURS,
          groups.STATISTIQUES_COLLABORATEURS_SUPERVISER
        ]
      },
      {
        title: 'Suivi hebdomadaire',
        to: { name: 'stats.suivihebdo' },
        credentials: [
          groups.STATISTIQUES_SUIVIHEBDO,
          groups.STATISTIQUES_SUIVIHEBDO_SUPERVISER
        ]
      },
      {
        title: 'Bien-être',
        to: { name: 'stats.bien_etre' },
        credentials: [groups.STATISTIQUES_BIEN_ETRE]
      },
      {
        title: 'Satisfaction',
        to: { name: 'stats.satisfaction' },
        credentials: [groups.STATISTIQUES_SATISFACTION]
      },
      {
        title: 'Résiliations',
        to: { name: 'stats.resiliation' },
        credentials: [groups.STATISTIQUES_RESILIATION]
      },
      {
        title: 'Diagrammes',
        to: { name: 'stats.diagrammes' },
        credentials: [
          groups.STATISTIQUES_DIAGRAMMES,
          groups.STATISTIQUES_DIAGRAMMES_SUPERVISER
        ]
      }
    ]
  }
]
