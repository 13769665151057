import Model from '../models/ligne.model'

export default {
  editLigne: ({ PUT }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(new Error(
          `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
        ))
      } else if (model.isNew()) {
        return reject(new Error(`Erreur paramètre : ${Model.constructor.name} is new`))
      }

      return PUT(
        `caisses/${model.getCaisseId()}/lignes/${model.getId()}`,
        model
      )
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
