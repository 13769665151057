import Model from '../instance.model'
import { getApiType } from '../instance.data'

export default {
  get: ({ GET }, { type, id }) =>
    new Promise((resolve, reject) => {
      if (!type) {
        return reject(new Error(`Erreur paramètre : Type non valide`))
      }
      if (!id) {
        return reject(new Error(`Erreur paramètre : ID non valide`))
      }
      return GET(`instances/${getApiType(type)}/${id}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  listCOMByFicheVisite: ({ GET }, { ficheId, visiteId }) =>
    new Promise((resolve, reject) => {
      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : ficheId non valide`))
      }
      if (!visiteId) {
        return reject(new Error(`Erreur paramètre : visiteId non valide`))
      }
      return GET(`instances/commerciale/${ficheId}/${visiteId}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            const instances = []
            data.forEach((d) => instances.push(new Model(d)))
            resolve(instances)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  listGESByFicheVisite: ({ GET }, { ficheId, visiteId }) =>
    new Promise((resolve, reject) => {
      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : ficheId non valide`))
      }
      if (!visiteId) {
        return reject(new Error(`Erreur paramètre : visiteId non valide`))
      }
      return GET(`instances/gestion/${ficheId}/${visiteId}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            const instances = []
            data.forEach((d) => instances.push(new Model(d)))
            resolve(instances)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }, type) =>
    new Promise((resolve, reject) => {
      if (!type) {
        return reject(new Error(`Erreur paramètre : Type non valide`))
      }

      return GET(`instances/${getApiType(type)}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
