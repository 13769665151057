import { api } from '@/plugins'
import {
  GET_INSTANCES,
  GET_INSTANCES_SUCCESS,
  GET_INSTANCES_FAIL
} from './mutations'

export default {
  list ({ commit }, type) {
    return new Promise((resolve, reject) => {
      commit(GET_INSTANCES)

      api
        .GET('instance/list', type)
        .then(async datas => {
          await commit(GET_INSTANCES_SUCCESS, datas)
          resolve(datas)
        })
        .catch(e => {
          commit(GET_INSTANCES_FAIL)
          reject(e)
        })
    })
  }
}
