import { store } from '@/plugins'
import POST from './api/post'

export default {
  onCreate(api, { HTTP }) {
    HTTP.interceptors.request.use(
      (config) => {
        const token = store.getters['auth/token']
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  POST
}
