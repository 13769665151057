export default {
  deleteLigne: ({ DELETE }, ligne) =>
    new Promise((resolve, reject) => {
      if (!ligne.getCaisseId && !ligne.getCaisseId()) {
        return reject(new Error(`Erreur paramètre : caisse ID non valide`))
      }
      if (!ligne.getId && !ligne.getId()) {
        return reject(new Error(`Erreur paramètre : caisse ligne ID non valide`))
      }
      return DELETE(`caisses/${ligne.getCaisseId()}/lignes/${ligne.getId()}`)
        .then(reponse => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
