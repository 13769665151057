import AbstractModel, { CALLBACK_SUBDOC_ID } from '../abstract/abstract.model'

import { STATE_WAITING } from './cheque.data'
class Cheque extends AbstractModel {
  constructor (data) {
    super(
      data,
      [
        'id',
        'collaborateur',
        'nom',
        'prenom',
        'contrat',
        'echeance',
        'envoi',
        'montant',
        {
          field: 'banque',
          callBack: CALLBACK_SUBDOC_ID
        },
        'numero',
        {
          field: 'state',
          default: STATE_WAITING
        },
        'commentaire',
        {
          field: 'archive',
          default: false,
          callBack: value => !!value
        }
      ],
      ['collaborateur']
    )
  }
}

export default Cheque
