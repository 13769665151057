<template>
  <div v-if="routerInit" id="app">
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from './components/dashboard/dashboard'

export default {
  name: 'App',
  components: {
    Dashboard
  },
  computed: {
    routerInit() {
      return this.$store.getters['router/initiated']
    }
  },
  mounted() {
    // Navigation guard
    const $router = this.$router
    $(document).on('click', '.router-link-raw', function (e) {
      e.preventDefault()
      e.stopPropagation()
      $router.push($(this).attr('href'))
    })
    $(document).on('click', '.router-link-to', function (e) {
      e.preventDefault()
      e.stopPropagation()
      $router.push(JSON.parse($(this).attr('to')))
    })
  }
}
</script>
