import Ligne from '../models/ligne.model'

export default {
  lignes: state => state.lignes,
  ligneById: state => id => {
    const ligne = state.lignes
      ? state.lignes.find(ligne => ligne.id === id)
      : null
    return ligne ? new Ligne(ligne) : null
  },
  lignes_pending: state => state.lignes_pending
}
