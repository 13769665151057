<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div
      v-for="notification in notificationsSorted"
      :key="notification.id"
      class="notification"
      :class="{
        unread: getState(notification) === 'NON_TRAITE'
      }"
    >
      <div class="notification-text">
        <span class="notification-title">
          {{ getNom(notification) }}
        </span>
        <span
          class="notification-description"
          v-html="getDescription(notification)"
        >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_STATE } from '@/modules/notification/notification.data'

export default {
  name: 'NotificationTeletransmissionList',
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      NOTIFICATION_STATE,
      pending: false
    }
  },
  computed: {
    notificationsSorted() {
      const notifs = [...this.notifications]
      return notifs.sort(
        (n1, n2) =>
          parseInt(n1?.data?.teletransmission?.contrat?.dateEffet) -
          parseInt(n2?.data?.teletransmission?.contrat?.dateEffet)
      )
    }
  },
  methods: {
    getState(notification) {
      return notification?.data?.teletransmission?.state ?? ''
    },
    getNom(notification) {
      return notification?.data?.teletransmission?.contrat?.nom ?? ''
    },
    getDescription(notification) {
      return notification?.data?.teletransmission?.contrat?.description ?? ''
    }
  }
}
</script>
