import { api } from '@/plugins'
import {
  GET_COMMISSIONS,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FAIL,
  REFRESH_COMMISSIONS,
  REFRESH_COMMISSIONS_SUCCESS,
  REFRESH_COMMISSIONS_FAIL
} from './mutations'
import { parseNumber } from '@/plugins/utils'

export default {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_COMMISSIONS)

      api
        .GET('production/commissions/list', data)
        .then(async (datas) => {
          await commit(GET_COMMISSIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_COMMISSIONS_FAIL)
          reject(e)
        })
    })
  },
  listAgence({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_COMMISSIONS)

      api
        .GET('production/commissions/listAgence', data)
        .then(async (datas) => {
          await commit(GET_COMMISSIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_COMMISSIONS_FAIL)
          reject(e)
        })
    })
  },
  refresh({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit(REFRESH_COMMISSIONS)

      const query = {
        annee: data?.annee
      }

      const modifieds = state.commissions
        .filter((c) => c.agence !== true)
        .map((c) => parseNumber(c.modified))

      const lastModified = modifieds.length > 0 && Math.max(...modifieds)

      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('production/commissions/list', query)
        .then(async (datas) => {
          await commit(REFRESH_COMMISSIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_COMMISSIONS_FAIL)
          reject(e)
        })
    })
  },
  refreshAgence({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit(REFRESH_COMMISSIONS)

      const query = {
        annee: data?.annee
      }

      const modifieds = state.commissions
        .filter((c) => c.agence === true)
        .map((c) => parseNumber(c.modified))

      const lastModified = modifieds.length > 0 && Math.max(...modifieds)

      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('production/commissions/listAgence', query)
        .then(async (datas) => {
          await commit(REFRESH_COMMISSIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_COMMISSIONS_FAIL)
          reject(e)
        })
    })
  }
}
