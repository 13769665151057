// Plugins
import Vue from 'vue'
import { router, store, api } from '@/plugins'
import { loadScripts } from './plugins/assets'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import App from './App'

// Component
require('./components/elements')

Vue.use(VueMoment, {
  moment
})

// Start (une fois les scripts chargés)
loadScripts()
  .then(() => {
    new Vue({
      el: '#app',
      router,
      store,
      api,
      moment,
      data: {
        reloadRouterKey: 0
      },
      render: (h) => h(App)
    })
  })
  .catch((e) => {
    // eslint-disable-next-line no-console
    console.error('ERROR: chargement scripts', e)
  })
