import AbstractModel from '../../abstract/abstract.model'
import { compareStr } from '@/plugins/utils'

import Contact from './contact.model'

import { api } from '@/plugins'

let idTemp = 0

class Cabinet extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      {
        field: 'nom',
        default: ''
      },
      'adresse',
      'cp',
      'ville',
      'villeId',
      'email',
      'telephone',
      {
        field: 'contacts',
        default: [],
        callBack: (values) => values.map((value) => new Contact(value))
      }
    ])
  }

  getContacts() {
    return this.getProperty('contacts', [])
  }

  addContact(contact) {
    if (contact.isNew()) {
      contact.__idTemp = `tmp${++idTemp}`
    }

    const contacts = this.getContacts()
    contacts.push(contact)
    this.setProperty('contacts', contacts)
  }

  updateContact(contact, id) {
    this.removeContact(id)
    this.addContact(contact)
  }

  removeContactOrRole(id, role) {
    const contact = this.getContact(id)
    const roles = contact.getRoles()

    // si le contact a plus de 1 role, on se contente de retirer le role
    if (role && roles.length > 1) {
      contact.removeRole(role)
    } else {
      // si le contact n'a qu'un role, on supprime le contact
      this.removeContact(id)
    }
  }

  removeContact(id) {
    const contacts = this.getContacts()
    this.setProperty(
      'contacts',
      contacts.filter(
        (contact) =>
          !compareStr(contact.getId())(id) && !compareStr(contact.__idTemp)(id)
      )
    )
  }

  getContact(id) {
    return this.getContacts().find(
      (contact) =>
        compareStr(contact.getId())(id) || compareStr(contact.__idTemp)(id)
    )
  }

  save() {
    if (this.isNew()) {
      return api.POST('cabinet/add', this).then((cabinet) => {
        M.toast({
          html: `Le cabinet a été ajouté.`,
          classes: 'green'
        })

        this.resetModelRef()
        return cabinet
      })
    } else {
      return api.PUT('cabinet/edit', this).then((cabinet) => {
        M.toast({
          html: `Le cabinet a été modifié.`,
          classes: 'green'
        })

        this.resetModelRef()
        return cabinet
      })
    }
  }

  delete() {
    return api.DELETE('cabinet/delete', this).then(() => {
      M.toast({
        html: `Le cabinet a été supprimé.`,
        classes: 'green'
      })
    })
  }
}

export default Cabinet
