/* eslint-disable camelcase */
class ConfigCollaborateur {
  constructor(data = {}) {
    const { id, collaborateurId, annee, objectif, modified } = data

    this.id = id
    this.collaborateurId = collaborateurId
    this.annee = annee
    this.objectif = objectif
    this.modified = modified
  }

  isNew() {
    return !this.id
  }
}

export default ConfigCollaborateur
