import Model from '../models/agence.model'
import AgenceRole from '../models/role.model'
import { compareStr } from '@/plugins/utils'

export default {
  agences: (state) => state.models,
  agenceById: (state) => (id) => {
    const model = state.models
      ? state.models.find((model) => compareStr(model.id)(id))
      : null
    return model ? new Model(model) : null
  },
  agences_pending: (state) => state.models_pending,
  collaborateurs: (state) => state.collaborateurs,
  collaborateurById: (state) => (id) => {
    const model = state.collaborateurs
      ? state.collaborateurs.find((model) =>
          compareStr(model.collaborateurId)(id)
        )
      : null
    return model ? new AgenceRole(model) : null
  },
  collaborateurs_pending: (state) => state.collaborateurs_pending
}
