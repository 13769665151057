import Model from '../cheque.model'

export default {
  add: ({ POST }, cheque) =>
    new Promise((resolve, reject) => {
      if (!(cheque instanceof Model)) {
        return reject(new Error(
          `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${cheque.constructor.name}"`
        ))
      }
      return POST(`cheques`, cheque)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
