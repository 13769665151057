import Model from '../ville.model'

export default {
  edit: ({ PUT }, ville) =>
    new Promise((resolve, reject) => {
      if (!(ville instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : Ville attendu mais reçu "${ville.constructor.name}"`
          )
        )
      } else if (ville.isNew()) {
        return reject(new Error(`Erreur paramètre : Ville is new`))
      }

      return PUT(`donnees/villes/${ville.getId()}`, ville)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const ville = new Model(data)
            resolve(ville)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
