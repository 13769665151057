import { api } from '@/plugins'
import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_COLLABORATEURS,
  GET_COLLABORATEURS_SUCCESS,
  GET_COLLABORATEURS_FAIL
} from './mutations'

export default {
  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_USER)

      api
        .GET('user/me')
        .then(async (user) => {
          await commit(GET_USER_SUCCESS, user)
          resolve(user)
        })
        .catch((e) => {
          commit(GET_USER_FAIL)
          reject(e)
        })
    })
  },
  collaborateurs({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_COLLABORATEURS)

      api
        .GET('user/collaborateurs')
        .then(async (users) => {
          await commit(GET_COLLABORATEURS_SUCCESS, users)
          resolve(users)
        })
        .catch((e) => {
          commit(GET_COLLABORATEURS_FAIL)
          reject(e)
        })
    })
  }
}
