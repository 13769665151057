export const GET_COMMISSIONS = 'GET_COMMISSIONS'
export const GET_COMMISSIONS_SUCCESS = 'GET_COMMISSIONS_SUCCESS'
export const GET_COMMISSIONS_FAIL = 'GET_COMMISSIONS_FAIL'
export const REFRESH_COMMISSIONS = 'REFRESH_COMMISSIONS'
export const REFRESH_COMMISSIONS_SUCCESS = 'REFRESH_COMMISSIONS_SUCCESS'
export const REFRESH_COMMISSIONS_FAIL = 'REFRESH_COMMISSIONS_FAIL'

export default {
  [GET_COMMISSIONS](state) {
    state.commissions_pending = true
  },
  [GET_COMMISSIONS_SUCCESS](state, commissions) {
    state.commissions = commissions
    state.commissions_pending = false
  },
  [GET_COMMISSIONS_FAIL](state) {
    state.commissions_pending = false
  },
  [REFRESH_COMMISSIONS](state) {
    state.commissions_refresh_pending = true
  },
  [REFRESH_COMMISSIONS_SUCCESS](state, commissions) {
    const ids = commissions.map((commission) => commission.id)
    state.commissions = state.commissions.filter(
      (commission) => !ids.includes(commission.id)
    )
    state.commissions.push(...commissions)

    state.commissions_refresh_pending = false
  },
  [REFRESH_COMMISSIONS_FAIL](state) {
    state.commissions_refresh_pending = false
  }
}
