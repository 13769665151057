import produit from './produit/produit.api'
import configuration from './configuration/configuration.api'
import contrat from './contrat/contrat.api'
import avenant from './avenant/avenant.api'
import commission from './commission/commission.api'
import surcommission from './surcommission/surcommission.api'
import prime from './prime/prime.api'
import configCollaborateur from './configCollaborateur/configCollaborateur.api'

export default {
  namespaced: true,
  modules: {
    produits: produit,
    configurations: configuration,
    contrats: contrat,
    avenants: avenant,
    commissions: commission,
    primes: prime,
    surcommissions: surcommission,
    configCollaborateurs: configCollaborateur
  }
}
