export const ROLE_COLLABORATEUR = 'ROLE_COLLABORATEUR'
export const ROLE_SUPERVISEUR_DEVIS = 'ROLE_SUPERVISEUR_DEVIS'
export const ROLE_SUPERVISEUR_INSTANCE = 'ROLE_SUPERVISEUR_INSTANCE'

export const roles = [
  // {
  //   id: ROLE_COLLABORATEUR,
  //   text: 'Collaborateur'
  // },
  {
    id: ROLE_SUPERVISEUR_DEVIS,
    text: 'Superviseur DEVIS'
  },
  {
    id: ROLE_SUPERVISEUR_INSTANCE,
    text: 'Superviseur INSTANCE'
  }
]
