import Model from '../commission.model'
import { compareStr } from '@/plugins/utils'

export default {
  commissions: (state) => state.commissions,
  commissionById: (state) => (id) => {
    const model = state.commissions
      ? state.commissions.find((model) => compareStr(model.id)(id))
      : null
    return model ? new Model(model) : null
  },
  commissionsByCollabId: (state) => (id) =>
    state.commissions.filter((c) => compareStr(c.collaborateurId)(id)),
  commissionsByGroup: (state) => (group) => {
    let result = []
    group.membres.forEach((id) => {
      result = result.concat(
        state.commissions.filter((c) => compareStr(c.collaborateurId)(id))
      )
    })
    return result.map((model) => (model ? new Model(model) : null))
  },
  commissions_pending: (state) => state.commissions_pending,
  commissions_refresh_pending: (state) => state.commissions_refresh_pending
}
