import AbstractModel, { CALLBACK_NUMBER } from '../../abstract/abstract.model'

class FicheCabinet extends AbstractModel {
  constructor(data) {
    super(data, [
      'nom',
      {
        field: 'date_cloture',
        callBack: CALLBACK_NUMBER
      },
      'nom_expert',
      'nom_responsable_social',
      'nom_responsable_fiscal',
      'adresse',
      'telephone',
      'email',
      'cabinetId',
      {
        field: 'contacts',
        default: []
      }
    ])
  }

  modifNeeded() {
    return [
      this.getProperty('nom_expert', ''),
      this.getProperty('adresse', ''),
      this.getProperty('telephone'),
      this.getProperty('nom_responsable_social', ''),
      this.getProperty('nom_responsable_fiscal', '')
    ].some((v) => v && String(v).trim())
  }
}

export default FicheCabinet
