import Model from '../models/agence.model'

export default {
  get: ({ GET }, { idAgence }) =>
    new Promise((resolve, reject) => {
      if (!idAgence) {
        return reject(new Error(`Erreur paramètre : agence ID non valide`))
      }
      return GET(`agences/${idAgence}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`agences`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  collaborateurs: ({ GET }, idAgence) =>
    new Promise((resolve, reject) => {
      return GET(`agences/${idAgence}/collaborateurs`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
