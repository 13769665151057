import DELETE from './api/delete'
import GET from './api/get'
import POST from './api/post'
import PUT from './api/put'
import configApi from './config/config.api'
import duedsnApi from './duedsn/duedsn.api'
import teletransmissionApi from './teletransmission/teletransmission.api'

export default {
  DELETE,
  GET,
  POST,
  PUT,
  modules: {
    configs: configApi,
    duedsn: duedsnApi,
    teletransmissions: teletransmissionApi
  }
}
