import { api } from '@/plugins'
import {
  GET_CONFIGURATIONS,
  GET_CONFIGURATIONS_SUCCESS,
  GET_CONFIGURATIONS_FAIL
} from './mutations'

export default {
  list ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_CONFIGURATIONS)

      api
        .GET('production/configurations/list', data)
        .then(async configurations => {
          await commit(GET_CONFIGURATIONS_SUCCESS, configurations)
          resolve(configurations)
        })
        .catch(e => {
          commit(GET_CONFIGURATIONS_FAIL)
          reject(e)
        })
    })
  }
}
