import { assert, forEachValue, isPromise, isObject } from '../../utils'
import ModuleCollection from './moduleCollection'

function unifyObjectStyle(type, data, options) {
  if (isObject(type) && type.type) {
    options = data
    data = type
    type = type.type
  }

  assert(
    typeof type === 'string',
    'expects string as the type, but found ' + typeof type + '.'
  )

  return { type: type, data: data, options: options }
}

export default class ModuleManager {
  apps = []

  constructor(options = {}, _actions = {}, _handlers = {}) {
    this._modules = new ModuleCollection(options)
    this._modulesNamespaceMap = {}
    this._actions = {}
    this.actions = _actions
    this.handlers = _handlers

    // Initialise des actions
    var actions = {}
    forEachValue(this.actions, function (action, key) {
      actions[key] = {}
    })
    this._actions = actions

    const state = this._modules.root.state
    this.installModule(this, state, [], this._modules.root)
  }

  getModules() {
    return this._modules
  }

  getModule(namespace = 'root') {
    return this._modules[namespace]
  }

  getModulesNamespace() {
    return this._modulesNamespaceMap
  }

  setHandlers(_handlers) {
    this.handlers = _handlers
  }

  registerModule(path, rawModule, options) {
    if (options === void 0) options = {}

    if (typeof path === 'string') {
      path = [path]
    }

    assert(Array.isArray(path), 'module path must be a String or an Array.')
    assert(
      path.length > 0,
      'cannot register the root module by using registerModule.'
    )

    this._modules.register(path, rawModule)

    this.installModule(this, this.state, path, this._modules.get(path))
  }

  unregisterModule(path) {
    if (typeof path === 'string') {
      path = [path]
    }

    assert(Array.isArray(path), 'module path must be a String or an Array.')

    this._modules.unregister(path)
  }

  installModule(manager, rootState, path, module) {
    const namespace = manager._modules.getNamespace(path)

    // register in namespace map
    manager._modulesNamespaceMap[namespace] = module

    const $registerMethod = this.registerMethod.bind(this)
    forEachValue(this.actions, function (action) {
      if (module._rawModule[action]) {
        forEachValue(module._rawModule[action], function (actions, key) {
          const namespacedType = namespace + key
          $registerMethod(manager, namespacedType, actions, action)
        })
      }
    })

    const $installModule = this.installModule.bind(this)
    module.forEachChild(function (child, key) {
      $installModule(manager, rootState, path.concat(key), child)
    })

    module.init(manager, manager.handlers)
  }

  registerMethod(manager, type, handler, action) {
    var entry =
      manager._actions[action][type] || (manager._actions[action][type] = [])
    entry.push(function (data) {
      var res = handler.call(manager, manager.handlers, data)

      if (!isPromise(res)) {
        res = Promise.resolve(res)
      }
      return res
    })
  }

  _callAction(action, _type, _data) {
    // check object-style commit
    var ref = unifyObjectStyle(_type, _data)
    var type = ref.type
    var data = ref.data

    var entry = this._actions[action][type]
    if (!entry) {
      // eslint-disable-next-line
      console.error(
        `[${this.constructor.name}] unknown action type: ${action} / ${type}`
      )

      return null
    }

    var result =
      entry.length > 1
        ? Promise.all(
            entry.map(function (handler) {
              return handler(data)
            })
          )
        : entry[0](data)

    return result
  }
}
