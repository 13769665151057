import AbstractModel, {
  CALLBACK_NUMBER,
  CALLBACK_SUBDOC_ID
} from '../../abstract/abstract.model'
import { situations } from '../fiche.data'

class FicheUser extends AbstractModel {
  constructor(data) {
    super(data, [
      'nom',
      'prenom',
      'titre',
      'societe',
      'vip',
      'courtage',
      'gp',
      'pgp',
      {
        field: 'naissance',
        callBack: CALLBACK_NUMBER
      },
      'naissance_villeId',
      'naissance_cp',
      'naissance_ville',
      'naissance_nom',
      'naissance_pays',
      'residence_pays',
      {
        field: 'adresse_conjoint',
        default: true
      },
      'adresse',
      'villeId',
      'cp',
      'ville',
      'telPro',
      'telPrive',
      'telMobile',
      'bloctel',
      'courriel',
      'courrielpro',
      'profession',
      {
        field: 'profession_secteur',
        callBack: CALLBACK_SUBDOC_ID
      },
      {
        field: 'profession_status',
        callBack: CALLBACK_SUBDOC_ID
      },
      {
        field: 'regime',
        callBack: CALLBACK_SUBDOC_ID
      },
      'n_secu',
      'nif',
      ['situation', 'celibataire'],
      {
        field: 'date_permis',
        callBack: CALLBACK_NUMBER
      },
      'loisirs',
      {
        field: 'mariage_regime',
        callBack: CALLBACK_SUBDOC_ID
      },
      'mariage_contrat',
      'mariage_donation',
      {
        field: 'mariage_date',
        callBack: CALLBACK_NUMBER
      },
      {
        field: 'pacse_date',
        callBack: CALLBACK_NUMBER
      },
      'pacse_regime',
      'situation_commentaire',
      {
        field: 'logement',
        callBack: CALLBACK_SUBDOC_ID
      },
      'logement_nb_handicap',
      'lien_parente'
    ])
  }

  hasConjoint = function () {
    const situation = this.getProperty('situation')
    return !!situations.find((s) => s.id === situation && s.hasConjoint)
  }
}

export default FicheUser
