import { api } from '@/plugins'
import {
  REFRESH_NOTIFICATIONS,
  REFRESH_NOTIFICATIONS_SUCCESS,
  REFRESH_NOTIFICATIONS_FAIL
} from './mutations'

export default {
  refresh({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit(REFRESH_NOTIFICATIONS)

      const query = {}

      const lastModified = state.lastModified
      if (lastModified) {
        query.modifiedAfter = lastModified
        query.withDeleted = true
      }

      api
        .GET('notification/list', query)
        .then(async (datas) => {
          await commit(REFRESH_NOTIFICATIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_NOTIFICATIONS_FAIL)
          reject(e)
        })
    })
  }
}
