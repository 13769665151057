export default {
  install (Vue) {
    Vue.mixin({
      mounted () {
        // toogle Fullscreen
        $(this.$el)
          .find('.toggle-fullscreen')
          .click(function () {
            toggleFullScreen()
          })
      }
    })
  }
}
