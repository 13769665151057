import Model from '../models/config.model'

export default {
  listConfig: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`production/surcommissions/configs`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach((modelData) => {
              models.push(new Model(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
