<template>
  <li>
    <a
      class="waves-effect waves-block waves-light header-button profile-button dropdown-menu"
      href="javascript:void(0);"
      data-target="profile-dropdown"
    >
      <i class="material-icons left">person</i>
      <span class="left">
        {{ user.nom }} {{ user.prenom }}
        <span v-if="agence">({{ agence }})</span>
      </span>
    </a>

    <ul id="profile-dropdown" class="dropdown-content">
      <template v-if="user">
        <li>
          <a
            is="router-link"
            class="grey-text text-darken-1"
            :to="'/user/profile'"
          >
            <i class="material-icons">person_outline</i> Mon profil
          </a>
        </li>
        <li class="divider" />
        <li>
          <a
            is="router-link"
            class="grey-text text-darken-1"
            :to="'/login'"
            @click.native="logout"
          >
            <i class="material-icons">power_settings_new</i> Déconnexion
          </a>
        </li>
      </template>
      <template v-else>
        <li>
          <a is="router-link" class="grey-text text-darken-1" :to="'/login'">
            <i class="material-icons">person_outline</i> Connexion
          </a>
        </li>
      </template>
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'User',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    agence() {
      return this.user._agence && this.user._agence.valeur
        ? this.user._agence.valeur
        : ''
    }
  },
  methods: {
    logout: function () {
      if (this.$route.name === 'login') {
        return
      }
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>
