export default {
  delete: ({ DELETE }, id) =>
    new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error(`Erreur paramètre : id non valide "${id}"`))
      }
      return DELETE(`production/contrats/${id}`)
        .then(reponse => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
