import { api } from '@/plugins'
import {
  GET_CONFIG_COLLABORATEURS,
  GET_CONFIG_COLLABORATEURS_SUCCESS,
  GET_CONFIG_COLLABORATEURS_FAIL,
  REFRESH_CONFIG_COLLABORATEURS_SUCCESS,
  REFRESH_CONFIG_COLLABORATEURS,
  REFRESH_CONFIG_COLLABORATEURS_FAIL
} from './mutations'

export default {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_CONFIG_COLLABORATEURS)

      api
        .GET('production/configCollaborateurs/list', data)
        .then(async (configCollaborateurs) => {
          await commit(GET_CONFIG_COLLABORATEURS_SUCCESS, configCollaborateurs)
          resolve(configCollaborateurs)
        })
        .catch((e) => {
          commit(GET_CONFIG_COLLABORATEURS_FAIL)
          reject(e)
        })
    })
  },
  refresh({ commit, state }) {
    const lastModified = state.lastModified
    return new Promise((resolve, reject) => {
      commit(REFRESH_CONFIG_COLLABORATEURS)

      const query = {}
      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('production/configCollaborateurs/list', query)
        .then(async (datas) => {
          await commit(REFRESH_CONFIG_COLLABORATEURS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_CONFIG_COLLABORATEURS_FAIL)
          reject(e)
        })
    })
  }
}
