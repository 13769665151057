export const GET_SURCOMMISSION_CONFIG = 'GET_SURCOMMISSION_CONFIG'
export const GET_SURCOMMISSION_CONFIG_SUCCESS =
  'GET_SURCOMMISSION_CONFIG_SUCCESS'
export const GET_SURCOMMISSION_CONFIG_FAIL = 'GET_SURCOMMISSION_CONFIG_FAIL'

export default {
  [GET_SURCOMMISSION_CONFIG](state) {
    state.models_pending = true
  },
  [GET_SURCOMMISSION_CONFIG_SUCCESS](state, configs) {
    state.configs = configs
    state.models_pending = false
  },
  [GET_SURCOMMISSION_CONFIG_FAIL](state) {
    state.models_pending = false
  }
}
