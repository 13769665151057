import { getStorage } from '@/plugins/Store/locationStorage'
import { STORE_TOKEN } from './storage'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_PARTIAL = 'LOGIN_PARTIAL'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export default {
  [LOGIN](state) {
    state.pending = true
  },
  [LOGIN_PARTIAL](state) {
    state.pending = false
    state.token = getStorage().getItem(STORE_TOKEN)
  },
  [LOGIN_SUCCESS](state) {
    state.pending = false
    state.token = getStorage().getItem(STORE_TOKEN)
  },
  [LOGIN_FAIL](state) {
    state.pending = false
  },
  [LOGOUT](state) {
    state.user = null
    state.token = null
  }
}
