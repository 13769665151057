export const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS'
export const GET_CONFIGURATIONS_SUCCESS = 'GET_CONFIGURATIONS_SUCCESS'
export const GET_CONFIGURATIONS_FAIL = 'GET_CONFIGURATIONS_FAIL'

export default {
  [GET_CONFIGURATIONS] (state) {
    state.configurations_pending = true
  },
  [GET_CONFIGURATIONS_SUCCESS] (state, configurations) {
    state.configurations = configurations
    state.configurations_pending = false
  },
  [GET_CONFIGURATIONS_FAIL] (state) {
    state.configurations_pending = false
  }
}
