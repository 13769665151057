import AbstractModel from '../abstract/abstract.model'

class Recommandation extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'collaborateurId',
      'ficheId',
      'societe',
      'activite',
      'nom',
      'prenom',
      'fonction',
      'telephone',
      'courriel',
      'dateRelance',
      'commentaire',
      'nContrat',
      'nClient',
      'nCheque',
      'montant',
      'dateEffet',
      'dateEnvoi',
      {
        field: 'archive',
        default: false,
        callBack: (value) => !!value
      }
    ])
  }
}

export default Recommandation
