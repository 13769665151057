<template>
  <footer
    class="page-footer footer footer-static footer-light navbar-border navbar-shadow"
  >
    <div class="footer-copyright">
      <div class="container">
        <span>Version: {{ version }}</span>
        <span class="right">
          &copy; {{ $moment().format('YYYY') }}
          <a href="https://www.torop.net/" target="_blank"> Torop.net </a>
          Tous droits réservés
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { version } from '@/../package.json'

export default {
  name: 'Footer',
  data() {
    return { version }
  }
}
</script>
