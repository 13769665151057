<template>
  <div>
    <div
      v-for="notification in notificationsSorted"
      :key="notification.id"
      class="notification"
      :class="{
        unread: NOTIFICATION_STATE.UNREAD === notification.state
      }"
    >
      <div class="notification-text">
        <span class="notification-title">
          {{ notification.title }}
        </span>
        <span class="notification-description">
          Date de relance:
          {{
            getDateRelance(notification) &&
            $moment(getDateRelance(notification)).format('DD/MM/YYYY')
          }}
        </span>
      </div>

      <div class="notification-actions">
        <router-link
          title="Voir l'instance"
          class="notification-action notification-read blue-text dropdown-close"
          :to="gotoInstance(notification)"
        >
          <i class="material-icons">notifications</i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_STATE } from '@/modules/notification/notification.data'

export default {
  name: 'NotificationInstanceList',
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      NOTIFICATION_STATE
    }
  },
  computed: {
    notificationsSorted() {
      const notifs = [...this.notifications]
      return notifs.sort((n1, n2) =>
        this.$moment(this.getDateRelance(n2)).isAfter(
          this.$moment(this.getDateRelance(n1))
        )
          ? 1
          : -1
      )
    }
  },
  methods: {
    gotoInstance(notification) {
      const instanceData = notification.data?.instance ?? {}
      const key =
        Object.keys(instanceData)
          .filter((key) => key.includes('Instance_'))
          .shift() ?? ''
      const type = key.replace('Instance_', '').toLowerCase()
      const id = instanceData?.[key]?.id ?? ''

      return {
        name: `instance.${type}`,
        params: {
          id
        }
      }
    },
    getDateRelance(notification) {
      const instanceData = notification.data?.instance ?? {}
      const key =
        Object.keys(instanceData)
          .filter((key) => key.includes('Instance_'))
          .shift() ?? ''
      return instanceData?.[key]?.dateRelance ?? 0
    }
  }
}
</script>
