import { getApiType } from '../instance.data'

export default {
  delete: ({ DELETE }, model) =>
    new Promise((resolve, reject) => {
      if (!model || !model.getId()) {
        return reject(new Error(`Erreur paramètre : ID non valide`))
      }
      return DELETE(`instances/${getApiType(model.getType())}/${model.getId()}`)
        .then(reponse => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
