export default {
  user: (state) => state.user,
  isAllowed: (state) => (grp, ignoreAdmin) =>
    state.user ? state.user.isAllowed(grp, ignoreAdmin) : false,
  isTorop: (state) => state.user && state.user.login === 'torop',
  collaborateurs: (state) => state.collaborateurs,
  collaborateurs_pending: (state) => state.collaborateurs_pending,
  collaborateurById: (state) => (id) =>
    state.collaborateurs.find((collab) => collab.id === id)
}
