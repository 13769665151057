export const situations = [
  {
    id: 'celibataire',
    text: 'Célibataire'
  },
  {
    id: 'marie',
    text: 'Marié',
    hasConjoint: true
  },
  {
    id: 'pacse',
    text: 'Pacsé',
    hasConjoint: true
  },
  {
    id: 'concubin',
    text: 'Concubin',
    hasConjoint: true
  },
  {
    id: 'divorce',
    text: 'Divorcé'
  },
  {
    id: 'veuf',
    text: 'Veuf'
  },
  {
    id: 'autre',
    text: 'Autre',
    hasConjoint: true
  }
]

export const impositions = [
  {
    id: 'ir',
    text: 'IR'
  },
  {
    id: 'is',
    text: 'IS'
  }
]

export const entrees = [
  {
    id: 'professionnels',
    text: 'Revenus professionnels et assimilés'
  },
  {
    id: 'immobiliers',
    text: 'Revenus immobiliers'
  },
  {
    id: 'mobiliers',
    text: 'Revenus mobiliers'
  },
  {
    id: 'prestations',
    text: 'Prestations familiales'
  },
  {
    id: 'pensions',
    text: 'Pensions alimentaires'
  },
  {
    id: 'autres',
    text: 'Autres'
  }
]

export const sorties = [
  {
    id: 'etat',
    values: [
      {
        id: 'impot_revenu',
        text: 'Impôts sur le revenu'
      },
      {
        id: 'csg_crds_non_deductible',
        text: 'CSG, CRDS non déductible'
      },
      {
        id: 'csg_crds_deductible',
        text: 'CSG, CRDS déductible'
      },
      {
        id: 'taxe_habitation',
        text: "Taxe d'habitation, foncière"
      },
      {
        id: 'ifi',
        text: 'Impôts sur la fortune immobilière IFI'
      }
    ]
  },
  {
    id: 'depenses_familiales',
    text: 'Dépenses familiales',
    values: [
      {
        id: 'vie',
        text: 'Vie quotidienne (EDF, tél, transport...)'
      },
      {
        id: 'pensions',
        text: 'Pensions alimentaires/rentes'
      }
    ]
  }
]

export const patrimoines = [
  {
    id: 'patrimoine',
    text: 'Estimations',
    values: [
      {
        id: 'residence_principale',
        text: 'Résidence principale'
      },
      {
        id: 'residence_secondaire',
        text: 'Résidences secondaires'
      },
      {
        id: 'biens_pro',
        text: 'Bien(s) professionnel'
      },
      {
        id: 'immeuble_rapport',
        text: 'Immeuble de rapport'
      }
    ]
  }
]

export const situationsEnfant = [
  {
    id: 'enfant_commun',
    text: 'Enfant commun'
  },
  {
    id: 'enfant_client',
    text: 'Enfant du client'
  },
  {
    id: 'enfant_conjoint',
    text: 'Enfant du conjoint'
  },
  {
    id: 'enfant_adopte',
    text: 'Enfant adopté'
  }
]

export const adoptions = [
  {
    id: 'adoption_simple',
    text: 'Adoption simple'
  },
  {
    id: 'adoption_pleniere',
    text: 'Adoption plénière'
  }
]
