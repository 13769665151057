import produit from './produit/produit.store'
import configuration from './configuration/configuration.store'
import contrat from './contrat/contrat.store'
import avenant from './avenant/avenant.store'
import commission from './commission/commission.store'
import surcommission from './surcommission/surcommission.store'
import prime from './prime/prime.store'
import configCollaborateur from './configCollaborateur/configCollaborateur.store'

export default {
  namespaced: true,
  modules: {
    produits: produit,
    configurations: configuration,
    contrats: contrat,
    avenants: avenant,
    commissions: commission,
    primes: prime,
    surcommissions: surcommission,
    configCollaborateurs: configCollaborateur
  }
}
