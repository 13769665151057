import Model from '../models/notification.model'
import { NOTIFICATION_STATE } from '../notification.data'

export default {
  state: ({ POST }, { model, state }) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      }

      if (!Object.values(NOTIFICATION_STATE).includes(state)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Object.keys(NOTIFICATION_STATE).join(
              ' | '
            )} attendu mais reçu "${state}"`
          )
        )
      }

      return POST(
        `notifications/${model.getId()}/${String(state).toLocaleLowerCase()}`,
        model
      )
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
