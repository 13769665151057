import Model from '../fichier.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`fichiers/${id}`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    }),
  gets: ({ GET }, ids) =>
    new Promise((resolve, reject) => {
      if (!ids) {
        reject(new Error(`Erreur paramètre : ids non valide "${ids}"`))
      }
      return GET(`fichiers/ids/${ids}`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            data.map(d => new Model(d))
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
