import moment from 'moment'
import { parseNumber } from '@/plugins/utils'

export const dateToTimestamp = (date) => moment(date).format('X')

export const trimestresConfigs = [
  {
    id: 1,
    debut: { day: 21, month: 11, subYear: 1 },
    fin: { day: 21, month: 2 }
  },
  {
    id: 2,
    debut: { day: 21, month: 2 },
    fin: { day: 21, month: 5 }
  },
  {
    id: 3,
    debut: { day: 21, month: 5 },
    fin: { day: 21, month: 8 }
  },
  {
    id: 4,
    debut: { day: 21, month: 8 },
    fin: { day: 21, month: 11 }
  },
  {
    id: 'all',
    debut: { day: 21, month: 11, subYear: 1 },
    fin: { day: 21, month: 11 }
  }
]

export const getTrimestreIntervalle = (_trimestre) => (_annee) => {
  const trimestreId = _trimestre !== 'all' ? parseNumber(_trimestre) : 'all'
  const trimestre = trimestresConfigs.find((t) => t.id === trimestreId)
  const annee = parseNumber(_annee || moment().format('YYYY'))

  if (!trimestre) {
    throw new Error(`Trimestre "${_trimestre}" : NOT_FOUND`)
  }

  const debutVar = {
    year: annee + parseNumber(trimestre.debut.year),
    month: parseNumber(trimestre.debut.month),
    day: parseNumber(trimestre.debut.day),
    hour: parseNumber(trimestre.debut.hour),
    minute: parseNumber(trimestre.debut.minute),
    second: parseNumber(trimestre.debut.second)
  }
  const debut = moment(debutVar)

  const finVar = {
    year: annee + parseNumber(trimestre.fin.year),
    month: parseNumber(trimestre.fin.month),
    day: parseNumber(trimestre.fin.day),
    hour: parseNumber(trimestre.fin.hour),
    minute: parseNumber(trimestre.fin.minute),
    second: parseNumber(trimestre.fin.second)
  }
  const fin = moment(finVar)

  if (!debut.isValid() || !fin.isValid()) {
    return
  }

  if (trimestre.debut.subYear) {
    debut.subtract(trimestre.debut.subYear, 'y')
  }
  fin.subtract(1, 's')

  return {
    debut,
    fin,
    trimestre,
    annee
  }
}

export const getTrimestre1 = getTrimestreIntervalle(1)
export const getTrimestre2 = getTrimestreIntervalle(2)
export const getTrimestre3 = getTrimestreIntervalle(3)
export const getTrimestre4 = getTrimestreIntervalle(4)
export const getTrimestres = getTrimestreIntervalle('all')

export function getTrimestreByDate(date) {
  const intervalle = getTrimestreIntervalleByDate(date)
  return intervalle && intervalle.trimestre
}
export function getTrimestreIntervalleByDate(date) {
  const mDate = moment(date)
  const annee = mDate.year()

  const getTrimestreByYear = (annee) =>
    [
      getTrimestre1(annee),
      getTrimestre2(annee),
      getTrimestre3(annee),
      getTrimestre4(annee)
    ].find((t) => {
      return t.debut.isSameOrBefore(mDate) && t.fin.isSameOrAfter(mDate)
    })
  const trimestreIntervalle = getTrimestreByYear(annee)

  if (trimestreIntervalle) {
    return trimestreIntervalle
  } else {
    const month = mDate.month()
    if (month === 11) {
      return getTrimestreByYear(annee + 1)
    }
  }
}

export const isInTrimestre = (_trimestre) => (_annee) => (mDate) => {
  const intervalle = getTrimestreIntervalle(_trimestre)(_annee)
  return mDate.isBetween(intervalle.debut, intervalle.fin, undefined, '[]')
}
