import { api } from '@/plugins'
import {
  GET_FICHES,
  GET_FICHES_SUCCESS,
  GET_FICHES_FAIL,
  REFRESH_FICHES,
  REFRESH_FICHES_SUCCESS,
  REFRESH_FICHES_FAIL,
  ON_DIRTY_SET,
  REMOVE_LOCAL_FICHE
} from './mutations'

export default {
  list({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(GET_FICHES)

      api
        .GET('fiche/list', data)
        .then(async (datas) => {
          if (!Array.isArray(datas)) {
            commit(GET_FICHES_FAIL)
            reject(new Error('Invalid response from server'))
            return
          }

          await commit(GET_FICHES_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_FICHES_FAIL)
          reject(e)
        })
    })
  },
  refresh({ commit, state }) {
    const lastModified = state.lastModified
    return new Promise((resolve, reject) => {
      commit(REFRESH_FICHES)

      const query = {}
      if (lastModified) {
        query.modifiedAfter = lastModified
      }

      api
        .GET('fiche/list', query)
        .then(async (datas) => {
          await commit(REFRESH_FICHES_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_FICHES_FAIL)
          reject(e)
        })
    })
  },
  removeLocal({ commit }, id) {
    commit(REMOVE_LOCAL_FICHE, id)
  },
  async setDirty({ commit }, data) {
    await commit(ON_DIRTY_SET, !!data)
    return !!data
  }
}
