import Model from '../models/config.model'
import ConfigCollab from '../models/config-collab.model'

export default {
  listConfig: ({ GET }) =>
    new Promise((resolve, reject) => {
      return GET(`production/primes/configs`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach((modelData) => {
              models.push(new Model(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  getConfigCollab: ({ GET }, { collaborateurId, annee, trimestre }) =>
    new Promise((resolve, reject) => {
      if (!collaborateurId) {
        return reject(
          new Error(`Erreur paramètre : "collaborateurId" non saisi`)
        )
      }
      if (!annee) {
        return reject(new Error(`Erreur paramètre : "annee" non saisi`))
      }
      if (!trimestre) {
        return reject(new Error(`Erreur paramètre : "trimestre" non saisi`))
      }

      return GET(`production/primes/${collaborateurId}/${annee}/${trimestre}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            return resolve(new ConfigCollab(data))
          } else {
            return reject(reponse.status)
          }
        })
        .catch((error) => {
          const { response } = error
          if (response.status === 404) {
            return resolve(
              new ConfigCollab({ collaborateurId, annee, trimestre })
            )
          }
          reject(error)
        })
    })
}
