import AbstractModel from '../abstract/abstract.model'

class Resiliation extends AbstractModel {
  constructor (data) {
    super(
      data,
      [
        'id',
        'collaborateur',
        'agence',
        'nom',
        'prenom',
        'numeroContrat',
        {
          field: 'typesContrat',
          default: []
        },
        {
          field: 'typesContratIds',
          default: [],
          callBack: (_, datas) =>
            (datas.typesContrat || []).map(contrat => contrat.id)
        },
        'dateResiliation',
        'motif',
        'commentaire',
        {
          field: 'antichute',
          default: false,
          callBack: value => !!value
        }
      ],
      ['collaborateur', 'agence', 'motif']
    )
  }
}

export default Resiliation
