export const GET_AVENANTS = 'GET_AVENANTS'
export const GET_AVENANTS_SUCCESS = 'GET_AVENANTS_SUCCESS'
export const GET_AVENANTS_FAIL = 'GET_AVENANTS_FAIL'
export const REFRESH_AVENANTS = 'REFRESH_AVENANTS'
export const REFRESH_AVENANTS_SUCCESS = 'REFRESH_AVENANTS_SUCCESS'
export const REFRESH_AVENANTS_FAIL = 'REFRESH_AVENANTS_FAIL'

export default {
  [GET_AVENANTS](state) {
    state.avenants_pending = true
  },
  [GET_AVENANTS_SUCCESS](state, avenants) {
    state.avenants = avenants
    state.avenants_pending = false
  },
  [GET_AVENANTS_FAIL](state) {
    state.avenants_pending = false
  },

  [REFRESH_AVENANTS](state) {
    state.avenants_refresh_pending = true
  },
  [REFRESH_AVENANTS_SUCCESS](state, avenants) {
    const ids = avenants.map((avenant) => avenant.id)
    state.avenants = state.avenants.filter(
      (avenant) => !ids.includes(avenant.id)
    )
    state.avenants.push(...avenants)

    state.lastModified = state.avenants.reduce((max, avenant) => {
      return avenant.modified > max ? avenant.modified : max
    }, 0)

    state.avenants_refresh_pending = false
  },
  [REFRESH_AVENANTS_FAIL](state) {
    state.avenants_refresh_pending = false
  }
}
