<template>
  <li v-if="isAdmin">
    <a
      class="waves-effect waves-block waves-light sidenav-trigger"
      href="#"
      data-target="slide-out-right"
    >
      <i class="material-icons">settings</i>
    </a>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    isAdmin() {
      return this.user && this.user.isAdmin()
    }
  }
}
</script>
