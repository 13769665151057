<template>
  <div v-show="title" id="breadcrumbs-wrapper" ref="wrapper">
    <div class="container">
      <div class="row">
        <div class="col s12 m6 l6">
          <h5 class="breadcrumbs-title mt-0 mb-0">
            {{ title }}
          </h5>
        </div>
        <div v-if="items.length" class="col s12 m6 l6 right-align-md">
          <ol class="breadcrumbs mb-0">
            <li
              v-for="(item, index) in items"
              :key="index"
              class="breadcrumb-item"
            >
              <a
                is="router-link"
                v-if="getItemPath(item)"
                :to="getItemPath(item)"
                >{{ getTexteFn(item.label) }}</a
              >
              <a v-else>{{ getTexteFn(item.label) }}</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumbsConfigs from '@/configs/elements/breadcrumbs'
import { GROUP_ADMIN } from '@/configs/groups'
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumbs',
  computed: {
    ...mapGetters('user', ['user']),
    title() {
      const title = this.$route.meta.breadcrumbs
        ? this.$route.meta.breadcrumbs.title
        : null
      return this.getTexteFn(title)
    },
    items() {
      return this.$route.meta.breadcrumbs
        ? this.$route.meta.breadcrumbs.items
        : []
    },
    imageRoute() {
      return this.$route.meta.breadcrumbs
        ? this.$route.meta.breadcrumbs.background
        : null
    },
    isAdmin() {
      return !!(
        this.$route.meta.credentials &&
        this.$route.meta.credentials.includes(GROUP_ADMIN)
      )
    },
    imageDefault() {
      return this.isAdmin
        ? breadcrumbsConfigs.background.admin
        : breadcrumbsConfigs.background.default
    },
    currentPath() {
      return this.$route
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.drawImage()
    })
    this.drawImage()
  },
  methods: {
    getImageUrl() {
      const imageRoute = this.imageRoute
      if (imageRoute) {
        return imageRoute
      } else if (imageRoute !== false) {
        return this.imageDefault
      }
    },
    drawImage() {
      const imageUrl = this.getImageUrl()
      if (imageUrl) {
        $(this.$refs.wrapper).addClass('breadcrumbs-bg-image')
        $(this.$refs.wrapper).css('background-image', 'url(' + imageUrl + ')')
      }
    },
    getTexteFn(textFn) {
      return typeof textFn === 'function'
        ? textFn({ user: this.user, $this: this })
        : textFn
    },
    getItemPath(item) {
      const path = item.name ? { name: item.name } : item.path
      return path === 'CURRENT' ? this.currentPath : path
    }
  }
}
</script>
