import AbstractModel from '../abstract/abstract.model'

class Donnee extends AbstractModel {
  constructor (data) {
    super(data, ['id', 'type', 'valeur'])
  }

  getValeur () {
    return this.getProperty('valeur')
  }
}

export default Donnee
