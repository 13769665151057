import Model from '../fiche.model'
import Contrat from '@/modules/production/contrat/contrat.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`fiches/${id}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  getFicheEntreprise: ({ GET }, siret) =>
    new Promise((resolve, reject) => {
      return GET(`fiches/entreprise/${siret}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  listContrats: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`fiches/${id}/contrats`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && Array.isArray(data)) {
            const contrats = data.map((contratData) => new Contrat(contratData))
            resolve(contrats)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }, query) =>
    new Promise((resolve, reject) => {
      return GET(`fiches`, query)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            // On ne transforme pas en Fiche pour des raisons de perf
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  getConsentement: ({ GET }, datas) =>
    new Promise((resolve, reject) => {
      const { idConsentement } = datas
      if (!idConsentement) {
        return reject(new Error(`Erreur paramètre : idConsentement non défini`))
      }
      return GET(`fiches/consentement/${idConsentement}`)
        .then((reponse) => {
          if (reponse.status === 200) {
            resolve(reponse.data)
          } else {
            reject(reponse.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
