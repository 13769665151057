import Fiche from '../fiche.model'

export default {
  isDirty: (state) => !!state.isDirty,
  fiches: (state) => state.fiches,
  ficheById: (state) => (id) => {
    const fiche = state.fiches
      ? state.fiches.find((fiche) => fiche.id === id)
      : null
    return fiche ? new Fiche(fiche) : null
  },
  fiches_pending: (state) => state.fiches_pending,
  fiches_refresh_pending: (state) => state.fiches_refresh_pending
}
