export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL'

export default {
  [GET_GROUPS](state) {
    state.groups_pending = true
  },
  [GET_GROUPS_SUCCESS](state, groups) {
    state.groups = groups
    state.groups_pending = false
  },
  [GET_GROUPS_FAIL](state) {
    state.groups_pending = false
  }
}
