export default {
  ignore: [],
  errorElement: 'span',
  errorClass: 'helper-text red-text error-validate',
  errorPlacement: function (error, element) {
    const $el = $(element)
    const placement = $el.data('error')
    if (placement) {
      $(placement).append(error)
    } else if ($el.hasClass('app-select2')) {
      error.appendTo($el.parent())
    } else {
      error.insertAfter($el)
    }
  },
  highlight: function (element) {
    $(element)
      .removeClass('valid')
      .addClass('invalid')
  },
  unhighlight: function (element) {
    $(element)
      .removeClass('invalid')
      .addClass('valid')
  }
}
