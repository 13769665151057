import Notifications from '@/components/dashboard/header/components/notifications/Notifications'
import User from '@/components/dashboard/header/components/User'
import Sidebar from '@/components/dashboard/header/components/Sidebar'

export default {
  search: false,
  fullscreen: false,
  components: [
    {
      component: Notifications
    },
    {
      component: User
    },
    {
      component: Sidebar
    }
  ]
}
