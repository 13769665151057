import Model from '../models/cabinet.model'
import { compareStr } from '@/plugins/utils'

export default {
  cabinets: (state) => state.models,
  cabinetById: (state) => (id) => {
    const model = state.models
      ? state.models.find((model) => compareStr(model.id)(id))
      : null
    return model ? new Model(model) : null
  },
  cabinets_pending: (state) => state.models_pending
}
