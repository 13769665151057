import Model from '../fiche.model'

export default {
  edit: ({ PUT }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : ${Model.constructor.name} attendu mais reçu "${model.constructor.name}"`
          )
        )
      } else if (model.isNew()) {
        return reject(
          new Error(`Erreur paramètre : ${Model.constructor.name} is new`)
        )
      }

      return PUT(`fiches/${model.getId()}`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  changeCollab: ({ PUT }, data) =>
    new Promise((resolve, reject) => {
      if (!data.idsFiches || data.idsFiches.length < 1) {
        return reject(
          new Error(
            `Erreur paramètre : "Array" attendu mais reçu "${typeof data.idsFiches}"`
          )
        )
      }
      if (!data.collaborateurId) {
        return reject(
          new Error(
            `Erreur paramètre : "number" attendu mais reçu "${typeof data.collaborateurId}"`
          )
        )
      }

      return PUT(`fiches/changeCollab`, data)
        .then((reponse) => {
          if (reponse) {
            resolve(true)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
