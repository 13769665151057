import { STORE_TOKEN } from '@/modules/auth/store/storage'

let isLocalStorage = !!localStorage.getItem(STORE_TOKEN)

const setLocalStorage = isLocal => (isLocalStorage = !!isLocal)
const getStorage = () => (isLocalStorage ? localStorage : sessionStorage)

function clearStorage () {
  localStorage.clear()
  sessionStorage.clear()
}

export { setLocalStorage, getStorage, clearStorage }
