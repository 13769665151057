import Group from '../group.model'

export default {
  add: ({ POST }, group) =>
    new Promise((resolve, reject) => {
      if (!(group instanceof Group)) {
        return reject(
          new Error(
            `Erreur paramètre : Group attendu mais reçu "${group.constructor.name}"`
          )
        )
      }
      return POST(`groups`, group)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const group = new Group(data)
            resolve(group)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
