<template>
  <!-- BEGIN: SideNav-->
  <aside
    class="sidenav-main nav-expanded nav-collapsible sidenav-dark sidenav-active-rounded"
    :class="{
      'nav-lock': navbarLocked,
      'nav-collapsed': !navbarLocked
    }"
  >
    <div class="brand-sidebar">
      <h1 class="logo-wrapper">
        <a is="router-link" class="brand-logo darken-1" :to="'/'">
          <span class="logo-text hide-on-med-and-down">SCA Thomas</span>
        </a>
        <a
          v-if="!navbarLocked"
          class="navbar-toggler"
          href="#"
          @click="navBarToggler"
        >
          <i class="material-icons">{{
            navbarLocked ? 'radio_button_checked' : 'radio_button_unchecked'
          }}</i>
        </a>
      </h1>
    </div>
    <div
      id="sidenav-main"
      class="app-perfectscrollbar sidenav sidenav-collapsible collapsible sidenav-fixed app-sidenav pb-2"
    >
      <sb-menu v-if="menu" :menu="menu" />
    </div>
    <div class="navigation-background" />
    <a
      class="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"
      href="#"
      data-target="sidenav-main"
    >
      <i class="material-icons">menu</i>
    </a>
  </aside>
</template>

<script>
import menus from '@/configs/menu'
import SbMenu from './elements/menu'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidenav',
  components: {
    SbMenu
  },
  data() {
    return {
      menu: undefined
    }
  },
  computed: {
    ...mapGetters('ui', { navbarLocked: 'sidebarLock' }),
    ...mapGetters('user', ['user'])
  },
  async beforeMount() {
    await Promise.all([this.$store.dispatch('agence/list')])

    const oks = {
      agenceList: false
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'agence/GET_AGENCES_SUCCESS') {
        oks.agenceList = true

        if (Object.values(oks).every((ok) => !!ok)) {
          this.menu = {
            groups: this.setOpenDefault(menus, this.user.menu ?? [])
          }
        }
      }
    })

    await this.$store.dispatch('agence/list')
  },
  mounted() {
    // Init Sidenav
    $(this.$el)
      .unbind('mouseenter')
      .unbind('mouseleave')
      .on('mouseenter', function () {
        const $el = $(this)
        if (!$el.hasClass('nav-lock')) {
          $el.addClass('nav-expanded').removeClass('nav-collapsed')
          $('.navbar-main.nav-collapsible')
            .addClass('nav-expanded')
            .removeClass('nav-collapsed')
          $('#sidenav-main > li.close > a')
            .parent()
            .addClass('open')
            .removeClass('close')

          setTimeout(function () {
            if ($el.find('.collapsible .open').children().length > 1) {
              if ($el.find('.collapsible .open').index()) {
                $el
                  .find('.collapsible')
                  .collapsible('open', $el.find('.collapsible .open').index())
              }
            }
          }, 100)
        }
      })
      .on('mouseleave', function () {
        const $el = $(this)
        if (!$el.hasClass('nav-lock')) {
          var openLength = $el.find('.collapsible .open').children().length
          $el.addClass('nav-collapsed').removeClass('nav-expanded')

          $('.navbar-main.nav-collapsible')
            .addClass('nav-collapsed')
            .removeClass('nav-expanded')
          $('#sidenav-main > li.open > a')
            .parent()
            .addClass('close')
            .removeClass('open')
          setTimeout(function () {
            if (openLength > 1) {
              $el
                .find('.collapsible')
                .collapsible('close', $el.find('.collapsible .close').index())
            }
          }, 100)
        }
      })
  },
  methods: {
    navBarToggler() {
      this.$store.dispatch('ui/navbarLockToggle')
    },
    setOpenDefault(items, opens = []) {
      opens.forEach((open) => {
        const [title, ...restTitle] = open.split('||')
        const item = items.find((i) => this.getTexteTitle(i.title) === title)
        if (restTitle.length === 0) {
          item.open = true
        } else {
          this.setOpenDefault(item.items, restTitle)
        }
      })

      return items
    },
    getTexteTitle(textFn) {
      return typeof textFn === 'function' ? textFn({ user: this.user }) : textFn
    }
  }
}
</script>
