export const GET_DOCS = 'GET_DOCS'
export const GET_DOCS_SUCCESS = 'GET_DOCS_SUCCESS'
export const GET_DOCS_FAIL = 'GET_DOCS_FAIL'

export default {
  [GET_DOCS](state) {
    state.quests_pending = true
  },
  [GET_DOCS_SUCCESS](state, quests) {
    state.quests = quests
    state.quests_pending = false
  },
  [GET_DOCS_FAIL](state) {
    state.quests_pending = false
  }
}
