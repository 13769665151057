export default {
  list: ({ GET }, query) =>
    new Promise((resolve, reject) => {
      return GET(`notifications`, query)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
