import AbstractModel from '../abstract/abstract.model'

import FicheUser from './models/user.model'
import FicheEnfant from './models/enfant.model'
import FicheContrat from './models/contrat.model'
import FicheEntreprise from './models/entreprise.model'
import FicheFinancier from './models/financier.model'
import FicheVisite from './models/visite.model'

import { api, router } from '@/plugins'
import FichierManager from '../fichier/fichierManager.model'

class Fiche extends AbstractModel {
  pending = false

  constructor(data) {
    super(data, [
      'id',
      'collaborateurId',
      {
        field: 'client',
        callBack: (value) => new FicheUser(value)
      },
      {
        field: 'conjoint',
        callBack: (value) => {
          return new FicheUser(value)
        }
      },
      {
        field: 'enfants',
        default: [],
        callBack: (values) => values.map((value) => new FicheEnfant(value))
      },
      {
        field: 'contrats',
        default: [],
        callBack: (values) => values.map((value) => new FicheContrat(value))
      },
      {
        field: 'entreprises',
        default: [],
        callBack: (values) => values.map((value) => new FicheEntreprise(value))
      },
      {
        field: 'financier',
        callBack: (value) => new FicheFinancier(value)
      },
      {
        field: 'visites',
        default: [],
        callBack: (values) => values.map((value) => new FicheVisite(value))
      },
      {
        field: 'fichiers',
        default: [],
        callBack: (value) => new FichierManager(value)
      },
      'alert',
      'completude',
      'teleprospection',
      'prospect',
      'recoec',
      'rgpdSkipped',
      'rgpdAccepted'
    ])

    this.init()
  }

  init = function () {
    this.getConjoint().setProperty('situation', undefined)
  }

  getClient = function () {
    return this.getProperty('client')
  }

  getTeleprospection = function () {
    return this.getProperty('teleprospection')
  }

  getConjoint = function () {
    return this.getProperty('conjoint')
  }

  getEnfants = function () {
    return this.getProperty('enfants')
  }
  setEnfants = function (enfants) {
    return this.setProperty('enfants', enfants)
  }

  getContrats = function () {
    return this.getProperty('contrats')
  }
  setContrats = function (contrats) {
    return this.setProperty('contrats', contrats)
  }

  getEntreprises = function () {
    return this.getProperty('entreprises')
  }
  setEntreprises = function (entreprises) {
    return this.setProperty('entreprises', entreprises)
  }

  getFinancier = function () {
    return this.getProperty('financier')
  }

  setVisites = function (visites) {
    return this.setProperty('visites', visites)
  }
  getVisites = function () {
    return this.getProperty('visites')
  }
  getVisite = function (id) {
    return this.getVisites().find((visite) => visite.getId() === id)
  }

  getFichiers = function () {
    return this.getProperty('fichiers')
  }

  save = function (goToFiche = true) {
    let savePromise

    if (this.pending) {
      return
    }
    this.pending = true

    const isNew = this.isNew()

    if (isNew) {
      savePromise = api.POST('fiche/add', this)
    } else {
      savePromise = api.PUT('fiche/edit', this)
    }

    return savePromise
      .then((fiche) => {
        M.toast({
          html: 'La fiche a bien été sauvegardée.',
          classes: 'green'
        })

        this.setProperty('financier', fiche.getFinancier())
        this.setProperty('entreprises', fiche.getEntreprises())

        this.resetModelRef()
        this.pending = false

        if (isNew && goToFiche) {
          router.push({
            name: 'fiche.edit',
            params: { id: fiche.getId() }
          })
        }
        return fiche
      })
      .catch(() => {
        M.toast({
          html: "La fiche n'a pas pu être sauvegardée.",
          classes: 'red darken-2'
        })
        this.pending = false
      })
  }

  delete() {
    return api.DELETE('fiche/delete', this).then(() => {
      this.deleted = true

      M.toast({
        html: `La fiche a été supprimée.`,
        classes: 'green'
      })
    })
  }
}

export default Fiche
