export default {
  install(Vue) {
    Vue.mixin({
      mounted() {
        // Panel sidebar
        $(this.$el)
          .find('.app-sidenav')
          .each(function () {
            var direction = 'left'
            if ($(this).hasClass('app-sidenav-right')) {
              direction = 'right'
            }
            const group =
              $(this).data('sidenav-group') ||
              `grp_${1000 + Math.round(Math.random() * 8999)}`
            $(this).data('sidenav-group', group)

            const id = this.id

            M.Sidenav.init(this, {
              edge: direction,
              onOpenStart: function (sidenav) {
                $(sidenav).parent().addClass('sidenav-show')
              },
              onCloseEnd: function (sidenav) {
                $(sidenav).parent().removeClass('sidenav-show')
              }
            })

            $(document).on(`sidenav:${id}:close`, () => {
              const instance = M.Sidenav.getInstance(this)
              if (instance) {
                instance.close()
              }
            })
          })
      },
      beforeDestroy() {
        // Retrait des DIVs supplémentaires créées
        $(this.$el)
          .find('.app-sidenav')
          .each((index, el) => {
            const instance = M.Sidenav.getInstance(el)
            if (instance) instance.destroy()
          })
      }
    })
  }
}
