export default {
  dsnSend: ({ PUT }, { id, date }) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/duedsn/${id}/dsnSend`, {
        date
      })
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  dueSend: ({ PUT }, { id, date }) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/duedsn/${id}/dueSend`, {
        date
      })
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  dsnProcess: ({ PUT }, { id }) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/duedsn/${id}/dsnProcess`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  dueProcess: ({ PUT }, { id }) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/duedsn/${id}/dueProcess`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
