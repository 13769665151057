export const GET_RECOMMANDATIONS = 'GET_RECOMMANDATIONS'
export const GET_RECOMMANDATIONS_SUCCESS = 'GET_RECOMMANDATIONS_SUCCESS'
export const GET_RECOMMANDATIONS_FAIL = 'GET_RECOMMANDATIONS_FAIL'

export default {
  [GET_RECOMMANDATIONS] (state) {
    state.recommandations_pending = true
  },
  [GET_RECOMMANDATIONS_SUCCESS] (state, recommandations) {
    state.recommandations = recommandations
    state.recommandations_pending = false
  },
  [GET_RECOMMANDATIONS_FAIL] (state) {
    state.recommandations_pending = false
  }
}
