import Satisfaction from '../satisfaction.model'

export default {
  edit: ({ PUT }, data) =>
    new Promise((resolve, reject) => {
      if (!(data.questions instanceof Satisfaction)) {
        throw new Error(
          `Erreur paramètre : Satisfaction attendu mais reçu "${typeof data.questions}"`
        )
      }
      if (!data.id) {
        throw new Error(`Erreur paramètre : id du questionnaire attendu`)
      }
      return PUT(`satisfactions/${data.id}`, data.questions)
        .then((reponse) => {
          resolve(reponse.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
}
