import AbstractModel from '../../abstract/abstract.model'
import { compareStr } from '@/plugins/utils'

class CabinetContact extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      {
        field: 'roles',
        default: []
      },
      'nom',
      'prenom',
      'email',
      'telephone',
      'commentaire'
    ])
  }

  getRoles() {
    return this.getProperty('roles', [])
  }

  addRole(role) {
    if (!this.hasRole(role)) {
      const roles = this.getRoles()
      roles.push(role)
      this.setProperty('roles', roles)
    }
  }

  hasRole(role) {
    const roles = this.getRoles()
    return roles.includes(role)
  }

  removeRole(role) {
    const roles = this.getRoles()
    this.setProperty(
      'roles',
      roles.filter((r) => !compareStr(r)(role))
    )
  }
}

export default CabinetContact
