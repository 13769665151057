import User from '../user.model'

export default {
  add: ({ POST }, user) =>
    new Promise((resolve, reject) => {
      if (!(user instanceof User)) {
        return reject(
          new Error(
            `Erreur paramètre : User attendu mais reçu "${user.constructor.name}"`
          )
        )
      }
      return POST(`users`, user)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const user = new User(data)
            resolve(user)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  menu: ({ POST }, { group, open = false }) =>
    new Promise((resolve, reject) => {
      if (group) {
        return POST(`users/menu`, { group, open })
          .then((reponse) => {
            if ([200, 204].includes(reponse.status)) {
              resolve(true)
            } else {
              reject(reponse.status)
            }
          })
          .catch((error) => {
            reject(error)
          })
      }
      reject(new Error(`Erreur paramètre : aucun group reçu`))
    })
}
