import { parseNumber } from '@/plugins/utils'

class ProductionContrat {
  constructor(data = {}) {
    const {
      id,
      collaborateurId,
      ficheId,
      produitId,
      contratChuteId,
      motifChuteId,
      dateChute,
      dateEffet,
      dateSaisie,
      dateCommission,
      agenceId,
      numero,
      nom,
      prenom,
      dateNaissance,
      sel,
      blacklist,
      sourceId,
      typologie,
      fraisDossierFixes,
      fraisVersementPU,
      fraisVersementPP,
      pourcentUC,
      primeAnnuelle,
      formulaire,
      gedIds,
      gedBloquantIds,
      fdcId,
      controle,
      commentaires,
      commentaireTeletransmission,
      calculs,
      bloquant,
      created,
      modified,
      garantieDeces,
      collectifDetails,
      due,
      dsn,
      duedsn
    } = data

    this.id = id

    this.collaborateurId = collaborateurId
    this.ficheId = ficheId || ''
    this.produitId = produitId
    this.agenceId = agenceId
    this.sourceId = sourceId
    this.contratChuteId = contratChuteId
    this.motifChuteId = motifChuteId
    this.fdcId = fdcId

    this.gedIds = gedIds || []
    this.gedBloquantIds = gedBloquantIds || []

    this.dateChute = dateChute || ''
    this.dateEffet = dateEffet || ''
    this.dateSaisie = dateSaisie || ''
    this.dateCommission = dateCommission || ''
    this.numero = numero || ''
    this.nom = nom || ''
    this.prenom = prenom || ''
    this.dateNaissance = dateNaissance || ''
    this.sel = sel ? '1' : '0'
    this.blacklist = blacklist ? '1' : '0'
    this.typologie = typologie || ''
    this.fraisDossierFixes = parseNumber(fraisDossierFixes)
    this.fraisVersementPU = parseNumber(fraisVersementPU)
    this.fraisVersementPP = parseNumber(fraisVersementPP)
    this.pourcentUC = parseNumber(pourcentUC)
    this.primeAnnuelle = parseNumber(primeAnnuelle)
    this.formulaire = formulaire || {}
    this.controle = !!controle
    this.commentaires = commentaires || ''
    this.commentaireTeletransmission = commentaireTeletransmission || ''
    this.calculs = calculs || {}
    this.bloquant = bloquant || {}
    this.created = created || ''
    this.modified = modified || ''
    this.garantieDeces = garantieDeces ? 1 : 0
    this.collectifDetails = collectifDetails || {}
    this.due = !!due
    this.dsn = !!dsn
    this.duedsn = duedsn || {}
  }

  isNew() {
    return !this.id
  }

  isBloque() {
    const total =
      typeof this.bloquant.total !== 'undefined' ? this.bloquant.total : 0
    return total !== 0
  }

  isChute() {
    return !!this.dateChute && !this.produitId
  }

  hasChute() {
    return !!this.dateChute && !!this.produitId
  }
}

export default ProductionContrat
