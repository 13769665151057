import Model from '../avenant.model'
import { compareStr } from '@/plugins/utils'

export default {
  avenants: (state) => state.avenants,
  avenantById: (state) => (id) => {
    const model = state.avenants
      ? state.avenants.find((model) => compareStr(model.id)(id))
      : null
    return model ? new Model(model) : null
  },
  avenants_pending: (state) => state.avenants_pending,
  avenants_refresh_pending: (state) => state.avenants_refresh_pending
}
