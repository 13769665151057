import agence from '@/modules/agence/agence.store'
import application from '@/modules/application/application.store'
import auth from '@/modules/auth/auth.store'
import bienetre from '@/modules/bienetre/bienetre.store'
import cabinet from '@/modules/cabinet/cabinet.store'
import caisse from '@/modules/caisse/caisse.store'
import cheque from '@/modules/cheque/cheque.store'
import client from '@/modules/client/client.store'
import donnee from '@/modules/donnee/donnee.store'
import fiche from '@/modules/fiche/fiche.store'
import instance from '@/modules/instance/instance.store'
import notification from '@/modules/notification/notification.store'
import production from '@/modules/production/production.store'
import recommandation from '@/modules/recommandation/recommandation.store'
import resiliation from '@/modules/resiliation/resiliation.store'
import router from '@/modules/router/router.store'
import satisfaction from '@/modules/satisfaction/satisfaction.store'
import stat from '@/modules/stat/stat.store'
import suivihebdo from '@/modules/suivihebdo/suivihebdo.store'
import ui from '@/modules/ui/ui.store'
import user from '@/modules/user/user.store'
import group from '@/modules/group/group.store'

import { NB_LOCK, NB_UNLOCK } from '@/modules/ui/store/mutations'
import { LOGIN_SUCCESS } from '@/modules/auth/store/mutations'

export default {
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    agence,
    application,
    auth,
    bienetre,
    cabinet,
    caisse,
    cheque,
    client,
    donnee,
    fiche,
    instance,
    notification,
    production,
    recommandation,
    resiliation,
    router,
    satisfaction,
    stat,
    suivihebdo,
    ui,
    user,
    group
  },
  plugins: [
    // Une fois connecté, on récupère l'utilisateur
    function getUserOnLogin(store) {
      store.subscribe((mutation) => {
        if (mutation.type === `auth/${LOGIN_SUCCESS}`) {
          store.dispatch('user/refresh')
        }
      })
    },
    // Lorsque l'on lock/unlock la sidebar, on lance un event resize
    function dispatchResizeNB(store) {
      store.subscribe((mutation) => {
        if (
          mutation.type === `ui/${NB_LOCK}` ||
          mutation.type === `ui/${NB_UNLOCK}`
        ) {
          setTimeout(function () {
            window.dispatchEvent(new Event('resize'))
          }, 350)
        }
      })
    }
  ]
}
