export const GET_AGENCES = 'GET_AGENCES'
export const GET_AGENCES_SUCCESS = 'GET_AGENCES_SUCCESS'
export const GET_AGENCES_FAIL = 'GET_AGENCES_FAIL'
export const GET_COLLABORATEURS = 'GET_COLLABORATEURS'
export const GET_COLLABORATEURS_SUCCESS = 'GET_COLLABORATEURS_SUCCESS'
export const GET_COLLABORATEURS_FAIL = 'GET_COLLABORATEURS_FAIL'

export default {
  [GET_AGENCES](state) {
    state.models_pending = true
  },
  [GET_AGENCES_SUCCESS](state, models) {
    state.models = models
    state.models_pending = false
  },
  [GET_AGENCES_FAIL](state) {
    state.models_pending = false
  },
  [GET_COLLABORATEURS](state) {
    state.collaborateurs_pending = true
  },
  [GET_COLLABORATEURS_SUCCESS](state, collaborateurs) {
    state.collaborateurs = collaborateurs
    state.collaborateurs_pending = false
  },
  [GET_COLLABORATEURS_FAIL](state) {
    state.collaborateurs_pending = false
  }
}
