import { getStorage } from '@/plugins/Store/locationStorage'
import { STORE_SB_COLLAPSE, STORE_COL_TABLES } from './storage'
import {
  NB_LOCK,
  NB_UNLOCK,
  SCROLL_LOCKED,
  SCROLL_UNLOCKED,
  DASHBOARD_DISPLAYED,
  SET_COLS_DATATABLE,
  MODE_DEBUG_ON,
  MODE_DEBUG_OFF
} from './mutations'

export default {
  navbarLock({ commit }) {
    getStorage().removeItem(STORE_SB_COLLAPSE)
    commit(NB_LOCK)
  },
  navbarUnlock({ commit }) {
    getStorage().setItem(STORE_SB_COLLAPSE, true)
    commit(NB_UNLOCK)
  },
  navbarLockToggle({ dispatch }) {
    const isLock = !getStorage().getItem(STORE_SB_COLLAPSE)
    dispatch(isLock ? 'navbarUnlock' : 'navbarLock')
  },
  scrollLock({ commit }) {
    commit(SCROLL_LOCKED)
  },
  scrollUnlock({ commit }) {
    commit(SCROLL_UNLOCKED)
  },
  modeDebugON({ commit }) {
    commit(MODE_DEBUG_ON)
  },
  modeDebugOFF({ commit }) {
    commit(MODE_DEBUG_OFF)
  },
  dashboardDisplayed({ commit }, display = false) {
    commit(DASHBOARD_DISPLAYED, display)
  },
  loadColsDatatable({ commit }) {
    let datatables = {}
    try {
      datatables = JSON.parse(getStorage().getItem(STORE_COL_TABLES)) || {}
    } catch (e) {
      datatables = {}
    }
    commit(SET_COLS_DATATABLE, datatables)
  },
  setColsDatatable({ commit }, { key, cols }) {
    let datatables = {}
    try {
      datatables = JSON.parse(getStorage().getItem(STORE_COL_TABLES)) || {}
    } catch (e) {
      datatables = {}
    }
    datatables[key] = cols
    getStorage().setItem(STORE_COL_TABLES, JSON.stringify(datatables))
    commit(SET_COLS_DATATABLE, datatables)
  }
}
