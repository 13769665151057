import AbstractModel, {
  CALLBACK_SUBDOC_ID
} from '@/modules/abstract/abstract.model'
import { entrees, sorties, patrimoines } from '@/modules/fiche/fiche.data'

import { forEachValue, isObject, mergeDeep } from '@/plugins/utils'

import FicheEpargne from './epargne.model'
import FicheCredit from './credit.model'
import FicheDonation from './donation.model'

class FicheFinancier extends AbstractModel {
  constructor(data) {
    super(data, [
      {
        field: 'imposition_taux_marginal',
        callBack: CALLBACK_SUBDOC_ID
      },
      'imposition_nb_part',
      'nb_personne_handicapee',
      'nb_personne_handicapee_modified',
      'nbPersonneHandicapeeDatemodif',
      'informations',
      'informations_modified',
      'informationsDatemodif',
      {
        field: 'entrees',
        default: {},
        callBack: (values) => hydrateObj(entrees, values)
      },
      {
        field: 'sorties',
        default: {},
        callBack: (values) => hydrateObj(sorties, values)
      },
      {
        field: 'patrimoines',
        default: {},
        callBack: (values) => hydrateObj(patrimoines, values)
      },
      {
        field: 'epargnes',
        default: [],
        callBack: (values) => values.map((value) => new FicheEpargne(value))
      },
      {
        field: 'credits',
        default: [],
        callBack: (values) => values.map((value) => new FicheCredit(value))
      },
      {
        field: 'donations',
        default: [],
        callBack: (values) => values.map((value) => new FicheDonation(value))
      }
    ])
  }

  getEntrees = function () {
    return this.getProperty('entrees')
  }
  getEntree = function (key) {
    if (!this.getEntrees()[key]) {
      this.getEntrees()[key] = {}
    }
    return this.getEntrees()[key]
  }

  getSorties = function () {
    return this.getProperty('sorties')
  }
  getSortie = function (key) {
    if (!this.getSorties()[key]) {
      this.getSorties()[key] = {}
    }
    return this.getSorties()[key]
  }

  getPatrimoines = function () {
    return this.getProperty('patrimoines')
  }
  getPatrimoine = function (key) {
    if (!this.getPatrimoines()[key]) {
      this.getPatrimoines()[key] = {}
    }
    return this.getPatrimoines()[key]
  }

  getEpargnes = function () {
    return this.getProperty('epargnes')
  }
  setEpargnes = function (epargnes) {
    return this.setProperty('epargnes', epargnes)
  }

  getCredits = function () {
    return this.getProperty('credits')
  }
  setCredits = function (credits) {
    return this.setProperty('credits', credits)
  }

  getDonations = function () {
    return this.getProperty('donations')
  }
  setDonations = function (donations) {
    return this.setProperty('donations', donations)
  }
}

const hydrateObj = function (elRef, values) {
  let _values = {}
  values = values || {}

  elRef.forEach((value) => {
    _values[value.id] = {}
  })

  forEachValue(_values, (value, index) => {
    if (!isObject(value)) {
      value = values[index]
    } else {
      value = mergeDeep(value, values[index])
    }
  })

  return _values
}

export default FicheFinancier
