import Model from '../avenant.model'

export default {
  add: ({ POST }, model) =>
    new Promise((resolve, reject) => {
      if (!(model instanceof Model)) {
        return reject(
          new Error(
            `Erreur paramètre : Model attendu mais reçu "${model.constructor.name}"`
          )
        )
      }
      return POST(`production/avenants`, model)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
