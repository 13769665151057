import User from '../user.model'

export default {
  edit: ({ PUT }, user) =>
    new Promise((resolve, reject) => {
      if (!(user instanceof User)) {
        return reject(new Error(
          `Erreur paramètre : User attendu mais reçu "${user.constructor.name}"`
        ))
      } else if (user.isNew()) {
        return reject(new Error(`Erreur paramètre : User is new`))
      }

      return PUT(`users/${user.id}`, user)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const user = new User(data)
            return resolve(user)
          } else {
            return reject(new Error({ reponse }))
          }
        })
        .catch(error => reject(error))
    })
}
