export const NB_LOCK = 'NB_LOCK'
export const NB_UNLOCK = 'NB_UNLOCK'
export const SCROLL_LOCKED = 'SCROLL_LOCKED'
export const SCROLL_UNLOCKED = 'SCROLL_UNLOCKED'
export const DASHBOARD_DISPLAYED = 'DASHBOARD_DISPLAYED'
export const SET_COLS_DATATABLE = 'SET_COLS_DATATABLE'
export const MODE_DEBUG_ON = 'MODE_DEBUG_ON'
export const MODE_DEBUG_OFF = 'MODE_DEBUG_OFF'

export default {
  [NB_LOCK](state) {
    state.sidebarLock = true
    setTimeout(function () {
      $(window).trigger('resize')
    }, 500)
  },
  [NB_UNLOCK](state) {
    state.sidebarLock = false
    setTimeout(function () {
      $(window).trigger('resize')
    }, 500)
  },
  [DASHBOARD_DISPLAYED](state, display) {
    state.dashboard = !!display
  },
  [SET_COLS_DATATABLE](state, colDatatables) {
    state.colDatatables = colDatatables
  },
  [SCROLL_LOCKED](state) {
    state.scrollLocked = true
  },
  [SCROLL_UNLOCKED](state) {
    state.scrollLocked = false
  },
  [MODE_DEBUG_ON](state) {
    state.modeDebug = true
  },
  [MODE_DEBUG_OFF](state) {
    state.modeDebug = false
  }
}
