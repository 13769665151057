import Model from '../cheque.model'

export default {
  get: ({ GET }, { chequeId }) =>
    new Promise((resolve, reject) => {
      if (!chequeId) {
        return reject(new Error(`Erreur paramètre : ID non valide`))
      }
      return GET(`cheques/${chequeId}`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    }),
  list: ({ GET }) =>
    new Promise((resolve, reject) =>
      GET(`cheques`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    )
}
