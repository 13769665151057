import Resiliation from '../resiliation.model'

export default {
  resiliations: state => state.resiliations,
  resiliationById: state => id => {
    const resiliation = state.resiliations
      ? state.resiliations.find(resiliation => resiliation.id === id)
      : null
    return resiliation ? new Resiliation(resiliation) : null
  },
  resiliations_pending: state => state.resiliations_pending
}
