import ConfigCollaborateur from '../configCollaborateur.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`production/configCollaborateurs/${id}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new ConfigCollaborateur(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  list: ({ GET }, data) =>
    new Promise((resolve, reject) => {
      return GET(`production/configCollaborateurs`, { ...data })
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach((modelData) => {
              models.push(new ConfigCollaborateur(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
