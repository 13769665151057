import { api } from '@/plugins'
import { GET_DOCS, GET_DOCS_SUCCESS, GET_DOCS_FAIL } from './mutations'

export default {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_DOCS)
      api
        .GET('suivihebdo/list')
        .then(async (quests) => {
          await commit(GET_DOCS_SUCCESS, quests)
          resolve(quests)
        })
        .catch((e) => {
          commit(GET_DOCS_FAIL)
          reject(e)
        })
    })
  }
}
