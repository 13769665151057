import moment from 'moment-timezone'
import 'moment/locale/fr'

import validateFormOptions from '@/configs/elements/validate-form'
import { toNumber } from 'lodash'

function assert(condition, msg, type = '_') {
  if (!condition) {
    throw new Error(`[${type}] ${msg}`)
  }
}

function clone(obj) {
  let _clone = Array.isArray(obj)
    ? []
    : Object.assign(Object.create(Object.getPrototypeOf(obj)), obj)
  for (let i in obj) {
    let value = obj[i]
    if (value !== null && (isObject(value) || Array.isArray(value))) {
      _clone[i] = clone(value)
    } else {
      _clone[i] = value
    }
  }
  return _clone
}

function hexToRgbA(hex, opacity = 1) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    )
  }
  throw new Error('Bad Hex')
}

function arrayDiff(arr1, arr2) {
  return arr1
    .filter((v) => arr2.indexOf(v) === -1)
    .concat(arr2.filter((v) => arr1.indexOf(v) === -1))
}

function forEachValue(obj, fn) {
  let results = []
  Object.keys(obj).forEach(function (key) {
    const result = fn(obj[key], key)
    results.push(result)
    return result
  })
  return results
}

function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}

function isPromise(val) {
  return val && typeof val.then === 'function'
}

function partial(fn, arg) {
  return function () {
    return fn(arg)
  }
}

function validateForm(form, options) {
  let validate = $(form).validate()

  if (validate) {
    validate.settings = {
      ...validate.settings,
      ...validateFormOptions,
      ...options
    }
  }

  return validate
}

async function _asyncForEach(array, callback) {
  let results = []
  for (let index = 0; index < array.length; index++) {
    results.push(await callback(array[index], index, array))
  }
  return results
}

async function asyncForEach(array, callback) {
  const results = await _asyncForEach(array, callback)
  const filtered = results.filter((r) => typeof r !== 'undefined')
  return Promise.resolve(filtered)
}

function getDeepValue(obj, keys, _default = undefined) {
  const _keys = keys.split('.')
  const key = _keys.shift()

  if (typeof obj === 'undefined') {
    return _default
  } else if (!obj) {
    return obj
  } else if (typeof obj[key] === 'undefined') {
    return _default
  } else if (_keys.length > 0) {
    return getDeepValue(obj[key], _keys.join('.'), _default)
  } else {
    return obj[key]
  }
}

function setDeepValue(obj, keys, value) {
  const _keys = String(keys).split('.')

  const key = _keys.shift()

  if (isObject(obj)) {
    if (_keys.length === 0) {
      obj[String(key)] = value
    } else {
      const [nextKey] = _keys

      if (!obj[String(key)]) {
        const intKey = parseInt(nextKey, 10)

        if (isNaN(intKey)) {
          obj[String(key)] = {}
        } else {
          obj[String(key)] = []
        }
      } else if (!isObject(obj[String(key)])) {
        obj[String(key)] = {
          __invalidDeepValue: obj[String(key)]
        }
      }

      const format = Array.isArray(obj[String(key)]) ? parseInt : String
      setDeepValue(obj[format(key)], _keys.join('.'), value)
    }
  }

  return obj
}

function mergeDeep(target, ...sources) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

function _compare(a, b, ...targets) {
  let lastArg = arguments[arguments.length - 1]
  const hasSort = ['SORT_ASC', 'SORT_DESC'].includes(lastArg)
  const sort = hasSort ? lastArg : 'SORT_ASC'
  if (hasSort) {
    targets.pop()
  }

  const target = targets.shift()

  const genreA = getDeepValue(a, target)
  const genreB = getDeepValue(b, target)

  const sens = sort === 'SORT_DESC' ? -1 : 1
  let comparison = 0
  if (genreA > genreB) {
    comparison = 1 * sens
  } else if (genreA < genreB) {
    comparison = -1 * sens
  } else if (targets.length > 0) {
    comparison = _compare(a, b, ...targets, sort)
  }
  return comparison
}

function sortDeep(obj, ...targets) {
  const _obj = clone(obj)
  return _obj.sort((a, b) => _compare(a, b, ...targets))
}

function nl2br(str, isXhtml) {
  const breakTag =
    isXhtml || typeof isXhtml === 'undefined' ? '<br ' + '/>' : '<br>'

  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  )
}

const parseNumber = (number, _default) => {
  const _d = _default || 0
  const n = parseFloat(number)
  return isNaN(n) ? _d : n
}

function round(number, precision) {
  const coef = Math.pow(10, parseNumber(precision))
  return Math.round(parseNumber(number) * coef) / coef
}

function objectEquals(objectA, objectB, reverse = true, debug = false) {
  const debugRootPath = debug === true ? '' : debug
  for (var p in objectA) {
    const debugPath = debug && `${debugRootPath}/${p}`
    const v1 = objectA[p]
    const v2 = objectB[p]
    if (typeof v1 !== typeof v2) {
      if (debug) {
        console.log('objectEquals', {
          path: debugPath,
          assert: 'typeof',
          test: {
            v1: {
              type: typeof v1,
              value: v1
            },
            v2: {
              type: typeof v2,
              value: v2
            }
          }
        })
      }
      return false
    }
    if ((v1 === null) !== (v2 === null)) {
      if (debug) {
        console.log('objectEquals', {
          path: debugPath,
          assert: 'null',
          test: {
            v1: {
              type: typeof v1,
              value: v1,
              isNull: v1 === null
            },
            v2: {
              type: typeof v2,
              value: v2,
              isNull: v2 === null
            }
          }
        })
      }
      return false
    }
    switch (typeof v1) {
      case 'undefined':
        if (typeof v2 !== 'undefined') {
          if (debug) {
            console.log('objectEquals', {
              path: debugPath,
              assert: 'undefined',
              test: {
                v1: {
                  type: typeof v1,
                  value: v1
                },
                v2: {
                  type: typeof v2,
                  value: v2
                }
              }
            })
          }
          return false
        }
        break
      case 'object':
        if (
          v1 !== null &&
          v2 !== null &&
          v1.constructor.toString() !== v2.constructor.toString()
        ) {
          if (debug) {
            console.log('objectEquals', {
              path: debugPath,
              assert: 'object',
              test: {
                v1: {
                  type: typeof v1,
                  value: v1,
                  constructor: {
                    value: v1.constructor,
                    toString: v1.constructor.toString()
                  }
                },
                v2: {
                  type: typeof v2,
                  value: v2,
                  constructor: {
                    value: v2.constructor,
                    toString: v2.constructor.toString()
                  }
                }
              }
            })
          }
          return false
        }

        if (!objectEquals(v1, v2, true, debugPath)) {
          // Pas de log pour le parent
          return false
        }
        break
      case 'function':
        if (v1.toString() !== v2.toString()) {
          if (debug) {
            console.log('objectEquals', {
              path: debugPath,
              assert: 'function',
              test: {
                v1: {
                  type: typeof v1,
                  value: v1,
                  toString: v1.toString()
                },
                v2: {
                  type: typeof v2,
                  value: v2,
                  toString: v2.toString()
                }
              }
            })
          }
          return false
        }
        break
      default:
        if (v1 !== v2) {
          if (debug) {
            console.log('objectEquals', {
              path: debugPath,
              assert: 'default',
              test: {
                v1: {
                  type: typeof v1,
                  value: v1
                },
                v2: {
                  type: typeof v2,
                  value: v2
                }
              }
            })
          }
          return false
        }
    }
  }

  return reverse ? objectEquals(objectB, objectA, false, debug) : true
}

const toStr = (str) => (!str && String(str) !== '0' ? '' : String(str))
const compareStr = (comp) => (str) => toStr(str) === toStr(comp)
const isEmptyStr = compareStr('')

function uuid() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

const range = (l, r) => new Array(r - l + 1).fill().map((_, k) => k + l)

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const setIntervalIfVisible = (fn, ms) => {
  let interval = setInterval(() => document.hidden || fn(), ms)
  return () => {
    clearInterval(interval)
  }
}

const formatDate = (date, format = 'DD/MM/YYYY') =>
  date ? moment.unix(date).format(format) : undefined

function strSingularOrPlural(count, singular, plural) {
  return toNumber(count) > 1 ? plural ?? `${singular}s` : singular
}

function strSingularOrPluralSuffix(count, singular, plural) {
  return `${count} ${strSingularOrPlural(count, singular, plural)}`.trim()
}

function normalizeDiacritic(str) {
  return String(str ?? '')
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
}

export {
  assert,
  clone,
  forEachValue,
  isObject,
  isPromise,
  partial,
  validateForm,
  asyncForEach,
  getDeepValue,
  setDeepValue,
  mergeDeep,
  sortDeep,
  hexToRgbA,
  arrayDiff,
  nl2br,
  parseNumber,
  round,
  objectEquals,
  toStr,
  compareStr,
  isEmptyStr,
  uuid,
  range,
  sleep,
  setIntervalIfVisible,
  formatDate,
  strSingularOrPlural,
  strSingularOrPluralSuffix,
  normalizeDiacritic
}
