import AbstractModel from '../../abstract/abstract.model'
import moment from 'moment'

class StatModel extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'key',
      'nom',
      'datas',
      {
        field: 'update',
        callBack: (value) => value && moment.unix(value)
      }
    ])
  }

  getKey() {
    return this.getProperty('key')
  }

  getNom() {
    return this.getProperty('nom')
  }

  getUpdate() {
    return this.getProperty('update')
  }
}

export default StatModel
