import AbstractModel from '../abstract/abstract.model'

class Instance extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'collaborateurId',
      'collaborateurHistory',
      'type',
      'societe',
      'nom',
      'prenom',
      'naissance',
      'adresse',
      'cp',
      'ville',
      'pays',
      'telPro',
      'telPrive',
      'telMobile',
      'courriel',
      'dateContact',
      'dateRelance',
      'commentaire',
      'archive',
      'dateArchive',
      'ficheId',
      'visiteId'
    ])
  }

  getType() {
    return this.getProperty('type', '')
  }

  getCourriel() {
    return this.getProperty('courriel', '')
  }
}

export default Instance
