import { api } from '@/plugins'
import {
  GET_RECOMMANDATIONS,
  GET_RECOMMANDATIONS_SUCCESS,
  GET_RECOMMANDATIONS_FAIL
} from './mutations'

export default {
  list ({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_RECOMMANDATIONS)

      api
        .GET('recommandation/list')
        .then(async datas => {
          await commit(GET_RECOMMANDATIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch(e => {
          commit(GET_RECOMMANDATIONS_FAIL)
          reject(e)
        })
    })
  }
}
