import Model from '../models/ligne.model'

export default {
  getLigne: ({ GET }, { caisseId, ligneId }) =>
    new Promise((resolve, reject) => {
      if (!caisseId) {
        return reject(new Error(`Erreur paramètre : caisse ID non valide`))
      }
      if (!ligneId) {
        return reject(new Error(`Erreur paramètre : caisse ligne ID non valide`))
      }
      return GET(`caisses/${caisseId}/lignes/${ligneId}`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Model(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    }),
  listLignes: ({ GET }, caisseId) =>
    new Promise((resolve, reject) => {
      if (!caisseId) {
        return reject(new Error(`Erreur paramètre : caisse ID non valide`))
      }

      return GET(`caisses/${caisseId}/lignes`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
