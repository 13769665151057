var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-container"},_vm._l((_vm.groups),function(_group,groupIndex){return _c('div',{key:(_vm.kdm + "-" + groupIndex + "-" + (_vm.getTexteTitle(_group.title))),staticClass:"menu-group",class:{
      open: _vm.isOpen(_group),
      selected: _vm.isSubSelected(_group)
    }},[(_vm.isAllowed(_group))?[(!_group.items && _group.to)?[_c('div',{staticClass:"menu-header menu-header-solo"},[_c('router-link',{staticClass:"menu-title-link",attrs:{"to":_group.to}},[_c('span',{staticClass:"menu-title-icon"},[(_group.icon)?_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_group.icon))]):_vm._e()]),_c('span',{staticClass:"menu-title-title"},[_vm._v(_vm._s(_vm.getTexteTitle(_group.title)))])])],1)]:(_group.items)?[_c('div',{staticClass:"menu-header",on:{"click":function($event){return _vm.toggleGroup(_group, groupIndex)}}},[_c('i',{staticClass:"material-icons close-icon"},[_vm._v(" "+_vm._s(_vm.isOpen(_group) ? 'expand_less' : 'expand_more'))]),_c('div',{staticClass:"menu-title-link"},[(_group.icon)?_c('span',{staticClass:"menu-title-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_group.icon))])]):_vm._e(),_c('span',{staticClass:"menu-title-title"},[_vm._v(_vm._s(_vm.getTexteTitle(_group.title)))])]),_c('div',{staticClass:"menu-items-title"},[_vm._v(" "+_vm._s(_vm.itemsTitles(_group))+" ")])]),(_group.open)?_c('div',{staticClass:"menu-items"},[(_group.searchBar)?_c('div',{staticClass:"ms-2 me-2"},[_c('div',{staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_group._search),expression:"_group._search",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","required":"","placeholder":"Rechercher"},domProps:{"value":(_group._search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_group, "_search", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":function($event){_group._search = ''}}},[_vm._v(" x ")])]),_c('hr',{staticClass:"mt-2 mb-2"})]):_vm._e(),_vm._l((_vm.itemsFilter(_group)),function(item,itemIndex){return _c('div',{key:(itemIndex + "-" + (_vm.getTexteTitle(item.title))),staticClass:"menu-item",class:{
              selected: _vm.isSelected(item)
            }},[(item.items)?_c('Menu',{attrs:{"group":_group,"menu":{
                groups: [item]
              },"base-open":_vm.baseOpen}}):(item.to)?_c('router-link',{staticClass:"menu-item-link",attrs:{"to":item.to}},[_c('span',{staticClass:"menu-item-icon"},[(item.icon)?_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(item.icon))]):_vm._e()]),_c('span',{staticClass:"menu-item-title"},[_vm._v(_vm._s(_vm.getTexteTitle(item.title)))])]):(item.href)?_c('a',{staticClass:"menu-item-link",attrs:{"href":item.href,"target":item.target || '_self'}},[_c('span',{staticClass:"menu-item-icon"},[(item.icon)?_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(item.icon))]):_vm._e()]),_c('span',{staticClass:"menu-item-title"},[_vm._v(_vm._s(_vm.getTexteTitle(item.title)))])]):_vm._e(),(_vm.actionsFilter(item))?_c('div',{staticClass:"menu-actions"},_vm._l((_vm.actionsFilter(item)),function(action,actionIndex){return _c('div',{key:actionIndex,staticClass:"menu-action"},[(action.to)?_c('router-link',{staticClass:"menu-action-link",attrs:{"to":action.to}},[(action.icon)?_c('span',{staticClass:"menu-action-icon tooltipped",attrs:{"data-position":"top","data-tooltip":_vm.getTexteTitle(action.title)}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(action.icon))])]):_vm._e()]):(action.href)?_c('a',{staticClass:"menu-action-link",attrs:{"href":action.href,"target":action.target || '_self'}},[(action.icon)?_c('span',{staticClass:"menu-action-icon tooltipped",attrs:{"data-position":"top","data-tooltip":_vm.getTexteTitle(action.title)}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(action.icon))])]):_vm._e()]):_vm._e()],1)}),0):_vm._e()],1)}),(
              _group.searchBar &&
              _vm.items(_group.items).length > 0 &&
              _vm.itemsFilter(_group).length === 0
            )?_c('div',[_vm._m(0,true)]):_vm._e()],2):_vm._e()]:_vm._e()]:_vm._e()],2)}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"menu-item"},[_c('span',{staticClass:"menu-item-link"},[_c('span',{staticClass:"menu-item-title"},[_vm._v("Aucun résultat trouvé.")])])])}]

export { render, staticRenderFns }