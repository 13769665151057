import { api } from '@/plugins'

import { getStorage, clearStorage } from '@/plugins/Store/locationStorage'
import { STORE_TOKEN } from './storage'
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_PARTIAL,
  LOGIN_FAIL,
  LOGOUT
} from './mutations'

export default {
  login({ commit }, creds) {
    commit(LOGIN)

    return new Promise((resolve, reject) => {
      api
        .POST('auth/login', creds)
        .then((data) => {
          clearStorage()
          getStorage().setItem(STORE_TOKEN, data.token)

          if (data.otp) {
            commit(LOGIN_PARTIAL)
            return resolve({ otp: data.otp })
          }

          commit(LOGIN_SUCCESS)
          resolve({
            token: data.token
          })
        })
        .catch((e) => {
          commit(LOGIN_FAIL)
          reject(e)
        })
    })
  },
  otp({ commit }, otp) {
    return new Promise((resolve, reject) => {
      api
        .POST('auth/otp', { otp })
        .then((data) => {
          clearStorage()
          getStorage().setItem(STORE_TOKEN, data.token)
          commit(LOGIN_SUCCESS)
          resolve({ token: data.token })
        })
        .catch((e) => {
          commit(LOGIN_FAIL)
          reject(e)
        })
    })
  },
  logout({ commit }) {
    clearStorage()
    commit(LOGOUT)
  }
}
