import AbstractModel from '../../abstract/abstract.model'
import { api } from '@/plugins'
import { roles as rolesData } from '../agence.data'

class AgenceRole extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      {
        field: 'roles',
        default: {},
        callBack: (_roles) => {
          const roles = {}
          rolesData.forEach((role) => {
            roles[role.id] = !!_roles[role.id]
          })
          return roles
        }
      },
      'collaborateurId',
      'agenceId'
    ])
  }

  getRoles() {
    return this.getProperty('roles')
  }

  hasRole(role) {
    const roles = this.getRoles()
    return roles.includes(role)
  }

  save() {
    if (this.isNew()) {
      return api.POST('agence/addCollaborateur', this).then((role) => {
        this.resetModelRef()
        return role
      })
    } else {
      return api.PUT('agence/editCollaborateur', this).then((role) => {
        this.resetModelRef()
        return role
      })
    }
  }
}

export default AgenceRole
