import { publicPath } from '@/../vue.config.js'

import '@/assets/vendors/vendors.min.css'

import '@/assets/vendors/flag-icon/css/flag-icon.min.css'
import '@/assets/vendors/jquery-fileuploader/font/font-fileuploader.css'

import '@/assets/vendors/data-tables/css/jquery.dataTables.min.css'
import '@/assets/vendors/data-tables/extensions/responsive/css/responsive.dataTables.min.css'
import '@/assets/vendors/data-tables/css/select.dataTables.min.css'
import '@/assets/vendors/select2/dist/css/select2.min.css'

import '@/assets/sass/materialize.scss'
import '@/assets/sass/style.scss'

// Import des pages
import '@/assets/sass/pages/page-404.scss'
import '@/assets/sass/pages/page-500.scss'
import '@/assets/sass/pages/error.scss'
import '@/assets/sass/pages/page-maintenance.scss'

import '@/assets/sass/pages/data-tables.scss'
import '@/assets/sass/pages/app-sidebar.scss'

const _scripts = [
  'assets/js/vendors.min.js',

  'assets/vendors/sparkline/jquery.sparkline.min.js',
  'assets/vendors/chartjs/chart.min.js',

  'assets/vendors/data-tables/js/jquery.dataTables.min.js',
  'assets/vendors/data-tables/extensions/responsive/js/dataTables.responsive.min.js',
  'assets/vendors/data-tables/js/dataTables.select.min.js',

  'assets/vendors/jquery-fileuploader/jquery.fileuploader.min.js',

  'assets/vendors/jquery-validation/jquery.validate.min.js',
  'assets/vendors/jquery-validation/additional-methods.min.js',
  'assets/vendors/jquery-validation/localization/messages_fr.min.js',

  'assets/vendors/select2/dist/js/select2.full.min.js',
  'assets/vendors/select2/dist/js/i18n/fr.js',

  'assets/vendors/sweetalert/sweetalert.min.js',

  'assets/vendors/formatter/jquery.formatter.min.js',
  'assets/js/plugins.js'
]

export const scripts = _scripts.map((script) => `${publicPath + script}`)
