import { api } from '@/plugins'
import { GET_STAT, GET_STAT_SUCCESS, GET_STAT_FAIL } from './mutations'

export default {
  get({ commit }, key) {
    return new Promise((resolve, reject) => {
      if (!key) {
        return reject(
          new Error(`Erreur paramètre : Stat:store:get : "key" non defini`)
        )
      }
      commit(GET_STAT)

      api
        .GET(`stat/get`, key)
        .then(async (data) => {
          await commit(GET_STAT_SUCCESS, data)
          resolve(data)
        })
        .catch((e) => {
          commit(GET_STAT_FAIL)
          reject(e)
        })
    })
  }
}
