export default {
  install (Vue) {
    Vue.mixin({
      mounted () {
        $(this.$el).on('click', '.card-alert .close', function () {
          $(this)
            .closest('.card-alert')
            .fadeOut('slow')
        })
      }
    })
  }
}
