import { store } from '@/plugins'

/**
 * Ajout des metas perso de la route
 * @param {} route
 */
function setMetas(route) {
  // On recherche les routes avec un titre
  const nearestWithTitle = route.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // on affecte le titre
  if (nearestWithTitle) {
    const user = store.getters['user/user']
    const title = nearestWithTitle.meta.title
    document.title = typeof title === 'function' ? title({ user }) : title
  }

  // On retire les metas perso
  Array.from(
    document.querySelectorAll('[meta-vue-router-controlled]')
  ).map((el) => el.parentNode.removeChild(el))

  // On recherche les routes avec des meta
  const nearestWithMeta = route.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // Si pas de route avec meta on sort
  if (!nearestWithMeta) return

  // On ajoute les metas
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // on ajoute un attribut pour identifier les metas perso
      tag.setAttribute('meta-vue-router-controlled', '')
      return tag
    })
    .forEach((tag) => document.head.appendChild(tag))
}

export default function(router) {
  router.afterEach((to, from) => {
    setMetas(to, from)
  })
}
