import { api } from '@/plugins'
import {
  GET_RESILIATIONS,
  GET_RESILIATIONS_SUCCESS,
  GET_RESILIATIONS_FAIL
} from './mutations'

export default {
  list ({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_RESILIATIONS)

      api
        .GET('resiliation/list')
        .then(async datas => {
          await commit(GET_RESILIATIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch(e => {
          commit(GET_RESILIATIONS_FAIL)
          reject(e)
        })
    })
  }
}
