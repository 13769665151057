import Model from '../donnee.model'

export default {
  edit: ({ PUT }, donnee) =>
    new Promise((resolve, reject) => {
      if (!(donnee instanceof Model)) {
        return reject(new Error(
          `Erreur paramètre : Donnee attendu mais reçu "${donnee.constructor.name}"`
        ))
      } else if (donnee.isNew()) {
        return reject(new Error(`Erreur paramètre : Donnee is new`))
      }

      return PUT(`donnees/${donnee.getId()}`, donnee)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const donnee = new Model(data)
            resolve(donnee)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
