export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES'
export const GET_QUESTIONNAIRES_SUCCESS = 'GET_QUESTIONNAIRES_SUCCESS'
export const GET_QUESTIONNAIRES_FAIL = 'GET_QUESTIONNAIRES_FAIL'

export default {
  [GET_QUESTIONNAIRES](state) {
    state.quests_pending = true
  },
  [GET_QUESTIONNAIRES_SUCCESS](state, quests) {
    state.quests = quests
    state.quests_pending = false
  },
  [GET_QUESTIONNAIRES_FAIL](state) {
    state.quests_pending = false
  }
}
