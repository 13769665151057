import { api } from '@/plugins'
import {
  GET_CABINETS,
  GET_CABINETS_SUCCESS,
  GET_CABINETS_FAIL
} from './mutations'

export default {
  list({ commit }, modelId) {
    return new Promise((resolve, reject) => {
      commit(GET_CABINETS)

      api
        .GET('cabinet/list', modelId)
        .then(async (datas) => {
          await commit(GET_CABINETS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(GET_CABINETS_FAIL)
          reject(e)
        })
    })
  }
}
