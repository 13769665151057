export default {
  relance: ({ PUT }, { id, date }) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/teletransmissions/${id}/relance`, {
        date
      })
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  comment: ({ PUT }, { id, comment }) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/teletransmissions/${id}/comment`, {
        comment
      })
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  archive: ({ PUT }, id) =>
    new Promise((resolve, reject) => {
      return PUT(`production/contrats/teletransmissions/${id}/archive`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
