<template>
  <div class="menu-container">
    <div
      v-for="(_group, groupIndex) in groups"
      :key="`${kdm}-${groupIndex}-${getTexteTitle(_group.title)}`"
      class="menu-group"
      :class="{
        open: isOpen(_group),
        selected: isSubSelected(_group)
      }"
    >
      <template v-if="isAllowed(_group)">
        <template v-if="!_group.items && _group.to">
          <div class="menu-header menu-header-solo">
            <router-link :to="_group.to" class="menu-title-link">
              <span class="menu-title-icon">
                <i v-if="_group.icon" class="material-icons">{{
                  _group.icon
                }}</i>
              </span>
              <span class="menu-title-title">{{
                getTexteTitle(_group.title)
              }}</span>
            </router-link>
          </div>
        </template>
        <template v-else-if="_group.items">
          <div class="menu-header" @click="toggleGroup(_group, groupIndex)">
            <i class="material-icons close-icon">
              {{ isOpen(_group) ? 'expand_less' : 'expand_more' }}</i
            >
            <div class="menu-title-link">
              <span v-if="_group.icon" class="menu-title-icon">
                <i class="material-icons">{{ _group.icon }}</i>
              </span>
              <span class="menu-title-title">{{
                getTexteTitle(_group.title)
              }}</span>
            </div>
            <div class="menu-items-title">
              {{ itemsTitles(_group) }}
            </div>
          </div>
          <div v-if="_group.open" class="menu-items">
            <div v-if="_group.searchBar" class="ms-2 me-2">
              <div class="input-group mb-3">
                <input
                  v-model.trim="_group._search"
                  class="form-control"
                  type="text"
                  required
                  placeholder="Rechercher"
                />
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="_group._search = ''"
                >
                  x
                </button>
              </div>
              <hr class="mt-2 mb-2" />
            </div>
            <div
              v-for="(item, itemIndex) in itemsFilter(_group)"
              :key="`${itemIndex}-${getTexteTitle(item.title)}`"
              class="menu-item"
              :class="{
                selected: isSelected(item)
              }"
            >
              <Menu
                v-if="item.items"
                :group="_group"
                :menu="{
                  groups: [item]
                }"
                :base-open="baseOpen"
              ></Menu>
              <router-link
                v-else-if="item.to"
                :to="item.to"
                class="menu-item-link"
              >
                <span class="menu-item-icon">
                  <i v-if="item.icon" class="material-icons">{{ item.icon }}</i>
                </span>
                <span class="menu-item-title">{{
                  getTexteTitle(item.title)
                }}</span>
              </router-link>
              <a
                v-else-if="item.href"
                :href="item.href"
                :target="item.target || '_self'"
                class="menu-item-link"
              >
                <span class="menu-item-icon">
                  <i v-if="item.icon" class="material-icons">{{ item.icon }}</i>
                </span>
                <span class="menu-item-title">{{
                  getTexteTitle(item.title)
                }}</span>
              </a>

              <div v-if="actionsFilter(item)" class="menu-actions">
                <div
                  v-for="(action, actionIndex) in actionsFilter(item)"
                  :key="actionIndex"
                  class="menu-action"
                >
                  <router-link
                    v-if="action.to"
                    :to="action.to"
                    class="menu-action-link"
                  >
                    <span
                      v-if="action.icon"
                      class="menu-action-icon tooltipped"
                      data-position="top"
                      :data-tooltip="getTexteTitle(action.title)"
                    >
                      <i class="material-icons">{{ action.icon }}</i>
                    </span>
                  </router-link>
                  <a
                    v-else-if="action.href"
                    :href="action.href"
                    :target="action.target || '_self'"
                    class="menu-action-link"
                  >
                    <span
                      v-if="action.icon"
                      class="menu-action-icon tooltipped"
                      data-position="top"
                      :data-tooltip="getTexteTitle(action.title)"
                    >
                      <i class="material-icons">{{ action.icon }}</i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              v-if="
                _group.searchBar &&
                items(_group.items).length > 0 &&
                itemsFilter(_group).length === 0
              "
            >
              <span class="menu-item">
                <span class="menu-item-link">
                  <span class="menu-item-title">Aucun résultat trouvé.</span>
                </span>
              </span>
            </div>
          </div></template
        >
      </template>
    </div>
  </div>
</template>

<script>
import { compareStr } from '@/plugins/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',
  props: {
    menu: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      default: () => ({})
    },
    baseOpen: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      groups: [],
      kdm: 0
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  mounted() {
    this.groups = this.menu.groups
  },
  methods: {
    isAllowed(item) {
      if (this.user.isAdmin()) {
        return true
      }
      return !!item.credentials && this.user.isAllowed(item.credentials)
    },
    items(items) {
      return typeof items === 'function' ? items(this) : items ?? []
    },
    itemsTitles(group) {
      return this.items(group.items)
        .filter((item) => this.isAllowed(item))
        .map((item) => this.getTexteTitle(item.title))
        .join(', ')
    },
    toggleGroup(group) {
      group.open = !group.open
      this.kdm++

      const name =
        (this.group?.title ? this.group.title + '||' : '') +
        (group?.title ?? '')

      this.$api.POST('user/menu', {
        group: name,
        open: group.open
      })
    },
    isSelected(item) {
      const route = this.$route

      if (route) {
        if (typeof item.to === 'string') {
          return route?.path === item.to
        } else if (route.name && item?.to?.name) {
          const to = item.to
          if (route?.name === to.name) {
            if (to.params && route.params) {
              const toParams = to.params
              return Object.keys(toParams).every((key) =>
                compareStr(toParams[key])(route.params[key])
              )
            }

            return true
          }

          if (item.childrens?.includes(String(route.name))) {
            return true
          }
        }
      }
      return false
    },
    isOpen(group) {
      if (!this.items(group.items).length) {
        return false
      }

      if (typeof group.open === 'undefined') {
        group.open = this.items(group.items).some((item) =>
          this.isSubSelected(item)
        )
      }

      return group.open
    },
    isSubSelected(group, a) {
      if (this.isSelected(group)) {
        return true
      }

      return this.items(group.items).some(
        (item) => this.isSelected(item, a) || this.isSubSelected(item, a)
      )
    },

    itemsFilter(group) {
      let result = this.items(group.items) ?? []

      // On filtre s'il y a une recherche en cours
      if (
        group.searchBar &&
        typeof group._search !== 'undefined' &&
        group._search !== ''
      ) {
        result = result.filter((item) =>
          this.getTexteTitle(item.title)
            .toLowerCase()
            .includes(String(group._search).toLowerCase())
        )
      }

      result = result.filter((item) => this.isAllowed(item))

      return result.slice(0, group.maxSize)
    },

    actionsFilter(item) {
      if (!item.actions) {
        return []
      }
      return item.actions.filter((action) => this.isAllowed(action))
    },

    getTexteTitle(textFn) {
      return typeof textFn === 'function' ? textFn({ user: this.user }) : textFn
    },

    getName(item) {
      if (item.to) {
        const { href } = this.$router.resolve(item.to)
        return href
      }
      return `${this.basePath}_${item.title}`
    }
  }
}
</script>
