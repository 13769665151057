import Donnee from '../donnee.model'

export default {
  get: ({ GET }, id) =>
    new Promise((resolve, reject) => {
      return GET(`donnees/${id}`)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Donnee(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    }),
  list: ({ GET }, type) =>
    new Promise((resolve, reject) => {
      const uri = type ? `donnees/types/${type}` : `donnees`
      return GET(uri)
        .then(reponse => {
          const data = reponse.data
          if (reponse.status === 200 && data) {
            let models = []
            data.forEach(modelData => {
              models.push(new Donnee(modelData))
            })
            resolve(models)
          } else {
            reject(reponse.status)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
