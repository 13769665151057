import Group from '../group.model'

export default {
  edit: ({ PUT }, group) =>
    new Promise((resolve, reject) => {
      if (!(group instanceof Group)) {
        return reject(
          new Error(
            `Erreur paramètre : Group attendu mais reçu "${group.constructor.name}"`
          )
        )
      } else if (group.isNew()) {
        return reject(new Error(`Erreur paramètre : Group is new`))
      }

      return PUT(`groups/${group.id}`, group)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const group = new Group(data)
            return resolve(group)
          } else {
            return reject(new Error({ reponse }))
          }
        })
        .catch((error) => reject(error))
    })
}
