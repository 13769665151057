import Fiche from '../fiche.model'

export default {
  delete: (_, model) =>
    new Promise((resolve, reject) => {
      if (!model.getId()) {
        return reject(new Error(`Erreur paramètre : fiche ID non valide`))
      }
      return this.deleteById(model.getId())
    }),
  deleteById: ({ DELETE }, id) =>
    new Promise((resolve, reject) => {
      return DELETE(`fiches/${id}`)
        .then((reponse) => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
  visiteDelete: ({ DELETE }, datas) =>
    new Promise((resolve, reject) => {
      const visiteId = datas.visite
      const ficheId = datas.fiche
      if (!visiteId) {
        return reject(new Error(`Erreur paramètre : Visite ID non défini`))
      }
      if (!ficheId) {
        return reject(new Error(`Erreur paramètre : Fiche ID non défini`))
      }
      return DELETE(`fiches/${ficheId}/visites/${visiteId}`)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const model = new Fiche(data)
            resolve(model)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
