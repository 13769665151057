import Ville from '../ville.model'

export default {
  add: ({ POST }, ville) =>
    new Promise((resolve, reject) => {
      if (!(ville instanceof Ville)) {
        return reject(
          new Error(
            `Erreur paramètre : Ville attendu mais reçu "${ville.constructor.name}"`
          )
        )
      }
      return POST(`donnees/villes`, ville)
        .then((reponse) => {
          const data = reponse.data
          if (reponse.status === 200 && data.id) {
            const ville = new Ville(data)
            resolve(ville)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
}
