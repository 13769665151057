import AbstractModel from '../../abstract/abstract.model'

export const STATE_NOT_REQUESTED = 'STATE_NOT_REQUESTED' // 0
export const STATE_REQUIRED = 'STATE_REQUIRED' // 1
export const STATE_SEND = 'STATE_SEND' // 2
export const STATE_CONFIRM = 'STATE_CONFIRM' // 3
export const STATE_CANCEL = 'STATE_CANCEL' // -1
export const STATE_EXPIRED = 'STATE_EXPIRED' // -3
export const STATE_FAIL = 'STATE_FAIL' // -5

class FicheSignature extends AbstractModel {
  constructor (data) {
    super(data, [
      'id',
      ['state', STATE_NOT_REQUESTED],
      'created',
      'dateSend',
      'dateConfirm',
      'dateCancel'
    ])
  }

  getState () {
    return this.getProperty('state')
  }
}

export default FicheSignature
